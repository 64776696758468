import { useMemo } from 'react';
import { range } from 'lodash';

const usePagination: ({
  totalCount,
  pageSize,
  currentPage,
  siblingCount,
}: {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  siblingCount?: number;
}) => [number[], number] = ({
  totalCount,
  pageSize,
  currentPage,
  siblingCount = 4,
}) => {
  return useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    if (siblingCount >= totalPageCount) {
      return [range(1, totalPageCount + 1), totalPageCount];
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount + 1,
      totalPageCount,
    );
    const leftOverflow = leftSiblingIndex > 1;
    const rightOverflow = rightSiblingIndex < totalPageCount;

    if (!rightOverflow && !leftOverflow && totalPageCount < 10) {
      return [range(1, totalPageCount + 1), totalPageCount];
    }

    if (!leftOverflow && rightOverflow && currentPage < 5) {
      const count = range(1, currentPage + 7);
      return [[...count], totalPageCount];
    }

    if (leftOverflow && !rightOverflow && totalPageCount - currentPage < 5) {
      // const count = [...range(totalPageCount - 7, totalPageCount), totalPageCount]
      const rangeNumbers = range(totalPageCount - 7, totalPageCount);
      if (rangeNumbers.includes(0)) {
        rangeNumbers.shift();
      }
      const count = [...rangeNumbers, totalPageCount];
      return [count, totalPageCount];
    }

    return [range(leftSiblingIndex, rightSiblingIndex), totalPageCount];
  }, [totalCount, pageSize, siblingCount, currentPage]);
};

export default usePagination;
