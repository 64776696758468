import React, { useState, useContext, useEffect } from 'react';
import { styled } from './../../../theme/index';
import { BrandSelector } from './../../molecules/BrandSelector/BrandSelector';
import { type Option as SelectProps } from './../../atoms/Select/Select';
import BrandShowcase from './../../molecules/BrandShowcase/BrandShowcase';
import BrandsCollection from './../../molecules/BrandsCollection/BrandsCollection';
import client from '../../api';
import { UserContext } from '../../../context/UserContext';
import LoadingOverlay from '../../atoms/LoadingOverlay/LoadingOverlay';
import ErrorMessage from '../../atoms/ErrorMessage/ErrorMessage';
import { type BrandsListProps, type Brand } from '../../../types/brandTypes';

const Container = styled('div', {
  margin: 0,
  '@bp1': {
    maxWidth: '744px',
  },
  '@bp3': {
    padding: 0,
    maxWidth: '100%',
  },
});

const MainContent = styled('div', {
  width: '100%',
});

const Body = styled('div', {
  display: 'flex',
  flexDirection: 'column-reverse',
  '@bp3': {
    display: 'grid',
    maxWidth: '100%',
    gridTemplateColumns: '75% 25%',
    justifyItems: 'center',
    gap: '24px',
  },
});

const Title = styled('h2', {
  padding: '15px 20px 15px 0',
  color: '$black',
  fontFamily: '$heading',
  fontSize: '25px',
  fontWeight: '$bold',
  letterSpacing: '1px',
  lineHeight: 1,
  textTransform: 'uppercase',
  margin: 0,
  '@bp3': {
    display: 'none',
  },
});

const SideContent = styled('div', {
  width: '100%',
  marginTop: '18px',
  '@bp1': {
    marginTop: '5px',
    marginRight: '5px',
  },
  '@bp3': {
    marginTop: '4px',
  },
});

const BrandsSelector = styled(BrandSelector, {
  '@bp4': {
    marginTop: 0,
    paddingBottom: '8px',
  },
});

let originalBrandsCollection: Brand[] = []; // saving old list while filtering
const StyledLoadingOverlay = styled(LoadingOverlay, {
  position: 'absolute',
  zIndex: 100,
});

const BrandsList: React.FC<BrandsListProps> = ({ ...props }) => {
  const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState(false);
  const [dataShowCase, setDataShowCase] = useState({
    title: 'Featured Brands',
    logos: [],
  });
  const [brandsCollection, setBrandsCollection] = useState<Brand[]>([]);
  const [categoriesSelect, setCategoriesSelect] = useState<SelectProps[]>([]);
  const [brandsSelect, setBrandsSelect] = useState<SelectProps[]>([]);
  const [indexItem, setIndexItem] = useState('All');
  const [loading, setLoading] = useState(false);

  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      setLoading(true);
      void client
        .get('/api/brands/list', {
          headers: { csrf_token },
          auth: { username, password },
        })
        .then(({ data }) => {
          setDataShowCase(data.featuredBrands);
          setBrandsCollection(data.brands);
          setCategoriesSelect([
            { value: '', label: '- Select a Category -' },
            ...data.categories,
          ]);
          setBrandsSelect([
            { value: '', label: '- All Brands -' },
            ...data.brandsSelect,
          ]);
          setLoading(false);
        })
        .catch((e) => {
          console.log('error', e);
          setErrorMessages(true);
          setLoading(false);
        });
    }
  }, [password, username, csrf_token]);

  const onChangeHandler = (e: React.SetStateAction<string>): void => {
    setIndexItem(e);
  };

  const onFilterSelect = (key: string, value: SelectProps): void => {
    // backup the original list on first filter
    if (originalBrandsCollection.length === 0) {
      originalBrandsCollection = brandsCollection;
    }

    const brandArray = originalBrandsCollection;

    if (value.value.length !== 0) {
      if (key === 'categories') {
        const filteredCollection = brandArray.map((charObj) => {
          const brandsOldArray = charObj.options;
          const catExist = brandsOldArray.filter((brand) => {
            const isExists = (
              brand as unknown as { category: string[] }
            ).category.filter((cat) => cat === value.value);
            return isExists.length === 1;
          });

          return {
            title: charObj.title,
            options: catExist,
          };
        });

        setBrandsCollection(filteredCollection);
      } else if (key === 'retailers') {
        let firstLetter = Array.from(value.label)[0].toLowerCase();
        if (firstLetter.match(/[a-z]/i) === null) {
          // is not letter
          firstLetter = '#';
        }

        const searchOptions = brandArray.filter((itemObj) => {
          return itemObj.title.toLowerCase() === firstLetter;
        });

        let newArray: (typeof brandArray)[0]['options'] = [];
        if (searchOptions[0] !== undefined) {
          newArray = searchOptions[0].options.filter((item) => {
            return item.name === value.label;
          });
        }

        const newBrandsCol = [
          {
            title: firstLetter,
            options: newArray,
          },
        ];

        setBrandsCollection(newBrandsCol);
      } // end if key===brands
    } else {
      setBrandsCollection(originalBrandsCollection);
    }
  };

  return (
    <Container
      className="brandsList"
      {...props}
    >
      <Title>BRANDS</Title>

      {errorMessages && <ErrorMessage />}

      <Body>
        <MainContent>
          <BrandsSelector
            onIndexClick={onChangeHandler}
            onFilterSelect={onFilterSelect}
            categoriesSelect={categoriesSelect}
            brandsSelect={brandsSelect}
          />
          {loading && <StyledLoadingOverlay />}
          <BrandsCollection
            brands={brandsCollection}
            indexItem={indexItem}
          />
        </MainContent>
        <SideContent>
          <BrandShowcase
            title={dataShowCase.title}
            logos={dataShowCase.logos}
          />
        </SideContent>
      </Body>
    </Container>
  );
};

export default BrandsList;
