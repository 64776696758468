import React from 'react';
import { ButtonCreateLinkProps } from 'src/types/createLinkTypes';
import { ReactComponent as IconPlus } from 'src/icons/iconPlus.svg';
import BaseButton from 'src/components/atoms/BaseButton/BaseButton';
import 'src/components/molecules/ButtonCreateLink/buttonCreateLink.scss';

const ButtonCreateLink: React.FC<ButtonCreateLinkProps> = ({
  affiliateLink,
  createLinkButtonText,
  isCopied,
  isLoading,
  handleGenerateLinkClick,
  className,
}) => {
  return (
    <BaseButton
      className={`buttonCreateLink ${className}`}
      icon={!isLoading && !affiliateLink && !isCopied ? IconPlus : undefined}
      text={createLinkButtonText}
      onClick={handleGenerateLinkClick}
      isDisabled={!!affiliateLink || isLoading}
      isLoading={isLoading}
    />
  );
};

export default ButtonCreateLink;
