// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { styled, type ThemeProps } from '../../../theme/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import useOutsideClick from '../../../hook/useOutsideClick/useOutsideClick';
import Calendar from './Calendar';
import Input from '../Input/Input';
import { Button } from '../Button/Button';

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  type: 'primary' | 'secondary';
  name: string;
  height?: string;
  value?: Date;
  callback?: (e: string) => void;
}

const Container = styled('label', {
  display: 'inline-flex',
  alignItems: 'center',
  position: 'relative',
  overflow: 'visible',
  width: '100%',
  backgroundColor: '$white',
  // zIndex: 1
});

const InputField = styled(Input, {
  'input[type="date"]::-webkit-calendar-picker-indicator, input[type="date"]::-webkit-input-placeholder':
    {
      display: 'none',
      visibility: 'hidden !important',
    },
  input: {
    border: '1px solid $blue7',
    borderRadius: '1px',
  },
  variants: {
    view: {
      primary: {
        input: {
          fontSize: '$size15',
          height: '38px',
          padding: '6px 12px',
          paddingRight: '64px',
        },
      },
      secondary: {
        input: {
          height: '29px',
          width: '100%',
          maxWidth: '177px',
        },
      },
    },
  },
});

const TheCalendar = styled(Calendar, {
  maxWidth: '220px',
  position: 'absolute',
  zIndex: 9,
  top: 'calc(100% + 3px)',
  left: 0,
});

const DatePicker: React.FC<Props> = ({
  type = 'primary',
  name,
  height,
  value,
  callback,
  ...props
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [output, setOutput] = useState(new Date(value));
  const wrapperRef = React.useRef(null);

  useEffect(() => {
    setOutput(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleClick = (): void => {
    if (navigator?.userAgentData?.mobile === false) {
      setShowCalendar(true);
    }
  };

  useOutsideClick(wrapperRef, function () {
    if (navigator?.userAgentData?.mobile === false) {
      setShowCalendar(false);
    }
  });

  const handleSelection = (nextValue): void => {
    callback?.(nextValue);
    setOutput(nextValue);
    if (navigator?.userAgentData?.mobile === false) {
      setShowCalendar(false);
    }
  };

  return (
    <Container
      htmlFor={`thisIs date-picker-${name}-${value}`}
      ref={wrapperRef}
      {...props}
    >
      {' '}
      {showCalendar && (
        <TheCalendar
          callback={handleSelection}
          value={output}
          calendarProps={{
            prev2Label: null,
            next2Label: null,
            prevLabel: '«',
            nextLabel: '»',
            minDetail: 'decade',
          }}
        />
      )}
      <InputField
        id={`date-picker-${name}-${value}`}
        type="date"
        name={name}
        view={type}
        blockInput={true}
        onFocus={handleClick}
        value={
          output.length === 10 ? output : output.toISOString().slice(0, 10)
        }
        css={{
          input: {
            height: height ?? '38px',
            background: 'transparent',
            appearance: 'none',
          },
        }}
        onChange={(e) => {
          e.preventDefault();
          handleSelection(new Date(e.target.value));
        }}
      />{' '}
      {type === 'primary' && (
        <Button
          as="span"
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            right: 0,
            userEvents: 'none',
            width: '52px',
            height: height ?? '38px',
            zIndex: -1,
          }}
        >
          {' '}
          <FontAwesomeIcon icon={faCalendarDays} />{' '}
        </Button>
      )}
    </Container>
  );
};
export default DatePicker;
