// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';

const Icon = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 19.944 19.914"
    >
      <defs>
        <clipPath id="clip-path-3">
          <path
            id="Clip_2-3"
            data-name="Clip 2-3"
            d="M0,0H19.945V19.914H0Z"
            transform="translate(0 0.086)"
            fill="currentColor"
          ></path>
        </clipPath>
      </defs>
      <g
        id="Icon-Instagram"
        transform="translate(0 -0.086)"
      >
        <g
          id="Group_3"
          data-name="Group 3"
          transform="translate(0 0)"
        >
          <path
            id="Clip_2-2"
            data-name="Clip 2"
            d="M0,0H19.945V19.914H0Z"
            transform="translate(0 0.086)"
            fill="none"
          ></path>
          <g
            id="Group_3-2"
            data-name="Group 3"
          >
            <path
              className="icon-path"
              id="Fill_1"
              data-name="Fill 1"
              d="M14.2,19.913H5.74A5.588,5.588,0,0,1,0,14.505v-9.1A5.588,5.588,0,0,1,5.74,0H14.2a5.588,5.588,0,0,1,5.741,5.408v9.1A5.588,5.588,0,0,1,14.2,19.913ZM5.74,1.9A3.688,3.688,0,0,0,1.9,5.408v9.1A3.688,3.688,0,0,0,5.74,18.013H14.2a3.689,3.689,0,0,0,3.839-3.507v-9.1A3.688,3.688,0,0,0,14.2,1.9Z"
              transform="translate(0 0.086)"
              fill="currentColor"
            ></path>
          </g>
        </g>
        <path
          className="icon-path"
          id="Fill_4"
          data-name="Fill 4"
          d="M5.089,10.176a5.088,5.088,0,1,1,5.089-5.088A5.094,5.094,0,0,1,5.089,10.176Zm0-8.421A3.333,3.333,0,1,0,8.421,5.089,3.337,3.337,0,0,0,5.089,1.755Z"
          transform="translate(4.884 4.955)"
          fill="currentColor"
        ></path>
        <path
          className="icon-path"
          id="Fill_6"
          data-name="Fill 6"
          d="M2.511,1.255A1.255,1.255,0,1,1,1.255,0,1.255,1.255,0,0,1,2.511,1.255"
          transform="translate(14.131 3.479)"
          fill="currentColor"
        ></path>
      </g>
    </svg>
  );
};

export default Icon;
