import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  href: string;
}

export const Link = styled('a', {
  display: 'block',
  backgroundColor: '$blue6',
  textAlign: 'left',
  padding: '10px 20px',
  color: '$clear',
  fontSize: '$size15',
  fontFamily: '$heading',
  textTransform: 'uppercase',
  clear: 'both',
  lineHeight: 1.17,
  letterSpacing: '1.5px',
  fontWeight: '$bold',
  textDecoration: 'none',
});

const NavigationLink: React.FC<Props> = ({ children, href, ...props }) => {
  return (
    <Link
      href={href}
      {...props}
    >
      {children}
    </Link>
  );
};

export default NavigationLink;
