// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useContext, useEffect } from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import DateForm from './../../molecules/DateForm/DateForm';
import DataTables from './../../atoms/DataTables/DataTables';
import { Title as TitleStyle, Border, Container } from './styles';
import client from '../../api';
import { UserContext } from '../../../context/UserContext';
import LoadingOverlay from '../../atoms/LoadingOverlay/LoadingOverlay';
import ErrorMessage from '../../atoms/ErrorMessage/ErrorMessage';

interface Props extends ThemeProps {
  data?: object[];
}

const Title = styled(TitleStyle, {
  paddingTop: '25px',
});

const PaymentSummary: React.FC<Props> = ({ ...props }) => {
  const { userCredentials } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState('');
  const [dataTable, setDataTable] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('all');

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
    callApi(selectedMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const callApi = (monthFilter, exportType = false): void => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client
        .post(
          '/api/reports?_format=json',
          {
            type: 'paid_summary',
            monthFilter,
            export_type: exportType ? 'file' : '',
          },
          {
            headers: {
              'Content-type': 'application/x-www-form-urlencoded',
              csrf_token,
            },
            auth: { username, password },
          },
        )
        .then(({ data }) => {
          if (exportType) {
            // 2. download the file
            const element = document.createElement('a');
            const file = new Blob([data.data], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = data.fileName;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
          } else {
            setDataTable(data.data.tableData);
          }

          setLoading(false);
        })
        .catch((e) => {
          setErrorMessages('Failed to get data for the report.');
          console.log('Error Payment Summary report, getting the report', e);
        });
    }
  };

  const handleFilterChange = (newValues): void => {
    setLoading(true);

    setSelectedMonth(newValues.month.value);

    callApi(newValues.month.value);
  };

  const handleExportFile = (): void => {
    setLoading(true);

    callApi(selectedMonth, true);
  };

  return (
    <Container {...props}>
      {loading && <LoadingOverlay />}

      <Title>Payment Summary Report</Title>

      {errorMessages !== '' ? <ErrorMessage message={errorMessages} /> : ''}

      <DateForm
        type="payment"
        callback={handleFilterChange}
        exportFile={handleExportFile}
      />
      <Border>
        <DataTables
          variant="payment"
          search={true}
          data={dataTable}
        />
      </Border>
    </Container>
  );
};

export default PaymentSummary;
