import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  variants?: undefined | 'pageTitle' | 'secondary';
}

const HeadingStyled = styled('h1', {
  margin: 0,
  padding: 0,
  display: 'block',
  font: 'inherit',
  fontWeight: '$bold',
  letterSpacing: '1px',
  textTransform: 'uppercase',
  lineHeight: 'inherit',
  a: {
    font: 'inherit',
    color: 'currentColor',
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
  variants: {
    type: {
      pageTitle: {
        fontFamily: '$heading',
        lineHeight: 1,
        fontSize: '20px',
        '@bp3': {
          fontSize: '25px',
        },
      },
      secondary: {
        fontFamily: '$heading',
        lineHeight: 1,
        fontSize: '19px',
      },
    },
  },
});

const Heading: React.FC<Props> = ({ children, ...args }) => {
  return <HeadingStyled {...args}>{children}</HeadingStyled>;
};

export default Heading;
