// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useContext, useState } from 'react';
import { styled } from './../../../theme/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faCheck } from '@fortawesome/free-solid-svg-icons';
import useCopy from '../../../hook/useCopy/useCopy';
import { UserContext } from '../../../context/UserContext';
import client from '../../api';

interface Props {
  bannerID: number;
  title: string;
  image: string;
  code: string;
  overrideState?: boolean;
  handleShowLoader: any;
  handleHideLoader: any;
}

const Container = styled('div', {
  textAlign: 'center',
});

const Header = styled('span', {
  fontFamily: '$body',
  fontWeight: '700',
  fontSize: '$size16',
  lineHeight: 1.428,
  color: '$darkgray',
});

const BodyBanner = styled('div', {
  border: '1px solid $lightgray3',
  boxShadow: '0 1px 2px 0 #4d4d4d80',
  backgroundColor: '$clear',
  marginRight: '2px',
});

const FooterBanner = styled('div', {
  borderTop: '1px solid $lightgray3',
  backgroundColor: '#F6F6F6',
  '@bp3': {
    marginTop: '20px',
  },
});

const CopyCode = styled('a', {
  color: '$black',
  display: 'block',
  fontWeight: '$bold',
  cursor: 'pointer',
  fontSize: '$size14',
  fontFamily: '$heading',
  letterSpacing: '0.2px',
  padding: '15px 0',
  lineHeight: 1,
});

const Icon = styled('span', {
  color: '$green4',
  fontSize: '18px',
  fontWeight: '$bold',
  lineHeight: 0.7,
  paddingRight: '6px',
  letterSpacing: '0.2px',
});

const ImageWrapper = styled('div', {
  padding: '16px 0 0',
});

const Image = styled('img', {
  display: 'inline-block',
  maxWidth: '100%',
  maxHeight: '195px',
  height: 'auto',
  objectFit: 'contain',
  imageRendering: 'optimizeSpeed',
});

// isApple pop up
const FooterBannerApple = styled('div', {
  borderTop: '1px solid $lightgray3',
  backgroundColor: '#F6F6F6',
  padding: '0 15px',
  '@bp3': {
    marginTop: '20px',
  },
});
const SafariInputField = styled('input', {
  marginTop: '15px',
  width: '100%',
});

const ShareBannerItem: React.FC<Props> = ({
  bannerID,
  title,
  image,
  code,
  overrideState,
  handleShowLoader,
  handleHideLoader,
  ...props
}) => {
  const { userCredentials } = useContext(UserContext);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  const [copyAction, ready] = useCopy();
  const [showSafariURL, setShowSafariURL] = useState(false);
  const [urlString, setUrlString] = useState('');

  let isApple = false;
  if (navigator.userAgent.match(/Macintosh|ipad|iphone/i) !== null) {
    isApple = true;
  }

  const showLoader = (): void => {
    handleShowLoader();
  };
  const hideLoader = (): void => {
    handleHideLoader();
  };

  const clickHandler = (): void => {
    showLoader();

    void client
      .get(`/api/banner/${bannerID}/get/code?_format=json`, {
        headers: { csrf_token },
        auth: { username, password },
      })
      .then(({ data }) => {
        if (isApple) {
          setUrlString(data);
          setShowSafariURL(true);
        } else {
          // Normal devices
          copyAction(data);
        }

        hideLoader();
      })
      .catch(() => {
        console.log('Error getting banner code: ', e);
        hideLoader();
      });
  };

  const handleCopyActionSafari = (): void => {
    copyAction(urlString);
  };

  const condition: boolean =
    overrideState !== undefined ? overrideState : ready;

  return (
    <Container {...props}>
      <Header>{title}</Header>
      <BodyBanner>
        <ImageWrapper>
          <Image
            src={image}
            alt={title}
          />
        </ImageWrapper>
        {!showSafariURL && (
          <FooterBanner>
            <CopyCode onClick={clickHandler}>
              <Icon>
                <FontAwesomeIcon icon={condition ? faCheck : faCode} />
              </Icon>
              {!isApple && <>COPY CODE</>}
              {isApple && <>GENERATE CODE</>}
            </CopyCode>
          </FooterBanner>
        )}
        {showSafariURL && (
          <FooterBannerApple
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleCopyActionSafari();
            }}
          >
            <SafariInputField
              type="text"
              value={urlString}
              disabled={true}
            />
            <br />
            <CopyCode>
              <Icon>
                <FontAwesomeIcon icon={condition ? faCheck : faCode} />
              </Icon>
              COPY CODE
            </CopyCode>
          </FooterBannerApple>
        )}
      </BodyBanner>
    </Container>
  );
};

export default ShareBannerItem;
