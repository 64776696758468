import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  children?: React.ReactNode;
}

const Container = styled('p', {
  fontFamily: '$body',
  fontSize: '$size16',
  color: '$black',
  padding: 0,
  margin: 0,
});

const Text: React.FC<Props> = ({ children, ...args }) => {
  return <Container {...args}>{children}</Container>;
};

export default Text;
