import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  outlined?: boolean;
  type?:
    | 'login'
    | 'main'
    | 'control'
    | 'icon'
    | 'export'
    | 'filter'
    | 'clear'
    | 'social'
    | 'action'
    | 'cleanIcon'
    | 'linkGenerator';
}

const actionStyles = {
  padding: '6px 20px',
  fontWeight: '$bold',
  fontSize: '$size15',
  lineHeight: 2.4,
  letterSpacing: '.8px',
  height: '44px',
  '@bp1': {
    padding: '6px 22px',
  },
};

export const ButtonElement = styled('button', {
  all: 'unset',
  width: '100%',
  color: '$clear',
  display: 'block',
  maxWidth: '100%',
  cursor: 'pointer',
  fontSize: '$size15',
  textAlign: 'center',
  fontFamily: '$heading',
  boxSizing: 'border-box',
  textTransform: 'uppercase',
  backgroundColor: '$blue5',
  tapHighlightColor: '$clear',
  lineHeight: 1,
  '&:focus': {
    boxShadow: 'inset 0 3px 5px rgba(0, 0, 0, 0.13)',
    outline: '3px auto -webkit-focus-ring-color',
    outlineOffset: '-1px',
  },
  '*, *:before, *:after': {
    boxSizing: 'inherit',
  },
  variants: {
    type: {
      login: {
        transition: 'all .4s ease-in',
        transitionProperty: 'color, background-color, border-color, outline',
        color: '$clear',
        backgroundColor: '$blue5',
        borderColor: '$blue5',
        fontWeight: '$bold',
        border: '2px solid',
        borderRadius: '1px',
        padding: '14px 0 14px',
        fontSize: '$size17',
        lineHeight: 1,
        letterSpacing: '1px',
        '&:hover': {
          color: '#595f7b',
          borderColor: '$black',
          backgroundColor: '$clear',
        },
        '&:focus': {
          boxShadow: 'none',
          outline: 'none',
        },
      },
      main: {
        padding: '6px 22px',
        fontWeight: '$bold',
        fontSize: '11px',
        lineHeight: 2.72,
        letterSpacing: '.8px',
        height: '40px',
        '@bp5': {
          height: '44px',
          fontSize: '$size15',
          lineHeight: 2.5,
        },
      },
      action: {
        ...actionStyles,
      },
      linkGenerator: {
        ...actionStyles,
        fontSize: '11px',
        height: 'auto',
        padding: '9px 20px 5px',
        '@bp1': {
          padding: '9px 20px 5px',
        },
        '@bp4': {
          fontSize: '$size15',
          padding: '6px 20px 2px',
        },
      },
      control: {
        padding: '14px 10px 13px',
        fontSize: '11px',
        lineHeight: 1,
        '@bp5': {
          fontSize: '$size15',
        },
      },
      icon: {
        padding: '14px 5px 13px',
        width: '40px',
      },
      export: {
        padding: '13px 25px 12px 29px',
        letterSpacing: '0.8px',
        fontWeight: '$bold',
      },
      filter: {
        height: '38px',
        borderRadius: 0,
        lineHeight: 0.56,
        padding: '6px 22px',
        fontSize: '$size16',
        fontWeight: '$regular',
        whiteSpace: 'nowrap',
      },
      clear: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '44px',
        minWidth: '97px',
        overflow: 'hidden',
        border: '2px solid $black',
        background: '$clear',
        borderRadius: '1px',
        color: 'inherit',
        lineHeight: 2.44,
        fontWeight: '$bold',
        fontSize: 'inherit',
      },
      social: {
        backgroundColor: '$clear',
        border: '2px solid $black',
        borderRadius: '1px',
        svg: {
          fontSize: '19.2px',
        },
        '&:active': {
          borderColor: 'rgba(0,0,0,0.2)',
        },
        '&:focus': {
          boxShadow: 'none',
          outline: 'none',
          outlineOffset: 'none',
        },
      },
      cleanIcon: {
        border: 'none',
        background: '$clear',
        color: '$black',
        padding: '10px 5px',
        borderBottom: '1px solid transparent',
        '&:hover': {
          borderBottom: '1px solid $black',
        },
      },
    },
  },
});

export const Button: React.FC<Props> = ({ children, ...props }) => {
  return <ButtonElement {...props}>{children}</ButtonElement>;
};

export default Button;
