import client from '../components/api';

const getSubUsersList = (
  password,
  username,
  csrfToken,
  setAffiliates,
  setErrorMessages,
) => {
  if (password.length > 2 && username.length > 2 && csrfToken.length > 2) {
    client
      .get('/api/subusers/get/list/all?_format=json', {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
          csrf_token: csrfToken,
        },
        auth: { username, password },
      })
      .then(({ data }) => {
        if (data?.length > 0) {
          const tempSubUsersList = data.map((item) => {
            return { value: item, label: item };
          });

          setAffiliates([...tempSubUsersList]);
        }
      })
      .catch((e) => {
        setErrorMessages('Failed to get the Users list.');
        console.log('Error summary report, getting the report', e);
      });
  }
};

export default getSubUsersList;
