// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useContext, useEffect } from 'react';
import { styled, type ThemeProps } from '../../../theme/index';

import Select, { type Option } from '../../atoms/Select/Select';
import DatePicker from '../../atoms/DatePicker/DatePicker';
import { Button } from '../../atoms/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import {
  lengthFilter,
  options,
  paidStatus,
  status,
} from '../../../.storybook/data/graphData';
import client from '../../api';
import { UserContext } from '../../../context/UserContext';
import moment from 'moment';
import ErrorMessage from '../../atoms/ErrorMessage/ErrorMessage';

export interface Props extends ThemeProps {
  type?:
    | 'home'
    | 'summary'
    | 'conversion'
    | 'affiliates'
    | 'brands'
    | 'traffic'
    | 'payment'
    | 'paymentBalance'
    | 'referral';
  callback?: (selections: object) => void;
  affiliates?: Option[];
  fullSelect?: boolean;
}

const styleButton = {
  fontSize: '11px',
  marginLeft: 'auto',
  '@bp5': {
    fontSize: '15px',
    marginRight: 0,
    marginLeft: 'auto',
  },
};

const fourRowLayout = {
  '@bp5': {
    columnGap: 0,
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
};

const offerSelectorStyles = {
  maxWidth: '100%',
  '@bp5': {
    maxWidth: '765px',
  },
};

const Container = styled('div', {
  width: '100%',
});

const MicroLevel = styled('div', {
  display: 'inline-flex',
});

const Controls = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  marginTop: '35px',
  variants: {
    type: {
      summary: {
        marginTop: '33px',
      },
      conversion: {
        marginTop: '14px',
      },
      brands: {
        marginTop: '31px',
      },
      referral: {
        marginTop: '31px',
      },
      paymentBalance: {
        marginTop: '31px',
      },
      traffic: {
        marginTop: '11px',
      },
      payment: {
        marginTop: '16px',
      },
    },
  },
});

const Level = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  alignItems: 'center',
  rowGap: '10px',
  variants: {
    type: {
      home: {
        '@bp3': {
          gridTemplateColumns: '1fr 1fr',
        },
        ...fourRowLayout,
      },
      summary: fourRowLayout,
      conversion: fourRowLayout,
      brands: fourRowLayout,
      paymentBalance: fourRowLayout,
      referral: fourRowLayout,
      traffic: {
        '@bp5': {
          columnGap: 0,
          gridTemplateColumns: 'repeat(3, 1fr)',
          marginBottom: '35px',
        },
      },
    },
  },
  '@bp0': {
    columnGap: '24px',
  },
});

const ControlsLevel = styled(Level, {
  variants: {
    affiliates: {
      true: {
        gridTemplateColumns: '1fr',
        '& > div:last-child': {
          gridColumn: 'auto',
        },
        '@bp4': {
          gridTemplateColumns: '250px 1fr',
          '& > div:last-child': {
            gridColumn: '4 / auto',
          },
        },
      },
    },
  },
});

const SelectWrapper = styled('div', {
  margin: 0,
  '@bp5': {
    marginRight: '24px',
  },
});

const SecondLevel = styled(Level, {
  marginTop: '23px',
  '@bp2': {
    marginTop: '33px',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
});

const LabelSelect = styled(SelectWrapper, {
  '& > div': {
    fontSize: '16px',
    marginTop: '5px',
    fontFamily: '$heading',
    '& > div:first-of-type': {
      maxHeight: '38px',
      textTransform: 'uppercase',
      alignItems: 'flex-start',
      overflow: 'hidden',
      lineHeight: 1.8,
      wordBreak: 'break-all',
      '& > div': {
        alignSelf: 'center',
      },
    },
  },
});

const SendButton = styled(Button, {
  marginLeft: 0,
  marginRight: 0,
  height: '42px',
  width: '60px',
  variants: {
    type: {
      home: {
        marginLeft: 'auto',
        marginRight: 0,
      },
    },
  },
});

const ControlsSendButton = styled(SendButton, {
  fontSize: '11px',
  '@bp5': {
    fontSize: '15px',
  },
});

const ResetButton = styled(Button, {
  height: '42px',
  width: '170px',
  marginLeft: 'auto',
  marginRight: '12px',
  fontSize: '11px',
  '@bp5': {
    fontSize: '15px',
  },
});

const DownloadButton = styled(Button, {
  display: 'block',
  padding: '11px 5px',
  margin: '10px 0 0 auto',
  borderRadius: '5px',
});

const PaymentLevel = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  gap: '12px',
  '& > div': {
    width: '200px',
  },
});

const AffiliatesControl = styled('div', {
  '@bp4': {
    marginTop: '33px',
    // flex: '1 1 350px',
    // display: 'inline-flex',
    alignItems: 'center',
    '&> div': {
      flexGrow: 1,
    },
  },
});
const UsersControl = styled('div', {
  '@bp4': {
    // marginTop: '33px',
    // flex: '1 1 350px',
    // display: 'inline-flex',
    alignItems: 'center',
    '&> div': {
      flexGrow: 1,
    },
  },
});

const FileDownload = styled(Button, {
  height: '42px',
  width: '170px',
  marginLeft: 'auto',
  fontSize: '11px',
  '@bp5': {
    fontSize: '15px',
  },
});

const DateForm: React.FC<Props> = ({
  affiliates = [],
  callback,
  type = 'home',
  fullSelect = false,
  exportFile,
  ...props
}) => {
  const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState(false);
  const [offersList, setOffersList] = useState([
    { value: 'all', label: 'All' },
  ]);
  const [payment, setPayment] = useState([{ value: 'all', label: 'All' }]);
  const [showFileDownload, setShowFileDownload] = useState(false);
  const role =
    window.localStorage.getItem('level') !== undefined
      ? window.localStorage.getItem('level')
      : 'affiliate';

  const prepDateList = (): void => {
    const a = moment();
    const b = a.subtract(10, 'year');
    const startDate = moment(b);
    const endDate = moment();
    // const betweenMonths = [{ label: 'All', value: 'All' }];
    const betweenMonths = [];

    if (startDate < endDate) {
      const date = startDate.startOf('month');

      while (date < endDate.endOf('month')) {
        betweenMonths.push({
          value: date.format('YYYY-MM'),
          label: date.format('MMMM YYYY'),
        });
        date.add(1, 'month');
      }
    }
    betweenMonths.push({ label: 'All', value: 'All' });
    betweenMonths.reverse();

    setPayment(betweenMonths);
  };

  const initialState = {
    range: options[3],
    startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    ...(type === 'paymentBalance' ? { affiliates: affiliates[0].value } : {}),
    ...(type === 'summary' || type === 'brands'
      ? { filter: lengthFilter[0] }
      : {}),
    ...(type === 'traffic' ? { traffic: offersList[0] } : {}),
    ...(type === 'conversion'
      ? {
          filter: lengthFilter[0],
          offers: offersList[0],
          status: status[0],
          paidStatus: paidStatus[0],
        }
      : {}),
    ...(type === 'payment' ? { month: payment[0] } : {}),
  };

  const [selections, setSelections] = useState(initialState);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
    if (type === 'conversion' || type === 'traffic') {
      if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
        if (offersList.length <= 1) {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          void client
            .get('/api/brands/get/list?_format=json', {
              headers: { csrf_token },
              auth: { username, password },
            })
            .then(({ data }) => {
              setOffersList([...offersList, ...data]);
            })
            .catch((e) => {
              console.log('Error getting brands list', e);
              setErrorMessages(true);
            });
        }
      }
    }

    if (type === 'payment') {
      prepDateList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, selections]);

  const selectionHandler = (e, _type): void => {
    let output = {
      range: selections.range,
      startDate: selections.startDate,
      endDate: selections.endDate,
      ...(type === 'affiliates' ? { affiliates: selections.affiliates } : {}),
      ...(type === 'summary' ? { filter: selections.filter } : {}),
      ...(type === 'conversion'
        ? {
            offers: selections.offers,
            status: selections.status,
            paidStatus: selections.paidStatus,
          }
        : {}),
      ...(type === 'payment' ? { filter: selections.month } : {}),
    };

    // validate range and dates
    if (output.startDate.getTime() > output.endDate.getTime()) {
      output.endDate = output.startDate;
    }

    // update selection
    output = { ...output, [_type]: e };

    // updating the field with the new date
    if (_type === 'startDate') {
      output = { ...output, range: { value: 'free', label: 'Between Dates' } };
      const momentObj = moment(e);
      output.startDate.setFullYear(
        momentObj.format('YYYY'),
        momentObj.format('M') - 1,
        momentObj.format('D'),
      );
      output.startDate.setHours(moment(e).format('hh'));
      output.startDate.setMinutes(moment(e).format('mm'));
      output.startDate.setSeconds(moment(e).format('ss'));
    }
    if (_type === 'endDate') {
      output = { ...output, range: { value: 'free', label: 'Between Dates' } };
      const momentObj = moment(e);
      output.endDate.setFullYear(
        momentObj.format('YYYY'),
        momentObj.format('M') - 1,
        momentObj.format('D'),
      );
      output.endDate.setHours(moment(e).format('hh'));
      output.endDate.setMinutes(moment(e).format('mm'));
      output.endDate.setSeconds(moment(e).format('ss'));
    }

    ((output) => {
      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        free: () => {},
        today: () => {
          output.startDate.setFullYear(
            moment().format('YYYY'),
            moment().format('M') - 1,
            moment().format('D'),
          );
          output.endDate.setFullYear(
            moment().format('YYYY'),
            moment().format('M') - 1,
            moment().format('D'),
          );
        },
        yesterday: () => {
          const yest = moment().subtract(1, 'days');
          output.startDate.setFullYear(
            yest.format('YYYY'),
            yest.format('M') - 1,
            yest.format('D'),
          );
          output.endDate.setFullYear(
            yest.format('YYYY'),
            yest.format('M') - 1,
            yest.format('D'),
          );
        },
        '7-days-past': () => {
          output.startDate.setFullYear(
            moment().format('YYYY'),
            moment().format('M') - 1,
            moment().format('D') - 6,
          );
          output.endDate.setFullYear(
            moment().format('YYYY'),
            moment().format('M') - 1,
            moment().format('D'),
          );
        },
        month: () => {
          const startOfMonth = moment().startOf('month');
          output.startDate.setFullYear(
            startOfMonth.format('Y'),
            startOfMonth.format('M') - 1,
            startOfMonth.format('D'),
          );
          output.endDate.setFullYear(
            moment().format('Y'),
            moment().format('M') - 1,
            moment().format('D'),
          );
        },
        'month-1': () => {
          const lastMonthStart = moment().subtract(1, 'month').startOf('month');
          const lastMonthEnd = moment().subtract(1, 'month').endOf('month');
          output.startDate.setFullYear(
            lastMonthStart.format('Y'),
            lastMonthStart.format('M') - 1,
            lastMonthStart.format('D'),
          );
          output.endDate.setFullYear(
            lastMonthEnd.format('Y'),
            lastMonthEnd.format('M') - 1,
            lastMonthEnd.format('D'),
          );
        },
        year: () => {
          const startOfYear = moment().startOf('year');
          output.startDate.setFullYear(
            startOfYear.format('Y'),
            startOfYear.format('M') - 1,
            startOfYear.format('D'),
          );
          output.endDate.setFullYear(
            moment().format('Y'),
            moment().format('M') - 1,
            moment().format('D'),
          );
        },
        'year-1': () => {
          const lastYearStart = moment().subtract(1, 'year').startOf('year');
          const lastYearEnd = moment().subtract(1, 'year').endOf('year');
          output.startDate.setFullYear(
            lastYearStart.format('Y'),
            lastYearStart.format('M') - 1,
            lastYearStart.format('D'),
          );
          output.endDate.setFullYear(
            lastYearEnd.format('Y'),
            lastYearEnd.format('M') - 1,
            lastYearEnd.format('D'),
          );
        },
      }[output.range.value](output);
    })(output);
    // setState
    setSelections({ ...selections, ...output });
  };

  const resetForm = (): void => {
    setSelections({ ...initialState });
  };

  const sendForm = (): void => {
    callback?.(selections);
  };

  const showFileDownloadHandler = (): void => {
    setShowFileDownload(!showFileDownload);
  };

  const createFileNDownload = (): void => {
    exportFile();

    setShowFileDownload(false);
  };
  // console.log("variant (report):", type);
  const baseSelectors = (
    <>
      {type !== 'referral' && (
        <SelectWrapper
        // css={
        //   fullSelect
        //     ? {
        //       width: '100%',
        //       '@media(min-width: 992px)': { width: 'max-content' }
        //     }
        //     : { width: 'max-content' }
        // }
        >
          <Select
            options={options}
            setValue={(e): void => {
              selectionHandler(e, 'range');
            }}
            value={selections.range}
            name="range-selection"
          />
        </SelectWrapper>
      )}

      <SelectWrapper>
        <DatePicker
          type="primary"
          callback={(e): void => {
            selectionHandler(e, 'startDate');
          }}
          value={selections.startDate}
          name="initial-date"
        />
      </SelectWrapper>

      <SelectWrapper>
        <DatePicker
          type="primary"
          callback={(e): void => {
            selectionHandler(e, 'endDate');
          }}
          value={selections.endDate}
          name="final-date"
        />
      </SelectWrapper>

      {type !== 'home' &&
        type !== 'traffic' &&
        type !== 'paymentBalance' &&
        type !== 'referral' && (
          <Select
            options={lengthFilter}
            setValue={(e) => {
              selectionHandler(e, 'filter');
            }}
            value={selections?.filter as Option}
            name="filter-selection"
          />
        )}
    </>
  );

  const topSelector =
    type === 'home' ? (
      <SendButton
        type={type}
        className="send-button"
        css={styleButton}
        onClick={sendForm}
      >
        Run
      </SendButton>
    ) : null;
  // (
  //   <Select
  //     options={lengthFilter}
  //     setValue={(e) => {
  //       selectionHandler(e, 'filter')
  //     }}
  //     value={selections?.filter as Option}
  //     name="filter-selection"
  //   />
  // )

  const isTraffic = type === 'traffic';
  const val = isTraffic ? selections?.traffic : selections?.offers;
  const from = isTraffic ? 'traffic' : 'offers';

  const offerSelector = (
    <LabelSelect css={offerSelectorStyles}>
      Offer:
      <Select
        isMulti={!isTraffic}
        isSearch
        options={offersList}
        setValue={(e) => {
          selectionHandler(e, from);
        }}
        value={val as Option}
        name="offer-selection"
      />
    </LabelSelect>
  );

  const conversionSelectors = (
    <SecondLevel>
      {offerSelector}

      <LabelSelect>
        Status:
        <Select
          isMulti
          options={status}
          setValue={(e) => {
            selectionHandler(e, 'status');
          }}
          value={selections?.status as Option}
          name="status-selection"
        />
      </LabelSelect>

      <LabelSelect>
        Paid Status:
        <Select
          options={paidStatus}
          setValue={(e) => {
            selectionHandler(e, 'paidStatus');
          }}
          value={selections?.paidStatus as Option}
          name="paid-status-selection"
        />
      </LabelSelect>
    </SecondLevel>
  );

  const reportsControl = (
    <ControlsLevel affiliates={affiliates !== undefined}>
      <Controls
        type={type as any}
        css={{ gridColumn: 4 }}
      >
        <MicroLevel>
          <ResetButton onClick={resetForm}>Reset Filter</ResetButton>
          <ControlsSendButton
            type={type as any}
            onClick={sendForm}
          >
            Run
          </ControlsSendButton>
        </MicroLevel>
        <DownloadButton
          type="icon"
          onClick={showFileDownloadHandler}
        >
          <FontAwesomeIcon icon={faDownload} />
        </DownloadButton>
        {showFileDownload && (
          <FileDownload onClick={createFileNDownload}>
            Export as CSV
          </FileDownload>
        )}
      </Controls>
    </ControlsLevel>
  );

  const paymentContent = (
    <PaymentLevel css={{ fontSize: '15px' }}>
      Select Month:
      <Select
        isSearch
        options={payment}
        setValue={(e) => {
          selectionHandler(e, 'month');
        }}
        value={selections?.month as Option}
        name="filter-selection"
      />
    </PaymentLevel>
  );

  const topSelectorIgnoreTypes = ['traffic', 'paymentBalance', 'referral'];

  const userSelector: React.JSX.Element = () => {
    if (type === 'traffic') {
      return (
        <UsersControl>
          <div style={{ marginBottom: '5px' }}>Users:</div>
          <Select
            isMulti={true}
            isSearch={true}
            styleDrop={{ width: '100%' }}
            placeholder={'All'}
            options={affiliates}
            setValue={(e) => {
              selectionHandler(e, 'affiliates');
            }}
            value={
              selections.affiliates !== undefined
                ? selections.affiliates
                : affiliates[0]
            }
            name="affiliates-selection"
          />
        </UsersControl>
      );
    }

    return (
      <AffiliatesControl>
        <div style={{ marginBottom: '5px' }}>Users:</div>
        <Select
          isMulti={true}
          isSearch={true}
          styleDrop={{ width: '100%' }}
          placeholder={'All'}
          options={affiliates}
          setValue={(e) => {
            selectionHandler(e, 'affiliates');
          }}
          value={
            selections.affiliates !== undefined
              ? selections.affiliates
              : affiliates[0]
          }
          name="affiliates-selection"
        />
      </AffiliatesControl>
    );
  };

  const notPaymentContent = (
    <>
      <Level type={type as any}>
        {baseSelectors}
        {!topSelectorIgnoreTypes.includes(type) && topSelector}
      </Level>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridColumnGap: '1rem',
        }}
      >
        {role === 'affiliate' &&
          (type === 'summary' ||
            type === 'brands' ||
            type === 'conversion' ||
            type === 'traffic') &&
          userSelector()}
        {role === 'account_manager' &&
          (type === 'conversion' || type === 'paymentBalance') &&
          userSelector()}
        {role === 'admin' &&
          (type === 'conversion' || type === 'paymentBalance') &&
          userSelector()}

        {type === 'traffic' && offerSelector}
        {type === 'conversion' && conversionSelectors}
      </div>
    </>
  );

  return (
    <Container {...props}>
      {errorMessages && <ErrorMessage />}

      {type === 'payment' ? paymentContent : notPaymentContent}
      {type !== 'home' && reportsControl}
    </Container>
  );
};

export default DateForm;
