// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { createContext, useEffect, useState } from 'react';

// Set initial values for user credentials
const initialGlobalVariables = {
  auth: false,
  bmkInfo: {},
};

// Create the GlobalContext
export const GlobalContext = createContext(initialGlobalVariables);

export const GlobalProvider: React.FC = ({ children }) => {
  const [globalVariables, setGlobalVariables] = useState(
    initialGlobalVariables,
  );

  const updateGlobalVariables = (newGlobals): void => {
    window.localStorage.setItem('globals', JSON.stringify(newGlobals));

    setGlobalVariables((prevGlobal) => ({
      ...prevGlobal,
      ...newGlobals,
    }));
  };

  useEffect(() => {
    if (window.localStorage.getItem('globals') !== null) {
      const { auth } = JSON.parse(window.localStorage.getItem('globals'));
      const newGlobals = {
        auth,
      };

      setGlobalVariables(newGlobals);
    }
  }, []);

  return (
    <GlobalContext.Provider value={{ globalVariables, updateGlobalVariables }}>
      {children}
    </GlobalContext.Provider>
  );
};
