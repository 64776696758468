// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const Page404 = () => {
  return (
    <>
      <h1>Page not found</h1>
      <div>The requested page could not be found.</div>
    </>
  );
};

export default Page404;
