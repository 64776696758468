import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaste } from '@fortawesome/free-regular-svg-icons';
import { styled } from './../../../theme/index';
import CursiveLink from './../../atoms/CursiveLink/CursiveLink';
import CopyDisclaimer from './../../atoms/CopyDisclaimer/CopyDisclaimer';

const Container = styled('div', {
  display: 'flex',
  paddingLeft: 0,
  paddingRight: 0,
  alignItems: 'center',
});

const Icon = styled('span', {
  paddingRight: '6px',
});

const CopyLink: React.FC = () => {
  const [copyLink, setCopyLink] = React.useState(false);

  const clickHandler = (): void => {
    setCopyLink(true);
  };

  return (
    <Container>
      <CursiveLink
        css={{
          fontSize: '20px',
          fontWeight: '700',
          lineHeight: 1.4,
          letterSpacing: '0.93px',
          '&:hover': {
            color: '#275153',
            textDecoration: 'none',
          },
        }}
        color="green"
        onClick={() => {
          clickHandler();
        }}
      >
        <Icon>
          <FontAwesomeIcon icon={faPaste} />
        </Icon>
        Copy Link
      </CursiveLink>

      {copyLink && (
        <CopyDisclaimer
          css={{
            marginLeft: '50px',
          }}
        >
          Link Copied
        </CopyDisclaimer>
      )}
    </Container>
  );
};

export default CopyLink;
