import React from 'react';
import { styled, type ThemeProps } from '../../../theme/index';
import Text from '../../atoms/Text/Text';

const Container = styled('div', {
  display: 'block',
  maxWidth: '100%',
  padding: '0',
});

const Title = styled('h4', {
  color: '$black',
  fontFamily: '$heading',
  fontSize: '$size18',
  fontWeight: '$bold',
  letterSpacing: '0.5px',
  lineHeight: 1,
  textTransform: 'uppercase',
  marginBottom: '29px',
});

const Question = styled(Text, {
  fontFamily: '$heading',
  fontSize: '$size17',
  fontWeight: '$bold',
  letterSpacing: '0.5px',
  lineHeight: 1.529,
  textTransform: 'uppercase',
  marginBottom: '10px',
  marginTop: '30px',
});

const Paragraph = styled(Text, {
  fontSize: '$size16',
  marginBottom: '10px',
  letterSpacing: '-0.2px',
  lineHeight: 1.666,
});

const OrderedList = styled('ul', {
  margin: '0 0 10px',
});

const ListElement = styled('li', {
  fontFamily: '$body',
  fontSize: '16px',
  lineHeight: 1.562,
  letterSpacing: '-0.2px',
});

const LinkVariant = styled('a', {
  color: '#7fbdb8',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const SupportContact = styled('div', {
  textAlign: 'center',
});

const QuestionSupport = styled('h3', {
  color: '$black',
  fontFamily: '$heading',
  fontSize: '25px',
  fontWeight: '$bold',
  letterSpacing: '0.2px',
  lineHeight: 1,
  borderBottom: 'none',
  marginBottom: '5px',
  paddingTop: '20px',
  paddingBottom: '17px',
  marginTop: 0,
});

const LinkContact = styled('a', {
  color: '$black',
  textDecoration: 'underline',
  fontWeight: '$bold',
  fontSize: '$size16',
});

const UnderlineText = styled('span', {
  textDecoration: 'underline',
});

const FaqsContent: React.FC<ThemeProps> = ({ ...props }) => {
  return (
    <Container
      className="supportTabs__tabContent supportTabs__tabContent--faqs"
      {...props}
    >
      <Title className="supportTabs__tabContentTitle">
        FREQUENTLY ASKED QUESTIONS
      </Title>
      <Question>WHAT IS BRANDCYCLE?</Question>
      <Paragraph>
        We’re a premium affiliate marketing network that connects the top retail
        brands with creators*, such as bloggers, content creators, influencers,
        affiliates, and mass media publishers. Our exclusive technology lets
        creators promote multiple brands under one platform and earn a
        commission on purchases made via their promotional offerings. We also
        negotiate paid, flat fee campaigns for our most active members!
      </Paragraph>
      <Paragraph>
        TLDR: If you’re an everyday creator, our platform allows you to earn
        money by promoting multiple products from multiple brands with easy and
        convenient technology.
      </Paragraph>
      <Paragraph>
        *Please note: We use “creators” when referring to bloggers, content
        creators, influencers, affiliates, and mass media publishers in our
        exciting world of marketing!
      </Paragraph>

      <Question>HOW DOES BRANDCYCLE WORK?</Question>
      <Paragraph>
        We’ve developed technology that gives creators a single platform to
        access a network of hundreds of brands to promote. Previously, creators
        that wanted to become an affiliate partner of a given brand had to apply
        to the brand’s marketing program one-by-one. We took the hard work out
        and developed a very simple and quick one-step process! You can join
        today and start promoting tomorrow!
      </Paragraph>
      <Paragraph>
        We provide all the exclusive content to build high performing
        cost-per-acquisition (CPA) campaigns to promote deals and offers. We
        also provide paid, flat fee campaigns for our most active members.
        Creators on BrandCycle work with the retail brands they want, when they
        want. It’s free to join, and creators make equal or greater commission
        working through BrandCycle compared to working directly with a brand or
        through other affiliate networks.
      </Paragraph>

      <Question>WHICH CREATORS WORK WITH BRANDCYCLE?</Question>
      <Paragraph>
        We work with creators that have built a business on creating and
        publishing content via social media, websites, blogs, and/or video.
        Although our creators are primarily aligned in the lifestyle market, we
        encourage a diversity of creators to apply. Our main niches include mom,
        kids & family; style & beauty, home & décor, health & wellness,
        technology, travel, food & drink, eco-friendly, pets, entertainment &
        art, wedding and deal seekers. An active social media footprint, blog,
        website, and/or video channel is required to apply.
      </Paragraph>
      <Paragraph>
        We do not work with coupon aggregator websites, apps, toolbars,
        cash-back, and loyalty affiliates. We do not work with creators that
        purposely expose brands with invalid deals/codes.
      </Paragraph>
      <Paragraph>
        To be accepted, we will review your current platform content and
        evaluate whether it aligns with BrandCycle’s standards.
      </Paragraph>

      <Question>WHICH BRANDS WORK WITH BRANDCYCLE?</Question>
      <Paragraph>
        We work with a wide range of brands, and new brands are added
        frequently. Currently, we work with more than 600 of the top brand
        affiliate programs, including Kate Spade, Macy’s, Old Navy, Wayfair,
        Coach, Carter’s, and Nordstrom.
      </Paragraph>

      <Question>
        HOW OFTEN DOES BRANDCYCLE ADD NEW PRODUCTS AND CONTENT?
      </Question>
      <Paragraph>
        We offer daily emails, weekly newsletters, a private Facebook group,
        paid influencer campaigns, and an easy-to-use network, among other
        things!
      </Paragraph>
      <Paragraph>
        In addition, we work with brands to develop exclusive campaigns for
        creators within the BrandCycle network. We are constantly sourcing new
        sales, promotions, and exclusive offerings from brands for our creators
        to promote. These promotions are sent via a daily email and weekly
        newsletter, allowing you to plan out content to promote.
      </Paragraph>

      <Question>
        DO CREATORS EARN LESS BY WORKING WITH BRANDCYCLE VERSUS DIRECTLY WORKING
        WITH BRANDS?
      </Question>
      <Paragraph>
        Creators make equal or greater commission working through BrandCycle
        compared to working directly with a brand or through a traditional
        affiliate network.
      </Paragraph>

      <Question>HOW DOES THE BROWSER EXTENSION TOOL WORK?</Question>
      <Paragraph>
        Our extension lets creators create links in one click while browsing
        their favorite brands! Just install, visit your favorite brand, and then
        click the icon or right-click anywhere on the page to create a
        BrandCycle link.
      </Paragraph>

      <Question>HOW CAN I DOWNLOAD THE BROWSER EXTENSION TOOL?</Question>
      <Paragraph>
        Simply{' '}
        <LinkVariant
          href="https://chromewebstore.google.com/detail/brandcycle/flljmcpelnkhfjdcchklalpaaolohdjo"
          target="_blank"
        >
          click on this link
        </LinkVariant>{' '}
        to download it onto your browser.
      </Paragraph>

      <Question>
        WHICH BROWSERS ARE COMPATIBLE WITH THE BROWSER EXTENSION?
      </Question>
      <Paragraph>
        The extension is compatible with Google Chrome or Edge on both Mac and
        PC.
      </Paragraph>

      <Question>HOW DOES THE EXTENSION WORK?</Question>
      <Paragraph>
        The BrandCycle Extension allows approved publishers to quickly generate
        affiliate links directly from their browser. Once installed, you can
        create links with just one click while browsing a retailer&apos;s site,
        without needing to switch between tabs or search the BrandCycle portal.
        It streamlines the link creation process, making it easier and faster to
        share products with your audience.
      </Paragraph>

      <Question>HOW CAN I DOWNLOAD THE EXTENSION?</Question>
      <Paragraph>
        The BrandCycle Extension can be downloaded using this{' '}
        <LinkVariant href="https://brandcycle.shop/chrome-extension">
          link
        </LinkVariant>
        . Find additional instructions{' '}
        <LinkVariant href="/extension">here</LinkVariant>.
      </Paragraph>

      <Question>HOW DOES THE BOOKMARKLET TOOL WORK?</Question>
      <Paragraph>
        Our proprietary technology, which is not available through the
        traditional affiliate networks, allows BrandCycle to deliver content and
        campaigns to creators and affiliates in a more streamlined,
        user-friendly way.
      </Paragraph>
      <Paragraph>
        Our bookmarklet tool can be dragged and dropped into a browser&apos;s
        bookmarks toolbar. This button enables creators to make money and create
        links right from a brand&apos;s site. With our simple tool, BrandCycle
        creators can access exclusive deals and content with a single click.
      </Paragraph>

      <Question>HOW CAN I DOWNLOAD THE BOOKMARKLET?</Question>
      <Paragraph>
        The bookmarklet can be downloaded with a simple drag-and-drop.
        BrandCycle-approved creators can download our free proprietary
        bookmarklet tool from the{' '}
        <LinkVariant href="/bookmarklet">BrandCycle portal</LinkVariant>.
      </Paragraph>

      <Question>WHICH BROWSERS ARE COMPATIBLE WITH THE BOOKMARKLET?</Question>
      <Paragraph>
        The bookmarklet is compatible with all major browsers.
      </Paragraph>

      <Question>IS IT FREE TO JOIN BRANDCYCLE?</Question>
      <Paragraph>
        It’s free and simple, and there are never any hidden fees. What you see
        is what you get!
      </Paragraph>

      <Question>HOW DO CREATORS RECEIVE PAYMENTS?</Question>
      <Paragraph>
        When a creator creates a BrandCycle account, they should navigate to the
        “My Account” page to set up payment details with one of our two payment
        providers, PayPal or Payoneer. Creators will be prompted to set-up a
        PayPal or Payoneer account with a separate login and password. Once an
        account is set-up, creators can choose a desired method of payment
        (direct deposit or pre-paid debit card) and access the PayPal or
        Payoneer account at any time to manage payment details and view
        transaction history.
      </Paragraph>

      <Question>WHEN WILL CREATORS BE PAID?</Question>
      <Paragraph>
        BrandCycle functions as a mediator connecting creators and brands.
        Creators are compensated by BrandCycle upon receipt of payment from
        brands. The timeline for payments from brands can fluctuate due to
        factors such as the brand&apos;s business nature or the standard
        return/refund processing time for consumer transactions. While it&apos;s
        typical for brand payments to occur within 30-90 days post-transaction,
        some brands might extend this duration, and take more time to finalize
        their payments to BrandCycle.
      </Paragraph>

      <Paragraph>
        Payments from BrandCycle will be credited to the payment details that a
        creators enters when setting up a BrandCycle account. When a
        creators&apos;s balance reaches $20, he or she can transfer to a linked
        account.
      </Paragraph>

      <Paragraph>
        Note: If you are using an international bank account (not US-based), and
        have chosen Payoneer as your payment provider, your balance will need to
        reach $50 USD until the funds can be transferred. If this is your
        situation, we&apos;d recommend choosing PayPal to receive a payout at
        $20.
      </Paragraph>

      <Paragraph>
        BrandCycle processes all payments to creators received from the previous
        month on the 15th day of every month (or the closest business day if the
        15th falls on a weekend).
      </Paragraph>

      <Question>HOW DOES BRANDCYCLE HANDLE CLICK FRAUD?</Question>
      <Paragraph>
        BrandCycle takes steps to ensure that clicks generated from your website
        are not of a fraudulent nature e.g. from BrandCycle creators or
        technology used to produce false clicks.
      </Paragraph>

      <Question>WHY IS A TAX FORM NECESSARY FOR PAYMENT?</Question>
      <Paragraph>
        As a creators working with BrandCycle, the IRS requires our company to
        report the annual income a creators receives through the program via a
        1099 form. Once a creators receives $600 in payouts, that creators must
        submit a W9 form through their PayPal or Payoneer account, which is an
        industry standard for affiliate programs.
      </Paragraph>

      <Question>
        ARE THERE OPPORTUNITIES FOR PERSONALIZED AND CUSTOM CAMPAIGNS?
      </Question>
      <Paragraph>
        Yes! We offer exclusive influencer campaigns to our BrandCycle network.
        The influencer campaigns allow you to reach a wider audience, sell more,
        and possibly earn a flat fee doing so! We constantly source our network
        for influencers to be part of upcoming campaigns. If you’re interested
        in custom opportunities, email{' '}
        <LinkVariant href="mailto:publishers@brandcycle.com">
          publishers@brandcycle.com
        </LinkVariant>{' '}
        for more information.
      </Paragraph>

      <Question>
        WHAT IS THE DIFFERENCE BETWEEN WORKING WITH BRANDCYCLE COMPARED TO OTHER
        CONTENT MONETIZATION PLATFORMS?
      </Question>
      <Paragraph>
        We believe in transparency. We work on your behalf with everything that
        we do. That means constantly negotiating higher commission rates,
        sourcing the best daily deals, and expanding our technology to support
        you in making more online income.
      </Paragraph>
      <Paragraph>
        We also offer programs within our network that allow you to earn
        significant bonuses when you reach a certain threshold in your sales. We
        have built our business on supporting you.
      </Paragraph>
      <Paragraph>
        Fun fact: Whether you call us or shoot us an email, you will get a
        response from a real person!
      </Paragraph>

      <Question>
        HOW DO CREATORS KNOW HOW MANY CLICKS AND HOW MUCH REVENUE THEY ARE
        GENERATING?
      </Question>
      <Paragraph>
        BrandCycle creators can monitor their performance using the Reports
        section in the BrandCycle portal.
      </Paragraph>

      <Question>
        I’M NEW TO CONTENT MONETIZATION. HOW DOES AFFILIATE MARKETING WORK?
      </Question>
      <Paragraph>
        Affiliate marketing is a marketing channel for brands to reach a wider
        audience via online sales. Brands developed a system to reward creators
        with commission on purchases made via their online promotions. Becoming
        an affiliate offers the opportunity to monetize content and leverage
        online influence when posting on web, social and video platforms, and
        apps.
      </Paragraph>
      <Paragraph>
        TLDR: You share products you love + your followers purchase those
        products = you earn money!
      </Paragraph>
      <Paragraph>
        We provide the brand relationships, tracking links, reporting, payments,
        and more to support you in your affiliate marketing efforts.
      </Paragraph>

      <Question>
        HOW DO I ADD MULTIPLE USER ACCOUNTS UNDER MY MASTER ACCOUNT?
      </Question>
      <Paragraph>
        Creators can now have multiple user accounts with different permission
        levels under one master account. Follow these{' '}
        <LinkVariant href="https://mcusercontent.com/7b2372ad67fad9e6a4e065b97/files/66f3a83b-5269-e22a-08fb-a31bc52a5a0e/BrandCycle_Users_Instructions.pdf">
          step-by-step instructions
        </LinkVariant>{' '}
        to set up new Users within your BrandCycle account.
      </Paragraph>

      <Question>HOW DOES OUR REFERRAL PROGRAM WORK?</Question>
      <Paragraph>
        To participate in the Program, follow these easy{' '}
        <LinkVariant href="https://mcusercontent.com/7b2372ad67fad9e6a4e065b97/files/95f2abb1-91e6-aad8-6651-6876a2b3dab6/Step_by_Step_How_Does_the_Referral_Program_Work_.pdf">
          step-by-step instructions
        </LinkVariant>{' '}
        to refer as many friends or colleagues as you want to the Platform.
        There is no limit to the number of referrals you can make. However, we
        do ask that you please refer only qualified individuals who meet
        BrandCycle requirements. We reserve the right to modify or amend these
        Terms, the Program, or any part of such, at any time and for any reason.
        We may also deny participation in our Program if we have any reason to
        believe that you are not in compliance with these Terms or the Program.
      </Paragraph>

      <Question>WHAT IS A QUALIFIED REFERRAL?</Question>
      <Paragraph>
        A &quot;Qualified Referral&quot; requires that all of the following
        conditions are met:
      </Paragraph>
      <OrderedList>
        <ListElement>
          Your Friend must be a new BrandCycle member– i.e. they have not
          previously worked with BrandCycle in the past, nor do they have an
          account or registration with us under any email address or alias. This
          will be determined at BrandCycle’s sole discretion.
        </ListElement>
        <ListElement>
          Your Friend must complete their first sale within thirty (30) days of
          being approved to join BrandCycle.
        </ListElement>
      </OrderedList>

      <Question>HOW LONG DOES IT TAKE TO GET MY REFERRAL BONUS?</Question>
      <Paragraph>
        <strong>REFERRAL:</strong> After you make your first sale, the $25 bonus
        will be added to this balance, and your earnings will accrue
        cumulatively until you hit the $10 payout threshold. BrandCycle pays out
        on the 15th of every month — if you have more than $20 in locked
        earnings, you will be paid on the next payout date.
      </Paragraph>
      <Paragraph>
        <strong>REFERRER:</strong> In order for you to get paid, your Referral
        has to make a sale. Once they do, a $25 bonus will be added to your
        account. If you have more than $20 in locked earnings at this point, you
        will be paid on the next payout date (15th of every month).
      </Paragraph>
      <Paragraph>
        Reminder: BrandCycle has a $20 minimum payout threshold in order to get
        paid. If you are using an international bank account (not US-based), and
        have chosen Payoneer as your payment provider, your balance will need to
        reach $50 USD until the funds can be transferred. If this is your
        situation, we&apos;d recommend choosing PayPal to receive a payout at
        $20.
      </Paragraph>

      <Question>WHAT IF MY REFERRAL DOESN’T MAKE A SALE?</Question>
      <Paragraph>
        If your Referral doesn&apos;t{' '}
        <UnderlineText>
          make a sale within 30 days after they were approved
        </UnderlineText>{' '}
        to join BrandCycle, neither of you will receive the bonus.
      </Paragraph>

      <Question>WHAT IS MY REFERRAL CODE AND WHERE CAN I FIND IT?</Question>
      <Paragraph>
        A Referral Code is a code that is used to track each person that you’ve
        invited to join BrandCycle. This code is unique to each BrandCycle
        creator and can be found under the Refer a Friend tab on the platform.
      </Paragraph>

      <Question>
        CAN I BUILD AN AFFILIATE LINK USING THE BRAND LINKS FROM THE NEWSLETTERS
        AND EMAILS THAT BRANDCYCLE SENDS TO THE creator?
      </Question>
      <Paragraph>
        Yes, the links in our newsletters are clean links and contain no
        affiliate tracking. These links can be used to create your own
        BrandCycle affiliate link. To further clarify, if a creator clicks a
        link from any of our newsletters, opens a browser, and then creates a
        deep link using the bookmarklet, build-a-link tool, browser extension or
        our app, and then promotes that link via one of their social handles,
        and a consumer converts off of it, the commissions will be paid back to
        the creator.
      </Paragraph>

      <SupportContact>
        <QuestionSupport>STILL HAVE QUESTIONS?</QuestionSupport>
        <Paragraph>
          <LinkContact href="/contact-support">Contact Support</LinkContact> and
          we’ll help you find an answer.
        </Paragraph>
      </SupportContact>
    </Container>
  );
};

export default FaqsContent;
