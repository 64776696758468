import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  href?: string;
  type?: 'secondary' | 'third' | 'fourth' | 'dashboard' | 'sideSubMenu';
}

const Container = styled('div', {
  display: 'block',
  fontSize: '$size16',
  color: '$green4',
  cursor: 'pointer',
  fontWeight: '$bold',
  lineHeight: 1.4,
  textDecoration: 'none',
  variants: {
    type: {
      secondary: {
        marginLeft: '30px',
        fontFamily: '$body',
        fontWeight: '$bold',
        fontSize: '17px',
        textDecoration: 'none',
        textTransform: 'uppercase',
        color: '$black',
        paddingBottom: '10px',
        borderBottom: '6px solid $clear',
        backgroundColor: 'transparent',
        '&:hover': {
          color: '$blue4',
          paddingBottom: '10px',
          borderBottom: '6px solid $blue4',
        },
      },
      third: {
        fontFamily: '$heading',
        fontWeight: '$bold',
        color: '$black',
        fontSize: '17px',
        lineHeight: '20px',
        letterSpacing: '1.5px',
        textDecoration: 'none',
        textTransform: 'uppercase',
      },
      fourth: {
        textDecoration: 'none',
        color: '#4b516c',
        fontWeight: 'normal',
      },
      dashboard: {
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: 1,
        color: '$black',
        fontFamily: '$body',
        textTransform: 'uppercase',
      },
      sideSubMenu: {
        letterSpacing: 0,
        fontFamily: '$heading',
        textTransform: 'uppercase',
        fontSize: '15px',
        padding: '10px 20px',
        color: '$black',
        background: 'transparent',
        '&:hover': {
          background: '$clear3',
        },
        '@bp3': {
          color: '$clear',
          '&:hover': {
            background: 'transparent',
          },
        },
      },
      sideDashboardMobile: {
        letterSpacing: 0,
        fontFamily: '$heading',
        textTransform: 'uppercase',
        fontSize: '15px',
        color: '$black',
      },
    },
  },
});

const Link: React.FC<Props> = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};

export default Link;
