import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';

interface Props extends ThemeProps {
  options: string[];
}

const Container = styled('div', {
  margin: '20px auto',
  width: '100%',
  maxWidth: '288px',
  paddingTop: '29px',
});

const ChartItems = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
});

const Item = styled('div', {
  marginTop: '2px',
  marginLeft: '22px',
  fontSize: '12px',
  fontFamily: '$heading',
  fontWeight: '$bold',
  color: '#333333',
  cursor: 'pointer',
});

const Dot = styled('div', {
  display: 'block',
  position: 'relative',
  width: '20px',
  height: '20px',
  textTransform: 'capitalize',
  variants: {
    type: {
      sales: {
        color: '#008080',
      },
      clicks: {
        color: '#808080',
      },
      payouts: {
        color: '#800080',
      },
      orders: {
        color: '#a9a9a9',
      },
    },
  },
  '&:after': {
    position: 'absolute',
    border: '1px solid currentColor',
    background: 'currentColor',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    content: '',
    display: 'block',
    width: '100%',
    height: '2px',
    borderRadius: '5px',
  },
  '&:before': {
    position: 'absolute',
    border: '1px solid currentColor',
    background: 'currentColor',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    content: '',
    display: 'block',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
  },
});

export const GraphBottom: React.FC<Props> = ({ options }) => {
  return (
    <Container>
      <ChartItems>
        {options.map((value) => (
          <Dot
            key={value}
            type={value as any}
          >
            <Item>{value}</Item>
          </Dot>
        ))}
      </ChartItems>
    </Container>
  );
};

export default GraphBottom;
