import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import PromotionCard, {
  type Props as PropsCard,
} from './../../organisms/PromotionCard/PromotionCard';

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  promotions: PropsCard[];
}

const Container = styled('div', {
  paddingBottom: 0,
  paddingTop: 0,
});

const Body = styled('div', {
  paddingTop: '21px',
  marginBottom: '20px',
  borderBottom: '1px solid #bbbbbb',
  paddingBottom: '23px',
  '&:last-child': {
    borderBottom: 'none',
  },
  '@bp3': {
    paddingTop: 0,
  },
});

const SpecialPromotionsGrid: React.FC<Props> = ({
  promotions = [],
  ...props
}) => {
  return (
    <Container {...props}>
      {promotions.map((value, i) => (
        <Body key={`promotions-${value.title}-${i}`}>
          <PromotionCard
            details={value.details}
            title={value.title}
            titleUrl={value.titleUrl}
            dealID={value.dealID}
            asItem={value.asItem}
            offSet
          ></PromotionCard>
        </Body>
      ))}
    </Container>
  );
};

export default SpecialPromotionsGrid;
