import React, { useState, useRef, useEffect } from 'react';
import { BaseImageProps } from 'src/types/baseComponentTypes';
import './baseImage.scss';
import defaultImage from 'src/images/image-placeholder-square.svg';

const BaseImage: React.FC<BaseImageProps> = ({
  src,
  alt,
  className = '',
  width,
  height,
  defaultSrc = defaultImage,
  lazy = false,
  objectFit = 'contain',
}) => {
  const [imageSrc, setImageSrc] = useState(lazy ? defaultSrc : src);
  const [isLoading, setIsLoading] = useState(lazy);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (lazy && imgRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setImageSrc(src); // loads image when in view
            setIsLoading(true);
            observer.disconnect(); // stops observing after loading
          }
        },
        { threshold: 0.05 }, // triggers loading when 5% of image is visible
      );

      observer.observe(imgRef.current);

      return () => observer.disconnect(); // cleanup observer on unMount
    } else {
      setImageSrc(src); // load image if not lazy
    }
  }, [lazy, src]);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setIsLoading(false);
    setImageSrc(defaultImage); // Set the placeholder image on error
  };

  return (
    <img
      className={`baseImage ${
        isLoading ? 'baseImage--loading' : ''
      } ${className}`}
      ref={imgRef}
      src={imageSrc}
      alt={alt}
      width={width}
      height={height}
      style={{ '--object-fit': objectFit } as React.CSSProperties}
      loading={lazy ? 'lazy' : 'eager'}
      onError={handleError}
      onLoad={handleLoad}
    />
  );
};

export default BaseImage;
