// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

const getParentDetails = (parentURL, setParentURL, setImagesList) => {
  if (localStorage.getItem('images') !== null) {
    setParentURL(localStorage.getItem('parentUrl'));
    setImagesList(JSON.parse(localStorage.getItem('images')));
    localStorage.removeItem('parentUrl');
    localStorage.removeItem('images');
  } else {
    window.addEventListener(
      'message',
      (event) => {
        if (event.data.action === 'request-site-info') {
          const data = event.data.value;
          // console.log("request-site-info: ", data);
          if (parentURL === '') {
            setParentURL(data.target); // save parent URL
          }

          setImagesList(data.websiteInfo.images);
        }
      },
      true,
    );
  }
};

export default getParentDetails;
