import React from 'react';
import { styled } from './../../../theme/index';
import type { CSS } from '@stitches/react';

interface DropdownProps {
  children?: React.ReactNode;
  readonly isOpen: boolean;
  readonly target: React.ReactNode;
  readonly onClose: () => void;
  styleDrop?: CSS;
}

const Wrapper = styled('div', {
  position: 'relative',
});

const Menu = styled('div', {
  backgroundColor: '$clear',
  borderRadius: 1,
  border: '1px solid $blue7',
  borderTop: 'none',
  position: 'absolute',
  zIndex: 2,
  width: '100%',
});

const Blanket = styled('div', {
  backgroundColor: 'transparent',
  bottom: 0,
  left: 0,
  top: 0,
  right: 0,
  position: 'fixed',
  zIndex: 1,
});
const Dropdown: React.FC<DropdownProps> = ({
  styleDrop = undefined,
  children,
  isOpen,
  target,
  onClose,
}) => (
  <Wrapper>
    {target}
    {isOpen ? <Menu css={styleDrop}>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </Wrapper>
);

export default Dropdown;
