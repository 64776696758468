// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useTable } from 'react-table';

export interface VariantProps {
  variant?:
    | 'summary'
    | 'conversion'
    | 'payment'
    | 'brands'
    | 'traffic'
    | 'default'
    | 'paymentBalance'
    | 'referral';
}

interface Props extends VariantProps {
  columns?: object[];
  data: object[];
  variant?:
    | 'conversion'
    | 'summary'
    | 'payment'
    | 'brands'
    | 'traffic'
    | 'default'
    | 'paymentBalance'
    | 'referral';
  overrideTotals?: Array<string | number | null>;
}

const UseTableTotals = ({
  columns,
  data,
  variant,
  overrideTotals,
}: Props): Array<string | number | null> => {
  const adder = {
    paymentBalance: ['Totals', null, 0, 0, 0, 0, 0, 0, 0, 0],
    conversion: [null, null, null, 0, 0, null, 0, 0, null, null],
    summary: ['Totals', 0, 0, 0, 0, 0, 0],
    payment: ['Totals', 0, 0, 0],
    brands: ['Totals', null, 0, 0, 0, 0, 0],
    traffic: ['Totals', null, null, null, 0, 0, 0, 0],
    default: [],
  };

  const totals = overrideTotals ?? adder[variant as string];

  const { rows, prepareRow } = useTable({
    columns,
    data,
  });

  rows.forEach((row) => {
    prepareRow(row);
    row?.cells.forEach((cell, i) => {
      if (typeof totals[i] === 'number') {
        const total = totals[i];
        cell = cell.value === undefined ? 0 : cell.value;
        totals[i] = parseFloat(total) + parseFloat(cell);
      }
    });
  });

  return totals;
};

export default UseTableTotals;
