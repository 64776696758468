// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';

const Icon = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
    >
      <g
        fill="currentColor"
        fillRule="evenodd"
      >
        <path d="M11.25 15a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0" />
        <path d="M10 18.125A8.125 8.125 0 0 1 1.875 10 8.125 8.125 0 0 1 10 1.875 8.125 8.125 0 0 1 18.125 10 8.125 8.125 0 0 1 10 18.125M10 0C4.478 0 0 4.478 0 10c0 5.523 4.478 10 10 10 5.523 0 10-4.477 10-10 0-5.522-4.477-10-10-10" />
        <path d="M10 12.505a.938.938 0 0 1-.938-.938v-.625c0-.656.254-1.247.753-1.755.367-.375.816-.656 1.249-.927.889-.559 1.28-.859 1.28-1.384 0-.571-.332-.952-.606-1.172-.444-.353-1.062-.547-1.738-.547-1.026 0-1.95.685-2.245 1.667a.937.937 0 1 1-1.795-.54 4.25 4.25 0 0 1 1.5-2.152 4.18 4.18 0 0 1 2.539-.85c1.097 0 2.128.34 2.901.953.85.674 1.317 1.611 1.317 2.641 0 1.616-1.247 2.4-2.158 2.971-.763.48-1.123.745-1.123 1.095v.625c0 .518-.42.938-.937.938H10Z" />
      </g>
    </svg>
  );
};

export default Icon;
