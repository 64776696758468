// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useEffect, useState, useContext } from 'react';
import { type ThemeProps } from './../../../theme/index';
import DateForm from './../../molecules/DateForm/DateForm';
import DataTables from './../../atoms/DataTables/DataTables';
import { Title, Border, Container } from './styles';
import LoadingOverlay from '../../atoms/LoadingOverlay/LoadingOverlay';
import client from '../../api';
import { UserContext } from '../../../context/UserContext';
import moment from 'moment';
import ErrorMessage from '../../atoms/ErrorMessage/ErrorMessage';

interface Props extends ThemeProps {
  data?: object[];
}

const headers = [
  {
    Header: 'Fist Name',
    accessor: 'fistName',
    style: {
      width: `calc(100%/10)`,
    },
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    style: {
      width: `calc(100%/10)`,
    },
  },
  {
    Header: 'Email',
    accessor: 'email',
    style: {
      width: `calc(100%/10)`,
    },
  },
  {
    Header: 'Username',
    accessor: 'username',
    style: {
      width: `calc(100%/10)`,
    },
  },
  {
    Header: 'Affiliate ID',
    accessor: 'id',
    style: {
      width: `calc(100%/10)`,
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    style: {
      width: `calc(100%/10)`,
    },
  },
  {
    Header: 'Publisher Code',
    accessor: 'code',
    style: {
      width: `calc(100%/10)`,
    },
  },
  {
    Header: 'Date Joined',
    accessor: 'dateJoined',
    style: {
      width: `calc(100%/10)`,
    },
  },
  {
    Header: 'Date of Purchase',
    accessor: 'datePurchase',
    style: {
      width: `calc(100%/10)`,
    },
  },
  {
    Header: 'Referral ID',
    accessor: 'referralId',
    style: {
      width: `calc(100%/10)`,
    },
  },
  {
    Header: 'Referral Username',
    accessor: 'referralUsername',
    style: {
      width: `calc(100%/10)`,
    },
  },
  {
    Header: 'Referral Affiliate ID',
    accessor: 'referralAffiliateId',
    style: {
      width: `calc(100%/10)`,
    },
  },
  {
    Header: 'Referral Email',
    accessor: 'referralEmail',
    style: {
      width: `calc(100%/10)`,
    },
  },
];

const PaymentBalance: React.FC<Props> = ({ ...props }) => {
  const { userCredentials } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState('');
  const [dataTable, setDataTable] = useState([
    {
      fistName: '',
      lastName: '',
      email: '',
      username: '',
      id: 0, // affiliate ID
      status: '',
      code: '',
      dateJoined: '',
      datePurchase: '',
      referralId: '',
      referralUsername: '',
      referralAffiliateId: 0,
      referralEmail: '',
    },
  ]);
  // filter data
  const [fromDate, setFromDate] = useState(
    moment().subtract(6, 'd').format('YYYY-M-DD'),
  );
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
    callApi(fromDate, toDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const handleFilterChange = (newValues): void => {
    setLoading(true);

    setFromDate(moment(newValues.startDate).format('YYYY-MM-DD'));
    setToDate(moment(newValues.endDate).format('YYYY-MM-DD'));

    callApi(
      moment(newValues.startDate).format('YYYY-MM-DD'),
      moment(newValues.endDate).format('YYYY-MM-DD'),
    );
  };

  const callApi = (fromDate, toDate, exportType = false): void => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client
        .post(
          '/api/reports?_format=json',
          {
            type: 'referral_links_report',
            fromDate,
            toDate,
            export_type: exportType ? 'file' : '',
          },
          {
            headers: {
              'Content-type': 'application/x-www-form-urlencoded',
              csrf_token,
            },
            auth: { username, password },
          },
        )
        .then(({ data }) => {
          if (exportType) {
            // 2. download the file
            const element = document.createElement('a');
            const file = new Blob([data.data], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = data.fileName;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
          } else {
            setDataTable(data.tableData);
          }

          setLoading(false);
        })
        .catch((e) => {
          setErrorMessages('Failed to get data for the report.');
          console.log('Error Referral Links report, getting the report', e);
        });
    }
  };

  const handleExportFile = (): void => {
    setLoading(true);

    callApi(fromDate, toDate, true);
  };

  return (
    <Container {...props}>
      {loading && <LoadingOverlay />}

      <Title>Payment Balance Report</Title>

      {errorMessages !== '' ? <ErrorMessage message={errorMessages} /> : ''}

      <DateForm
        type="referral"
        callback={handleFilterChange}
        exportFile={handleExportFile}
      />
      <Border>
        <DataTables
          css={{ marginTop: '0' }}
          data={dataTable}
          columns={headers}
        />
      </Border>
    </Container>
  );
};

export default PaymentBalance;
