import React from 'react';
import { styled } from './../../../theme/index';
import BrandCollectionItem from './../../atoms/BrandCollectionItem/BrandCollectionItem';
import { type BrandCollectionProps } from './../../../types/brandTypes';

const Container = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  maxWidth: '100%',
  '@bp1': {
    gridTemplateColumns: '1fr 1fr',
  },
  '@media(min-width: 1200px)': {
    gridTemplateColumns: '1fr',
    maxWidth: '100%',
  },
});

const Row = styled('div', {
  borderBottom: '1px solid $lightgray2',
  width: '100%',
  padding: '12px',
  paddingBottom: '23px',
  '@bp1': {
    padding: '12px 18px 23px 0',
  },
  '@bp3': {
    padding: '0 0 23px',
  },
});

const HeadingTitle = styled('h2', {
  marginTop: '27px',
  fontSize: '44px',
  lineHeight: 1,
  fontFamily: '$quote',
  color: '$darkgray',
  padding: 0,
  borderBottom: 0,
  marginBottom: '12px',
  fontWeight: '500',
  textTransform: 'uppercase',
});

const ListWrapper = styled('ul', {
  margin: 0,
  padding: 0,
  display: 'grid',
  gridTemplateColumns: '1fr',
  '@media(min-width: 1200px)': {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
});

const BrandsCollection: React.FC<BrandCollectionProps> = ({
  brands,
  indexItem,
  ...Props
}) => {
  return (
    <Container
      className="brandsCollection"
      {...Props}
    >
      {brands.map((value, i) => {
        if (indexItem !== 'All' && indexItem !== value.title) {
          return null;
        }

        return (
          <Row
            className="brandsCollection__letterGroup"
            key={`brandCollection-${value.title}-${i}`}
          >
            <HeadingTitle className="brandsCollection__letterGroupTitle">
              {value?.title ?? ''}
            </HeadingTitle>
            <ListWrapper className="brandsCollection__letterGroupList">
              {value.options.map((item, i) => (
                <BrandCollectionItem
                  className="brandsCollection__letterGroupListItem"
                  item={item}
                  key={`userDetail-${item.name}-${i}`}
                />
              ))}
            </ListWrapper>
          </Row>
        );
      })}
    </Container>
  );
};

export default BrandsCollection;
