// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import { globalCss } from './index';

export const globalStyles = globalCss({
  html: {
    boxSizing: 'border-box',
    fontFamily: '$page',
    // webkitFontSmoothing: 'antialiased',
    // mozOsxFontSmoothing: 'grayscale'
  },
  '*, *:after, *:before': {
    boxSizing: 'inherit',
  },
  svg: { display: 'block' },
});

export const StylesTag: React.FC = (props: { children: React.ElementType }) => {
  globalStyles();
  return <>{props.children}</>;
};
