// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useContext, useEffect, useState } from 'react';
import { styled } from '../../../theme/index';
import DateForm from '../../molecules/DateForm/DateForm';
import DeepLinkReport from './../../molecules/DeepLinkReport/DeepLinkReport';
import client from '../../api';
import moment from 'moment';
import { UserContext } from '../../../context/UserContext';
import LoadingOverlay from '../../atoms/LoadingOverlay/LoadingOverlay';

export interface Props {
  homeSnapShot?: boolean;
}

const Container = styled('div', {
  paddingBottom: 0,
  paddingTop: 0,
  textAlign: 'left',
  variants: {
    homeSnapShot: {
      true: {
        maxWidth: '100%',
        paddingBottom: 0,
        paddingTop: 0,
        textAlign: 'left',
        position: 'relative',
      },
    },
  },
});

const Title = styled('h2', {
  color: '$black',
  fontFamily: '$heading',
  fontSize: '20px',
  fontWeight: '$bold',
  letterSpacing: '1px',
  lineHeight: 1,
  borderBottom: 'none',
  margin: 0,
  padding: '25px 0 13px',
  '@bp3': {
    fontSize: '25px',
    padding: '25px 0 13px',
  },
});

const DateWrap = styled('div', {
  marginBottom: '35px',
});

const Error = styled('div', {
  fontFamily: '$label',
  color: '#a94442',
  border: '1px solid #ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  padding: '15px 30px',
  fontSize: '$size16',
});

const SnapshotReport: React.FC<Props> = ({ homeSnapShot = false }) => {
  const { userCredentials } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState('');
  const [report, setReport] = useState([]);
  const [dateFilter, setDateFilter] = useState('7-days-past');
  const [startDate, setStartDate] = useState(
    moment().subtract(6, 'd').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
    callApi(dateFilter, startDate, endDate, 'day', true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const callApi = (
    dateFilter,
    startDate,
    endDate,
    resolution = 'day',
    homePage = true,
  ): void => {
    const fn = (a: string, b: string): string => a + ':' + b;
    const basicAuth = btoa(fn(username, password));
    void client
      .post(
        '/api/reports?_format=json',
        {
          type: 'homepage_report',
          date_filter: dateFilter,
          from_date: moment(startDate).format('YYYY-MM-DD'),
          to_date: moment(endDate).format('YYYY-MM-DD'),
          users: 'all',
          resolution,
          homepage: homePage,
        },
        {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            csrf_token,
            Authorization: 'Basic ' + basicAuth,
          },
          // auth: { username, password }
        },
      )
      .then(({ data }) => {
        if (data.totals !== undefined) {
          setReport(data.totals);
        } else {
          setErrorMessages('Error while getting data.');
        }

        setLoading(false);
      })
      .catch((e) => {
        setErrorMessages('Error while getting data.');
        console.log('snapshotreport error', e);
      });
  };

  const handleAction = (args: any): void => {
    setLoading(true);

    const newStartDateFormat = moment(args.startDate).format('YYYY-MM-DD');
    setStartDate(newStartDateFormat);

    const newEndDateFormat = moment(args.endDate).format('YYYY-MM-DD');
    setEndDate(newEndDateFormat);

    if (dateFilter !== args.range.label) {
      setDateFilter(args.range.label);
    }

    callApi(
      args.range.label,
      newStartDateFormat,
      newEndDateFormat,
      'day',
      true,
    );
  };

  return (
    <Container homeSnapShot={homeSnapShot}>
      {loading && <LoadingOverlay />}

      <Title>SNAPSHOT REPORT</Title>

      <DateWrap>
        <DateForm
          fullSelect
          type="home"
          callback={handleAction}
        ></DateForm>
      </DateWrap>

      {errorMessages !== '' ? <Error>{errorMessages}</Error> : ''}

      <DeepLinkReport report={report} />
    </Container>
  );
};

export default SnapshotReport;
