import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { styled } from './../../../theme/index';
import Heading from './../../atoms/Heading/Heading';
import CursiveLink from './../../atoms/CursiveLink/CursiveLink';
import Image from './../../atoms/Image/Image';
import Text from './../../atoms/Text/Text';
import moment from 'moment';

export interface Props {
  type: string;
  title: string;
  image: string;
  date?: string;
  nid?: number;
  content: string;
  link: string;
  announcements?: boolean;
  featured?: boolean;
  homePost?: boolean;
}

const Container = styled('div', {
  boxShadow: '0 1px 3px 0 rgba(77, 77, 77, 0.5)',
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
  variants: {
    featured: {
      true: {
        boxShadow: 'none',
        '@bp3': {
          boxShadow: '0 1px 3px 0 rgb(77, 77, 77, 0.5)',
          display: 'grid',
          gridTemplateColumns: '33.33% 1fr',
          columnGap: '36px',
        },
      },
    },
    homePost: {
      true: {
        maxWidth: '706px',
        '@bp3': {
          width: '100%',
          maxWidth: '458px',
        },
      },
    },
    announcements: {
      true: {
        width: '100%',
        maxWidth: '706px',
        '@bp3': {
          width: '100%',
          maxWidth: '474px',
        },
      },
    },
  },
});

const Top = styled('div', {
  fontSize: '18px',
  fontFamily: '$quote',
  lineHeight: 1.44,
  backgroundColor: '#F8F8F8',
  padding: '20px',
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
});

const Details = styled('div', {
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexGrow: 2,
  '@media(min-width: 989px)': {
    minHeight: '400px',
  },
  '@media(min-width: 1200px)': {
    minHeight: '370px',
  },
  '@media(min-width: 1460px)': {
    minHeight: '300px',
  },
  variants: {
    featured: {
      true: {
        display: 'block',
        minHeight: 'initial',
        padding: '26px 20px 24px 0',
      },
    },
    homePost: {
      true: {
        maxWidth: '706px',
        '@bp3': {
          width: '100%',
          maxWidth: '458px',
        },
      },
    },
    announcements: {
      true: {
        width: '100%',
        maxWidth: '706px',
        paddingTop: '8px',
        '@bp3': {
          width: '100%',
          maxWidth: '474px',
        },
      },
    },
  },
});

const Icon = styled('span', {
  fontSize: '14px',
  paddingLeft: '6px',
});

const Bottom = styled('div', {
  justifySelf: 'flex-end',
  fontSize: '20px',
  variants: {
    featured: {
      true: {
        marginTop: '8px',
        '@bp3': {
          marginTop: '18px',
        },
      },
    },
  },
});

const blogTitleStyles = {
  fontSize: '$size18',
  fontFamily: '$quote',
  letterSpacing: '0.2px',
  fontWeight: '$regular',
  marginBottom: '10px',
  textTransform: 'none',
};

const featuredTitleStyles = {
  fontSize: '19px',
  lineHeight: 1,
  fontFamily: '$quote',
  letterSpacings: '0.24px',
  fontWeight: '$regular',
  margin: '2px 0 13px',
  textTransform: 'none',
  '@bp3': {
    fontSize: '21px',
    lineHeight: 1.18,
  },
};

const textStyles = {
  margin: '5px 0 6px',
  fontSize: '15px',
  lineHeight: 1.46,
};

const dateStyles = {
  margin: '0 0 15px',
  color: '#555555',
  fontSize: '$size14',
  lineHeight: 1.357,
  fontFamily: '$sourceSans',
};

const FeatureTag = styled('div', {
  position: 'absolute',
  right: '-13px',
  top: '-15px',
  background: '$black',
  color: '$clear',
  fontFamily: '$quote',
  fontSize: '17px',
  lineHeight: 0.9,
  padding: '14px 22px',
  zIndex: 1,
  '@bp3': {
    right: '-21px',
  },
});

const BlogSnippet: React.FC<Props> = ({
  type,
  title,
  image,
  nid,
  date,
  content,
  link,
  announcements = false,
  featured = false,
  homePost = false,
}) => {
  let dateFormat = '';

  if (typeof date === 'string') {
    dateFormat = moment.unix(parseInt(date)).format('MMMM DD,YYYY');
  }

  let blogLink = '';
  if (type === 'featuredAnnouncements') {
    blogLink = link;
    if (!link.includes('http://') && !link.includes('https://')) {
      blogLink = '#';
    }
  } else {
    blogLink = `/blog-post/${nid}`;
  }

  return (
    <Container
      featured={featured}
      announcements={announcements}
      homePost={homePost}
    >
      {announcements && (
        <Top>
          <Heading
            css={{
              latterSpacing: '0.2px',
              textTransform: 'none',
              fontWeight: 'normal',
            }}
            as="h4"
          >
            <Link to={blogLink}>{title}</Link>
          </Heading>
        </Top>
      )}
      <div
        style={{
          position: 'relative',
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        {featured && <FeatureTag>Featured</FeatureTag>}

        <Link
          to={blogLink}
          style={{ display: 'block', width: '100%' }}
        >
          <Image src={image} />
        </Link>
      </div>
      <Details
        announcements={announcements}
        featured={featured}
      >
        <div>
          {!announcements && (
            <>
              <Heading
                css={featured ? featuredTitleStyles : blogTitleStyles}
                as="h2"
              >
                <Link to={blogLink}>{title}</Link>
              </Heading>
              {date !== null && <Text css={dateStyles}>{dateFormat}</Text>}
            </>
          )}
          <Text
            css={textStyles}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
        {!announcements && (
          <Bottom featured={featured}>
            <CursiveLink
              css={{
                fontSize: '24px',
                lineHeight: '14px',
              }}
              color="green"
              href={blogLink}
              underline
            >
              Read More
              <Icon>
                <FontAwesomeIcon icon={faArrowRight} />
              </Icon>
            </CursiveLink>
          </Bottom>
        )}
      </Details>
    </Container>
  );
};

export default BlogSnippet;
