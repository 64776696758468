// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';

const useOutsideClick = (
  reference: React.RefObject<any>,
  fn: () => void,
): void => {
  React.useEffect(() => {
    function handleClickOutside(event): void {
      const current = reference?.current;

      if (
        (current as boolean) &&
        !(current.contains(event.target) as boolean)
      ) {
        fn();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [reference, fn]);
};

export default useOutsideClick;
