import { useState } from 'react';
const UseCopy = (): [(str: string) => void, boolean, boolean] => {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);

  // const copy = (str: string): void => {
  //   setReady(false)
  //   setError(false)
  //
  //   navigator.clipboard
  //     .writeText(str)
  //     .then(() => {
  //       setReady(true)
  //     })
  //     .catch(() => {
  //       setError(true)
  //     })
  // }

  const copyAction = async (str: string): Promise<void> => {
    // copy(str)
    setReady(false);
    setError(false);

    if (navigator.userAgent.match(/Macintosh|ipad|iphone/i) !== null) {
      // safari browser
      const type = 'text/plain';
      const blob = new Blob([str], { type });
      const data = [new ClipboardItem({ [type]: blob })];
      await navigator.clipboard
        .write(data)
        // await navigator.clipboard.write([new ClipboardItem({ "text/plain": str })])
        .then(function () {
          console.log('copied');
          setReady(true);
        })
        .catch(function (error) {
          console.log(error);
          setError(true);
        });
    } else {
      // all other browsers
      try {
        await navigator.clipboard.writeText(str);
        setReady(true);
      } catch (error) {
        console.error('Unable to copy to clipboard:', error);
        setError(true);
      }
    }
  };

  return [copyAction, ready, error];
};

export default UseCopy;
