import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import Logo from './../../atoms/Logo/Logo';
import Link from './../../atoms/Link/Link';
import AnimatedToggle from './../../atoms/AnimatedToggle/AnimatedToggle';
import { opacity } from './config';
import TopBar from './../../molecules/TopBar/TopBar';
import SocialItems from './../../molecules/SocialItems/SocialItems';
import HeaderBar from './../../molecules/HeaderBar/HeaderBar';
import SideMenu from './../../molecules/SideMenu/SideMenu';
import { type MenuItem, siteUrl } from './../../../theme/menuLinks';
import logoImage from './../../../images/logo.png';

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  navCollection: MenuItem[];
  isScroll?: boolean;
}

const Container = styled('div', {
  textAlign: 'center',
  overflow: 'hidden',
  display: 'block',
  width: '100%',
  '.hidden': {
    display: 'none',
    visibility: 'hidden',
    userEvents: 'none',
  },
  '@bp3': {
    position: 'sticky',
    top: '-1px',
    left: 0,
    width: '100%',
    zIndex: 3,
  },
});

const SiteLogo = styled(Logo, {
  width: '85px',
  ...opacity,
});

const Desktop = styled('div', {
  background: '$clear',
  display: 'none',
  width: '100%',
  '@bp3': {
    display: 'block',
  },
});

const Mobile = styled('div', {
  display: 'block',
  minHeight: '100%',
  width: '100%',
  position: 'relative',
  '@bp3': {
    display: 'none',
  },
});

const MobileHeader = styled('div', {
  display: 'flex',
  minHeight: '80px',
  backgroundColor: '#f0f0f0',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  texAlign: 'center',
  width: '100%',
});

const MobileHeaderWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  maxWidth: '454px',
  margin: '0 15px',
  '@bp1': {
    maxWidth: '480px',
  },
  '@bp2': {
    maxWidth: '680px',
  },
});

const MobileWrapper = styled('div', {
  display: 'block',
  backgroundColor: '#f0f0f0',
  minHeight: '100vh',
  minWidth: '100%',
  width: '100%',
});

const LoginBox = styled('div', {
  display: 'flex',
  width: 'calc(100% - 70px)',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  borderBottom: '2px solid $gray3',
  texAlign: 'center',
  margin: '0 35px',
  padding: '15px 0',
  a: {
    letterSpacing: 0,
    padding: '12px 0',
    lineHeight: '20px',
    fontSize: '17px',
    textTransform: 'uppercase',
    ...opacity,
  },
});

const MobileMenu = styled('div', {
  display: 'block',
  textAlign: 'center',
  width: '100%',
  background: '$clear',
});

const Social = styled(SocialItems, {
  justifyContent: 'space-evenly',
  width: '100%',
  maxWidth: '200px',
  padding: '15px 0 0',
  margin: '0 auto',
});

const toggle = {
  position: 'absolute',
  top: '10px',
  left: 0,
};

const Stub = styled('div', {
  '@bp3': {
    height: '57px',
  },
});

const Header: React.FC<Props> = ({
  navCollection,
  isScroll = false,
  ...props
}) => {
  const [isActive, setIsActive] = React.useState(false);
  const handleToggle = (): void => {
    setIsActive(!isActive);
  };

  const mobileHeaderStyles = isActive
    ? { minHeight: '82px' }
    : { minHeight: '80px' };
  const mobileHeaderWrapperStyles = isActive
    ? { transform: 'translateY(-1px)' }
    : { transform: 'translateY(0)' };

  return (
    <>
      {isScroll && <Stub />}
      <Container {...props}>
        <Desktop>
          <TopBar isScroll={isScroll} />
          <HeaderBar
            navCollection={navCollection}
            isScroll={isScroll}
          />
        </Desktop>

        <Mobile>
          <MobileHeader css={mobileHeaderStyles}>
            <MobileHeaderWrapper css={mobileHeaderWrapperStyles}>
              <AnimatedToggle
                callback={handleToggle}
                isOpen={isActive}
                css={toggle}
              />
              <a href={siteUrl}>
                <SiteLogo
                  aspectRatio={0.61}
                  style={{ width: '85px' }}
                  src={logoImage}
                  css={{ transform: 'translateY(1px)' }}
                />
              </a>
            </MobileHeaderWrapper>
          </MobileHeader>

          <MobileWrapper className={!isActive ? 'hidden' : ''}>
            <MobileMenu>
              <SideMenu navCollection={navCollection} />
            </MobileMenu>

            <LoginBox>
              <Link
                type="third"
                href="/login"
              >
                log in
              </Link>

              <Link
                type="third"
                href="https://www.brandcycle.com/apply-now"
              >
                apply now
              </Link>
            </LoginBox>
            <Social
              type="navbar"
              fontAwesomeIcon={true}
            />
          </MobileWrapper>
        </Mobile>
      </Container>
    </>
  );
};

export default Header;
