// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import { styled } from './../../../theme/index';
import Text from './../../atoms/Text/Text';
// import LoadingOverlay from "../../atoms/LoadingOverlay/LoadingOverlay";
import DeepLinkForm from './../../organisms/DeepLinkForm/DeepLinkForm';

const Container = styled('div', {});

const Paragraph = styled(Text, {
  letterSpacing: '-0.2px',
  lineHeight: 1.562,
  marginBottom: '20px',
});

const BuildALinkContent: React.FC<Props> = ({ ...props }) => {
  // const [loading, setLoading] = useState(false);

  return (
    <Container {...props}>
      {/* {loading && <LoadingOverlay style={{position: "absolute", zIndex: "100"}} />} */}

      <Paragraph>
        Generate a custom deep link for any BrandCycle supported retailer
      </Paragraph>

      <DeepLinkForm
        isBuildALink={true}
        isHomePage={true}
      />
    </Container>
  );
};

export default BuildALinkContent;
