import React from 'react';
import { TagType } from 'src/types/baseComponentTypes';
import './baseTag.scss';

interface BaseTagProps {
  label: string;
}

// maps tag types to modifier classes
const labelToModifierClassMap: { [key in TagType]: string } = {
  [TagType.Trending]: 'baseTag--trending',
  [TagType.HotItem]: 'baseTag--hotItem',
  [TagType.LowestPrice]: 'baseTag--lowestPrice',
};

const BaseTag: React.FC<BaseTagProps> = ({ label }) => {
  // get the modifier class from the map or use default class
  const modifierClass =
    labelToModifierClassMap[label as TagType] || 'baseTag--generic';

  return <span className={`baseTag ${modifierClass}`}>{label}</span>;
};

export default BaseTag;
