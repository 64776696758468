// checks if date is in the current year
export const isCurrentYear = (date: Date): boolean => {
  const currentYear = new Date().getFullYear();
  return date.getFullYear() === currentYear;
};

// formats date as MM/DD/YY
export const formatDateShort = (date: Date): string => {
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const year = String(date.getUTCFullYear()).slice(-2);
  return `${month}/${day}/${year}`;
};

// formats date as MM/DD
export const formatDateVeryShort = (date: Date): string => {
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${month}/${day}`;
};

export const formatDateRange = (
  startDateStr: string | undefined,
  endDateStr: string,
): string => {
  const today = new Date();
  const startDate = startDateStr ? new Date(startDateStr) : undefined;
  const endDate = new Date(endDateStr);

  // check for valid end date
  if (isNaN(endDate.getTime())) {
    console.error('Invalid end date provided');
    return '';
  }

  // check for valid start date if it exists
  if (startDate && isNaN(startDate.getTime())) {
    console.error('Invalid start date provided');
    return '';
  }

  // if start date exists, check if end date is earlier than start date
  if (startDate && endDate < startDate) {
    console.error('End date is earlier than start date');
    return '';
  }

  // check if end date has already passed
  if (endDate < today) {
    return 'Expired';
  }

  // check if both dates are the same day
  const sameDay =
    startDate && startDate.toDateString() === endDate.toDateString();

  if (sameDay) {
    return isCurrentYear(endDate)
      ? `Valid ${formatDateVeryShort(endDate)}`
      : `Valid ${formatDateShort(endDate)}`;
  }

  // check if the date range has started
  const hasStarted = startDate ? startDate <= today : true;

  // only end date is provided
  if (!startDate) {
    return isCurrentYear(endDate)
      ? `Ends ${formatDateVeryShort(endDate)}`
      : `Ends ${formatDateShort(endDate)}`;
  }

  // if start date is the current year + end date is a future year
  if (isCurrentYear(startDate) && !isCurrentYear(endDate)) {
    return hasStarted
      ? `Ends ${formatDateShort(endDate)}`
      : `Valid ${formatDateShort(startDate)} – ${formatDateShort(endDate)}`;
  }

  // if both start + end dates are in future years
  if (startDate > today && endDate > today && !isCurrentYear(startDate)) {
    return `Valid ${formatDateShort(startDate)} – ${formatDateShort(endDate)}`;
  }

  // if start date is not the current year + hasnt started
  if (!isCurrentYear(startDate)) {
    return `Valid ${formatDateShort(startDate)} – ${formatDateShort(endDate)}`;
  }

  // if date range is in the current year + hasn't started
  if (isCurrentYear(startDate) && !hasStarted) {
    return `Valid ${formatDateVeryShort(startDate)} – ${formatDateVeryShort(
      endDate,
    )}`;
  }

  // if date range is in the current year + has started
  if (isCurrentYear(startDate) && hasStarted) {
    return `Ends ${formatDateVeryShort(endDate)}`;
  }

  return '';
};
