import React from 'react';
import { styled } from './../../../theme/index';
import { Link as SimpleLink } from 'react-router-dom';
import { type BrandCollectionItemProps } from './../../../types/brandTypes';

const Container = styled('div', {
  marginBottom: '6px',
  listStyle: 'none',
  paddingRight: '32px',
  '@bp5': {
    marginBottom: '5px',
  },
});

const SingleItem = styled('div', {
  color: '$black',
  fontFamily: '$body',
  fontSize: '15.5px',
  letterSpacing: '-0.2px',
  lineHeight: '1.29',
  textDecoration: 'none',
});

const BrandCollectionItem: React.FC<BrandCollectionItemProps> = ({ item }) => {
  return (
    <Container>
      <SimpleLink
        to={item.href}
        style={{ textDecoration: 'none' }}
      >
        <SingleItem>{item.name}</SingleItem>
      </SimpleLink>
    </Container>
  );
};

export default BrandCollectionItem;
