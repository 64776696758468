// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { type Dispatch, type SetStateAction, useState } from 'react';
import Select, { type StylesConfig } from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { styled, type ThemeProps } from './../../../theme/index';
import Dropdown from './../../atoms/Select/Dropdown';

export interface Option {
  value: string;
  label: string;
}

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  isSearch?: boolean;
  isMulti?: boolean;
  searchIcon?: boolean;
  options: Option[];
  placeholder?: string;
  maxMenuHeight?: number;
  minMenuHeight?: number;
  name?: string;
  value: Option | null;
  setValue: Dispatch<SetStateAction<Option | null>>;
  isForm?: boolean;
}

const Trigger = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid #9FA1A8',
  borderRadius: 1,
  padding: '7px 10px',
  width: '100%',
  cursor: 'pointer',
  fontFamily: '$body',
  fontSize: '$size15',
  svg: {
    fontSize: '13x',
    color: '#595f7B',
    marginRight: '4px',
  },
  variants: {
    isForm: {
      true: {
        border: '1px solid $lightgray1',
        borderRadius: '2px',
        padding: '5px 10px',
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: '$label',
      },
    },
  },
});

const CaretDown = styled('div', {
  display: 'inline-block',
  width: 0,
  height: 0,
  marginLeft: '2px',
  verticalAlign: 'middle',
  borderTop: '6px dashed',
  borderRight: '6px solid #0000',
  borderLeft: '6px solid #0000',
  color: '#595f7B',
});

const selectStyles = (isSearchable: boolean): StylesConfig => {
  return {
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
    }),
    container: (provided) => ({
      ...provided,
      margin: 0,
    }),
    control: (provided) => ({
      ...provided,
      minWidth: '100%',
      display: isSearchable ? 'flex' : 'none',
      padding: 0,
      minHeight: 'initial',
      borderRadius: 0,
      border: 'none',
      boxShadow: 'none',
    }),
    valueContainer: (provided) => ({
      ...provided,
      width: '100%',
      padding: '4px 8px',
    }),
    input: (provided) => ({
      ...provided,
      width: '100%',
      padding: '6px',
      border: '2px solid #000000',
      boxShadow: 'none',
      borderRadius: '6px',
    }),
    // Removes all the default styles for the menu
    menu: () => ({
      width: '100%',
    }),
    menuList: (provided) => ({
      ...provided,
      width: '100%',
    }),
  };
};

const SelectInput: React.FC<Props> = ({
  isForm,
  isMulti = false,
  isSearch = false,
  searchIcon = false,
  options = [],
  placeholder = 'Select one',
  maxMenuHeight = 150,
  minMenuHeight = 150,
  name,
  value = null,
  setValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const Icons = { DropdownIndicator: null, IndicatorSeparator: null };
  const label: string = value?.label ?? (value !== undefined ? value : '');
  // const label: string = value ?? ''
  // const label = value?.label ?? value ?? ''

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      target={
        <Trigger
          isForm={isForm}
          className="trigger"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {/* {label !== '' ? label : placeholder} */}
          {label !== '' ? value?.label : placeholder}
          {searchIcon ? (
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          ) : (
            <CaretDown />
          )}
        </Trigger>
      }
    >
      <Select
        name={name}
        menuIsOpen
        options={options}
        key={value}
        value={value}
        isSearchable={isSearch}
        placeholder=""
        styles={selectStyles(isSearch)}
        minMenuHeight={minMenuHeight}
        maxMenuHeight={maxMenuHeight}
        backspaceRemovesValue={false}
        components={Icons}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        isMulti={isMulti}
        tabSelectsValue={false}
        onChange={(newValue: Option) => {
          const output: Option = newValue;
          if (isMulti) {
            const concat = (arr, key): string => {
              let result = '';
              for (let i = 0; i < arr.length; i++) {
                result += `${arr[i][key]}, `;
              }
              return result;
            };

            output.label = concat(newValue, 'label');
            output.value = concat(newValue, 'value');
          }
          setValue(output);
          setIsOpen(false);
        }}
      />
    </Dropdown>
  );
};

export default SelectInput;
