import React from 'react';
import { styled } from './../../../theme/index';
import ShareBannerItem from './../../molecules/ShareBannerItem/ShareBannerItem';

export interface Data {
  id: number;
  title: string;
  image: string;
  code: string;
}

export interface Props {
  options: Data[];
  showLoader: any;
  hideLoader: any;
}

const Container = styled('div', {
  maxWidth: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr',
  '@bp1': {
    display: 'grid',
    gap: '25px 28px',
    gridTemplateColumns: '1fr',
    paddingTop: '4px',
  },
  '@bp3': {
    display: 'grid',
    gap: '25px 28px',
    gridTemplateColumns: '1fr 1fr 1fr',
    paddingTop: '13px',
  },
});

const BrandBannerGrid: React.FC<Props> = ({
  options,
  showLoader,
  hideLoader,
}) => {
  const handleShowLoader = (): void => {
    showLoader();
  };
  const handleHideLoader = (): void => {
    hideLoader();
  };
  return (
    <Container>
      {options.map((value, i) => (
        <ShareBannerItem
          key={i}
          bannerID={value.id}
          title={value.title}
          image={value.image}
          code={value.code}
          handleShowLoader={handleShowLoader}
          handleHideLoader={handleHideLoader}
        />
      ))}
    </Container>
  );
};

export default BrandBannerGrid;
