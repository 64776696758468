import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import { Button } from './../../atoms/Button/Button';
import Input from './../../atoms/Input/Input';
import Select, { type Option } from './../../atoms/Select/Select';

interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  options: Option[];
}

const Container = styled('div', {
  width: '80vw',
  flexDirection: 'column',
  '&, label': {
    display: 'inline-flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  label: {
    width: '100%',
    marginLeft: 0,
    marginBottom: '15px',
    '& > div': {
      width: '100%',
      marginLeft: 0,
      minWidth: '140px',
      '.trigger': {
        gridGap: '5px',
        paddingTop: '6px',
        paddingBottom: '6px',
        alignItems: 'center',
      },
    },
  },
  button: {
    width: '100%',
    height: '38px',
    marginLeft: 0,
  },
  '@bp2': {
    '&, label': {
      ':not(:first-of-type)': {
        justifyContent: 'space-between',
      },
      '&:first-of-type': {
        justifyContent: 'flex-end',
        input: {
          marginRight: 0,
          marginLeft: 'auto',
        },
      },
      width: '100%',
    },
    label: {
      '& > div': {
        width: '80%',
      },
      // All except the input text
      '&:not(:first-of-type)': {
        '& > div': {
          marginLeft: '10px',
        },
      },
    },
  },
  '@bp3': {
    width: '100%',
    flexDirection: 'row',
    '&, label': {
      justifyContent: 'flex-end',
      width: '100%',
    },
    label: {
      '& > div': {
        width: 'auto',
      },
      // All except the input text
      '&:not(:first-of-type)': {
        marginLeft: '25px',
      },
      marginBottom: 0,
      width: 'auto',
    },
    button: {
      marginLeft: '25px',
      width: '97px',
    },
  },
});

const SearchInput = styled('label', {
  height: '100%',
  paddingRight: '20px',
  input: {
    height: '38px',
    fontSize: '$size15',
    lineHeight: 1,
    width: '230px',
    border: '1px solid #9FA1A8 !important',
    '&:focus': {
      boxShadow:
        'inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6)',
      borderColor: '$blue',
      outline: 0,
    },
  },
  '@bp3': {
    paddingRight: 0,
  },
});

const SearchSelect = styled('label', {
  justifyContent: 'space-between',
  div: {
    width: 'none',
    maxWidth: '100%',
  },
  'div > div > div > div': {
    width: 'auto',
    maxWidth: '100%',
  },
  '@bp2': {
    div: {
      width: 'none',
      maxWidth: '100%',
    },
    'div > div > div > div': {
      width: 'auto',
      maxWidth: '100%',
    },
  },
  '@bp3': {
    div: {
      width: '200px',
      maxWidth: '100%',
    },
    'div > div > div > div': {
      width: 'auto',
      maxWidth: '100%',
    },
  },
});

const SortSelect = styled('label', {
  justifyContent: 'space-between',
});

const OrderSelect = styled('label', {
  justifyContent: 'space-between',
});

const styleLabel = {
  color: '$black',
  fontSize: '12px',
  paddingLeft: 0,
  fontWeight: '600',
  lineHeight: '26px',
  '@bp2': {
    paddingLeft: '10px',
  },
  '@bp3': {
    color: '$black',
    fontSize: '$size17',
    paddingLeft: 0,
    fontWeight: '600',
    lineHeight: '26px',
  },
};

const BrandsFilter: React.FC<Props> = ({
  options,
  handleFilters,
  ...props
}) => {
  const [selections, setSelections] = React.useState({
    searchString: '',
    search: options[0],
    sort: { label: 'Start date', value: 'start' },
    order: { label: 'Newest', value: 'new' },
  });
  const handler = (selection: string, value: any): void => {
    setSelections({ ...selections, ...{ [selection]: value } });
  };

  return (
    <Container {...props}>
      <SearchInput
        htmlFor="search-input"
        css={styleLabel}
      >
        <Input
          name="search-input"
          type="text"
          placeholder="Enter search term"
          value={selections.searchString}
          onChange={(e) => {
            handler('searchString', (e.target as HTMLFormElement).value);
          }}
          onKeyDown={(hKey) => {
            if (hKey.key === 'Enter') {
              handleFilters(selections);
            }
          }}
        />
      </SearchInput>
      <SearchSelect
        htmlFor="search-select"
        css={styleLabel}
      >
        Brands
        <Select
          name="search-select"
          className="select"
          searchIcon={true}
          options={options}
          value={selections.search}
          isSearch={true}
          placeholder="Select an option"
          setValue={(e) => {
            handler('search', e);
          }}
        />
      </SearchSelect>
      <SortSelect
        htmlFor="sort-select"
        css={styleLabel}
      >
        Sort by
        <Select
          className="select"
          name="sort-select"
          placeholder="Start Date"
          value={selections.sort}
          options={[
            { label: 'Start Date', value: 'start' },
            { label: 'End Date', value: 'end' },
          ]}
          setValue={(e) => {
            handler('sort', e);
          }}
        />
      </SortSelect>
      <OrderSelect
        htmlFor="order-select"
        css={styleLabel}
      >
        Order
        <Select
          className="select"
          name="order-select"
          placeholder="Newest"
          value={selections.order}
          options={[
            { label: 'Newest', value: 'new' },
            { label: 'Oldest', value: 'old' },
          ]}
          setValue={(e) => {
            handler('order', e);
          }}
        />
      </OrderSelect>

      <Button
        onClick={() => {
          handleFilters(selections);
        }}
      >
        Filter
      </Button>
    </Container>
  );
};

export default BrandsFilter;
