import React, { useState, useEffect, useRef } from 'react';
import {
  MultiSelectFilterDropdownProps,
  MultiSelectItem,
} from 'src/types/formTypes';
import './multiSelectFilterDropdown.scss';
import BaseIcon from 'src/components/atoms/BaseIcon/BaseIcon';
import { ReactComponent as IconChevron } from 'src/icons/iconChevron.svg';
import { ReactComponent as IconSearch } from 'src/icons/iconSearch.svg';

const MultiSelectFilterDropdown: React.FC<MultiSelectFilterDropdownProps> = ({
  items,
  selectedItems,
  onSelectionChange,
  label = 'item',
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState<boolean>(false);
  const [hasSomeSelected, setHasSomeSelected] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Update the state for select all checkbox and indeterminate state
  useEffect(() => {
    const allSelected =
      items.length > 0 && items.length === selectedItems.length;
    const noneSelected = selectedItems.length === 0;
    if (isSelectAllChecked !== allSelected) {
      setIsSelectAllChecked(allSelected);
    }
    if (hasSomeSelected !== (!allSelected && !noneSelected)) {
      setHasSomeSelected(!allSelected && !noneSelected);
    }
  }, [items, selectedItems, isSelectAllChecked, hasSomeSelected]);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleItemSelect = (item: MultiSelectItem) => {
    const isSelected = selectedItems.some(
      (selected) => selected.id === item.id,
    );
    if (isSelected) {
      onSelectionChange(
        selectedItems.filter((selected) => selected.id !== item.id),
      );
    } else {
      onSelectionChange([...selectedItems, item]);
    }
  };

  const handleSelectAll = () => {
    if (isSelectAllChecked) {
      onSelectionChange([]);
    } else {
      onSelectionChange(items);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.relatedTarget as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div
      className="multiSelectFilterDropdown"
      onBlur={handleBlur}
      ref={dropdownRef}
      tabIndex={-1}
    >
      <button
        className="multiSelectFilterDropdown__toggle"
        onClick={toggleDropdown}
        aria-expanded={isDropdownOpen}
        aria-haspopup="listbox"
      >
        {selectedItems.length > 0
          ? `— Selected ${label}s —`
          : `Select ${label}s`}
        <BaseIcon
          className="multiSelectFilterDropdown__toggleIcon"
          icon={IconChevron}
          size={12}
        />
      </button>
      {isDropdownOpen && (
        <div className="multiSelectFilterDropdown__menu">
          <div className="multiSelectFilterDropdown__menuSearch">
            <BaseIcon
              className="multiSelectFilterDropdown__menuSearchIcon"
              icon={IconSearch}
              size={16}
            />
            <input
              type="text"
              className="multiSelectFilterDropdown__menuSearchInput"
              placeholder={`Search ${label}s...`}
              value={searchTerm}
              onChange={handleSearch}
              aria-label={`Search for a ${label}`}
            />
          </div>
          <ul
            className="multiSelectFilterDropdown__menuList"
            role="listbox"
          >
            <li className="multiSelectFilterDropdown__menuListItem">
              <label className="multiSelectFilterDropdown__menuListItemLabel multiSelectFilterDropdown__menuListItemLabel--selectAll">
                <input
                  type="checkbox"
                  className="multiSelectFilterDropdown__menuListItemLabelCheckbox"
                  checked={isSelectAllChecked}
                  onChange={handleSelectAll}
                  aria-checked={isSelectAllChecked}
                  ref={(el) => {
                    if (el) {
                      el.indeterminate = hasSomeSelected;
                    }
                  }}
                />
                Select All
              </label>
              <hr className="multiSelectFilterDropdown__menuListItemLabelHr" />
            </li>
            {filteredItems.map((item) => (
              <li
                key={item.id}
                className="multiSelectFilterDropdown__menuListItem"
              >
                <label className="multiSelectFilterDropdown__menuListItemLabel">
                  <input
                    type="checkbox"
                    className="multiSelectFilterDropdown__menuListItemLabelCheckbox"
                    checked={selectedItems.some(
                      (selected) => selected.id === item.id,
                    )}
                    onChange={() => handleItemSelect(item)}
                    aria-checked={selectedItems.some(
                      (selected) => selected.id === item.id,
                    )}
                  />
                  {item.name}
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MultiSelectFilterDropdown;
