// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {
  useState,
  useMemo,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { range, findIndex, orderBy } from 'lodash';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import { styled } from './../../../theme/index';
import DragDropItem, { type Item } from './DragDropItem';
import { ButtonElement } from '../../atoms/Button/Button';

export interface Props {
  title: string;
  data?: Item[];
  onChange: Dispatch<SetStateAction<Item[]>>;
}

const Top = styled('div', {
  color: '$black',
  textAlign: 'right',
});

const Toggle = styled('span', {
  cursor: 'pointer',
  fontSize: '$size16',
  lineHeight: 1.523,
  '&:hover': {
    textDecoration: 'underline',
  },
});

const Heading = styled('p', {
  fontSize: '$size16',
  lineHeight: 1.523,
  fontWeight: '$bold',
  fontFamily: '$label',
  color: '$darkgray',
  padding: '8px 0',
  margin: 0,
  borderBottom: '1px solid $gray4',
});

const Button = styled(ButtonElement, {
  background: '#5bc0de',
  borderColor: '#5bc0de',
  color: '$clear',
  fontSize: '$size16',
  lineHeight: 1.428,
  width: '166px',
  marginTop: '23px',
  padding: '6px 22px',
  textTransform: 'none',
  fontFamily: '$label',
  '&:hover': {
    backgroundColor: '#31b0d5',
    borderColor: '#31b0d5',
  },
  variants: {
    disabled: {
      true: {
        cursor: 'default',
        opacity: 0.7,
        '&:hover': {
          backgroundColor: '#5bc0de',
          borderColor: '#5bc0de',
        },
      },
    },
  },
});

const Notice = styled('div', {
  fontFamily: '$label',
  fontSize: '$size16',
  lineHeight: '22.85px',
  color: '$darkgray',
});

const DragDropList: React.FC<Props> = ({ data = [], title, onChange }) => {
  const [editWeights, setEditWeights] = useState(false);
  const [changed, setChanged] = useState(false);
  const [itemsTemp, setItemsTemp] = useState<Item[]>([]);
  const itemsLength = data?.length ?? 1;

  const lengthOptions = useMemo(() => {
    return [...range(-itemsLength, 0), ...range(itemsLength + 1)];
  }, [itemsLength]);

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event): void => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const items = [...data];

      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      let newItemsArray = arrayMove(items, oldIndex, newIndex);

      newItemsArray = newItemsArray.map((item, index) => {
        item.order = index;
        return item;
      });

      setChanged(true);
      onChange(newItemsArray);
    }
  };

  const onWeightChange = (id, value): void => {
    const items = [...itemsTemp];
    const index = findIndex(items, (item) => item.id === id);
    items[index].order = value;
    setChanged(true);
    setItemsTemp(items);
  };
  const toggleWeights = (): void => {
    // Update all items with new order, when the user is done toggling weights
    if (editWeights) {
      let items = [...itemsTemp];
      items = orderBy(items, ['order'], ['asc']);
      items = items.map((item, index) => {
        item.order = index;
        return item;
      });

      onChange(items);
      setItemsTemp([]);
    }

    setItemsTemp([...data]);
    setEditWeights(!editWeights);
  };

  const addItem = (): void => {
    const items = [...data];
    const newItem = { ...items[0] };
    newItem.id = itemsLength + 1;
    newItem.order = itemsLength + 1;
    newItem.value = '';
    items.push(newItem);

    onChange(items);
  };

  const onChangeText = (id, value): void => {
    const items = [...data];
    const index = findIndex(items, (item) => item.id === id);
    items[index].value = value;

    onChange(items);
  };

  return (
    <>
      <Top>
        <Toggle
          onClick={toggleWeights}
          title="Re-order rows by numerical weight instead of dragging."
          tabIndex={0}
        >
          {editWeights ? 'Hide' : 'Show'} row weights
        </Toggle>
      </Top>
      {changed && (
        <Notice>
          * Changes made in this table will not be saved until the form is
          submitted.
        </Notice>
      )}
      <Heading>{title}</Heading>
      {data?.length > 0 && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={data}
            strategy={verticalListSortingStrategy}
          >
            {data.map((item, index) => (
              <DragDropItem
                key={item.id !== undefined ? item.id : index}
                item={item}
                lengthOptions={lengthOptions}
                editWeight={editWeights}
                onWeightChange={onWeightChange}
                onChangeText={onChangeText}
              />
            ))}
          </SortableContext>
        </DndContext>
      )}
      <Button
        as="span"
        onClick={addItem}
        disabled={editWeights}
      >
        Add another item
      </Button>
    </>
  );
};

export default DragDropList;
