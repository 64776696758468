import axios, { AxiosInstance } from 'axios';
import camelcaseKeys from 'camelcase-keys';

const ENV = process.env.REACT_APP_ENV;
let baseURL = '';
if (
  ENV === 'production' &&
  typeof process.env.REACT_APP_PROD_DOMAIN !== 'undefined'
) {
  baseURL = process.env.REACT_APP_PROD_DOMAIN;
} else if (
  ENV === 'stage' &&
  typeof process.env.REACT_APP_STAGE_DOMAIN !== 'undefined'
) {
  baseURL = process.env.REACT_APP_STAGE_DOMAIN;
} else if (
  ENV === 'local' &&
  typeof process.env.REACT_APP_LOCAL_DOMAIN !== 'undefined'
) {
  baseURL = process.env.REACT_APP_LOCAL_DOMAIN;
}

const client = axios.create({
  withCredentials: true,
  baseURL,
});

export function addCamelCaseRespInterceptor(
  axiosClient: AxiosInstance,
): number {
  return axiosClient.interceptors.response.use(
    (response) => {
      if (response.data && typeof response.data === 'object') {
        response.data = camelcaseKeys(response.data);
      }
      return response;
    },
    (error) => {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === 'object'
      ) {
        error.response.data = camelcaseKeys(error.response.data);
      }
      return Promise.reject(error);
    },
  );
}

export function ejectCamelCaseRespInterceptor(
  axiosClient: AxiosInstance,
  interceptorId: number,
): void {
  axiosClient.interceptors.response.eject(interceptorId);
}

export default client;
