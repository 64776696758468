// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import { AccountDetailsForm } from './../../molecules/AccountDetailsForm/AccountDetailsForm';
import { type Option as SelectProps } from './../../atoms/Select/Select';
import TermsAndConditionsOfUse from './../../molecules/TermsandConditionsofUse/TermsAndConditionsOfUse';
import Heading from './../../atoms/Heading/Heading';
// import { useParams } from "react-router-dom";

// interface Data {
//   firstName?: string
//   lastName?: string
//   userName?: string
//   email?: string
//   password?: string
//   confirmation?: string
//   role?: SelectProps
// }

export interface Props extends ThemeProps {
  optionsSelect?: SelectProps[];
  data?: Data;
}

const Container = styled('div', {
  margin: 0,
});

const Form = styled('div', {
  paddingLeft: 0,
  paddingBottom: '40px',
  '@bp3': {
    paddingLeft: '30px',
  },
});

const Title = styled(Heading, {
  color: '$black',
  textTransform: 'capitalize',
  marginBottom: '40px',
});

const SubUsersDetails: React.FC<Props> = () => {
  // const { uid } = useParams();

  return (
    <Container>
      <Title
        type="pageTitle"
        css={{ fontSize: '36px' }}
      >
        Account Details
      </Title>

      <Form>
        <AccountDetailsForm />
      </Form>

      <TermsAndConditionsOfUse readOnly={true} />
    </Container>
  );
};

export default SubUsersDetails;
