// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  BlogIcon,
  CompanyIcon,
  FaqsIcon,
  HomeIcon,
  PlatformIcon,
  ServicesIcon,
  TestimonialsIcon,
} from './../../src/icons/index';
import { type CSS } from '@stitches/react';
import {
  faCircleQuestion,
  faHeart,
  faStar,
} from '@fortawesome/free-regular-svg-icons';
import {
  faAlignLeft,
  faChartColumn,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';

export const siteUrl = '/';

interface Props {
  label: string;
  src: string;
  icon?: any;
  show?: string;
  css?: CSS;
}

export interface MenuItem extends Props {
  items?: Props[];
}

export const menuLinks = [
  {
    label: 'Home',
    src: siteUrl,
    icon: HomeIcon,
    show: 'side',
  },
  {
    label: 'Company',
    src: 'https://www.brandcycle.com/company/',
    icon: CompanyIcon,
    show: 'all',
  },
  {
    label: 'Platform',
    src: 'https://www.brandcycle.com/platform/',
    icon: PlatformIcon,
    show: 'all',
  },
  {
    label: 'Services',
    src: 'https://www.brandcycle.com/services/',
    icon: ServicesIcon,
    show: 'all',
  },
  {
    label: 'Testimonials',
    src: 'https://www.brandcycle.com/testimonials/',
    icon: TestimonialsIcon,
    show: 'all',
  },
  {
    label: 'FAQ',
    src: 'https://www.brandcycle.com/faq/',
    icon: FaqsIcon,
    show: 'all',
  },
  {
    label: 'Blog',
    src: 'https://www.brandcycle.com/blog/',
    icon: BlogIcon,
    show: 'all',
  },
  {
    label: 'Contact',
    src: 'https://www.brandcycle.com/contact/',
    show: 'footer',
  },
  {
    label: 'Privacy',
    src: 'https://www.brandcycle.com/privacy-policy/',
    show: 'footer',
  },
] as MenuItem[];

export const footerMenuLinks = [
  {
    label: 'Company',
    src: 'https://www.brandcycle.com/company/',
    icon: CompanyIcon,
    show: 'footer',
  },
  {
    label: 'Platform',
    src: 'https://www.brandcycle.com/platform/',
    icon: PlatformIcon,
    show: 'footer',
  },
  {
    label: 'Services',
    src: 'https://www.brandcycle.com/services/',
    icon: ServicesIcon,
    show: 'footer',
  },
  {
    label: 'Testimonials',
    src: 'https://www.brandcycle.com/testimonials/',
    icon: TestimonialsIcon,
    show: 'footer',
  },
  {
    label: 'FAQ',
    src: 'https://www.brandcycle.com/faq/',
    icon: FaqsIcon,
    show: 'footer',
  },
  {
    label: 'Blog',
    src: 'https://www.brandcycle.com/blog/',
    icon: BlogIcon,
    show: 'footer',
  },
  {
    label: 'Contact',
    src: 'https://www.brandcycle.com/contact/',
    show: 'footer',
  },
  {
    label: 'Privacy',
    src: 'https://www.brandcycle.com/privacy-policy/',
    show: 'footer',
  },
] as MenuItem[];

export const sideDashboardResponsiveLinks = [
  {
    label: 'Notifications',
    src: '#',
    show: 'side',
    level: 1,
  },
  {
    label: 'Refer a Friend',
    src: '#',
    show: 'side',
    level: 2,
  },
  {
    label: 'My Account',
    src: '#',
    show: 'side',
    level: 1,
  },
  {
    label: 'Logout',
    src: '#',
    show: 'side',
    level: 1,
  },
] as unknown as MenuItem[];

export const sideDashboardLinks = [
  {
    label: 'Notifications',
    src: siteUrl,
    show: 'side',
  },
  {
    label: 'Brands',
    src: '#',
    icon: faHeart,
    show: 'all',
  },
  {
    label: 'Promotions',
    src: '#',
    icon: faStar,
    show: 'all',
  },
  {
    label: 'Reports',
    icon: faChartColumn,
    show: 'all',
    items: [
      {
        label: 'Summary Report',
        src: '#',
        show: 'all',
      },
      {
        label: 'Conversion Report',
        src: '#',
        show: 'all',
      },
      {
        label: 'Brands Report',
        src: '#',
        show: 'all',
      },
      {
        label: 'Traffic Referrals Report',
        src: '#',
        show: 'all',
      },
      {
        label: 'Payments Report',
        src: '#',
        show: 'all',
      },
    ],
  },
  {
    label: 'Tools',
    icon: faWrench,
    show: 'all',
    items: [
      {
        label: 'BookMarklet Tool',
        src: '#',
        show: 'all',
      },
      {
        label: 'Build A Link',
        src: '#',
        show: 'all',
      },
      {
        label: 'Mobile App',
        src: '#',
        show: 'all',
      },
    ],
  },
  {
    label: 'Blog',
    src: '#',
    icon: faAlignLeft,
    show: 'all',
  },
  {
    label: 'Support',
    src: '#',
    icon: faCircleQuestion,
    show: 'all',
  },
] as unknown as MenuItem[];
