// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useMemo } from 'react';
import { styled } from './../../../theme/index';
import Table, { type Props } from './Table';

const cols = (n): string => `calc(100%/${n})`;

const headers = {
  summary: [
    {
      Header: ' ',
      columns: [
        {
          Header: 'Date',
          accessor: 'date1',
          style: {
            width: cols(7),
          },
        },
        {
          Header: 'Clicks',
          accessor: 'number1',
          style: {
            width: cols(7),
          },
        },
        {
          Header: 'Orders',
          accessor: 'number2',
          style: {
            width: cols(7),
          },
        },
        {
          Header: 'Sales($)',
          accessor: 'float1',
          style: {
            width: cols(7),
          },
        },
        {
          Header: 'Payout($)',
          accessor: 'float2',
          style: {
            width: cols(7),
          },
        },
      ],
    },
    {
      Header: 'Locked',
      columns: [
        {
          Header: 'Sales($)',
          accessor: 'float3',
          style: {
            width: cols(7),
          },
        },
        {
          Header: 'Payout($)',
          accessor: 'float4',
          style: {
            width: cols(7),
          },
        },
      ],
    },
  ],
  conversion: [
    {
      Header: 'Transaction Information',
      columns: [
        {
          Header: 'Date',
          accessor: 'date1',
          style: {
            width: cols(10),
          },
        },
        {
          Header: 'Offer',
          accessor: 'string1',
          sortType: 'caseInsensitive',
          style: {
            width: cols(10),
          },
        },
        {
          Header: 'Status',
          accessor: 'string2',
          style: {
            width: cols(10),
          },
        },
        {
          Header: 'Sales($)',
          accessor: 'float1',
          style: {
            width: cols(10),
          },
        },
        {
          Header: 'Payout($)',
          accessor: 'float2',
          style: {
            width: cols(10),
          },
        },
        {
          Header: 'Order ID',
          accessor: 'number1',
          style: {
            width: cols(10),
          },
        },
      ],
    },
    {
      Header: 'Locked Status',
      columns: [
        {
          Header: 'Sales($)',
          accessor: 'float3',
          style: {
            width: cols(10),
          },
        },
        {
          Header: 'Payout($)',
          accessor: 'float4',
          style: {
            width: cols(10),
          },
        },
        {
          Header: 'Paid Status',
          accessor: 'string3',
          style: {
            width: cols(10),
          },
        },
        {
          Header: 'Paid Date',
          accessor: 'string4',
          style: {
            width: cols(10),
          },
        },
      ],
    },
  ],
  paymentBalance: [
    {
      Header: ' ',
      columns: [
        {
          Header: 'Affiliate Name',
          accessor: 'name',
          sortType: 'caseInsensitive',
          style: {
            width: cols(10),
          },
        },
      ],
    },
    {
      Header: ' ',
      columns: [
        {
          Header: 'Affiliate ID',
          accessor: 'id',
          style: {
            width: cols(12),
          },
        },
      ],
    },
    {
      Header: 'Pending',
      columns: [
        {
          Header: 'Sales',
          accessor: 'pending_sales',
        },
        {
          Header: 'Payout',
          accessor: 'pending_payout',
        },
      ],
    },
    {
      Header: 'Approved',
      columns: [
        {
          Header: 'Sales',
          accessor: 'approved_sales',
        },
        {
          Header: 'Payout',
          accessor: 'approved_payout',
        },
      ],
    },
    {
      Header: 'Paid',
      columns: [
        {
          Header: 'Sales',
          accessor: 'paid_sales',
        },
        {
          Header: 'Payout',
          accessor: 'paid_payout',
        },
      ],
    },
    {
      Header: 'Balance',
      columns: [
        {
          Header: 'Sales',
          accessor: 'balance_sales',
        },
        {
          Header: 'Payout',
          accessor: 'balance_payout',
        },
      ],
    },
  ],
  referral: [
    {
      Header: 'Fist Name',
      accessor: 'fistName',
      sortType: 'caseInsensitive',
      style: {
        width: cols(10),
      },
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      sortType: 'caseInsensitive',
      style: {
        width: cols(10),
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      sortType: 'caseInsensitive',
      style: {
        width: cols(10),
      },
    },
    {
      Header: 'Username',
      accessor: 'username',
      sortType: 'caseInsensitive',
      style: {
        width: cols(10),
      },
    },
    {
      Header: 'Affiliate ID',
      accessor: 'id',
      style: {
        width: cols(10),
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      sortType: 'caseInsensitive',
      style: {
        width: cols(10),
      },
    },
    {
      Header: 'Publisher Code',
      accessor: 'code',
      style: {
        width: cols(10),
      },
    },
    {
      Header: 'Date Joined',
      accessor: 'dateJoined',
      style: {
        width: cols(10),
      },
    },
    {
      Header: 'Date of Purchase',
      accessor: 'datePurchase',
      style: {
        width: cols(10),
      },
    },
    {
      Header: 'Referral ID',
      accessor: 'referralId',
      style: {
        width: cols(10),
      },
    },
    {
      Header: 'Referral Username',
      accessor: 'referralUsername',
      sortType: 'caseInsensitive',
      style: {
        width: cols(10),
      },
    },
    {
      Header: 'Referral Affiliate ID',
      accessor: 'referralAffiliateId',
      style: {
        width: cols(10),
      },
    },
    {
      Header: 'Referral Email',
      accessor: 'referralEmail',
      sortType: 'caseInsensitive',
      style: {
        width: cols(10),
      },
    },
  ],
  brands: [
    {
      Header: ' ',
      columns: [
        {
          Header: 'Date',
          accessor: 'date1',
          style: {
            width: cols(8),
          },
        },
        {
          Header: 'Offer',
          accessor: 'string1',
          sortType: 'caseInsensitive',
          style: {
            width: cols(8),
          },
        },
        {
          Header: 'Clicks',
          accessor: 'number1',
          style: {
            width: cols(8),
          },
        },
        {
          Header: 'Sales($)',
          accessor: 'float1',
          style: {
            width: cols(8),
          },
        },
        {
          Header: 'Payout($)',
          accessor: 'float2',
          style: {
            width: cols(8),
          },
        },
      ],
    },
    {
      Header: 'Locked Status',
      columns: [
        {
          Header: 'Sales($)',
          accessor: 'float3',
          style: {
            width: cols(8),
          },
        },
        {
          Header: 'Payout($)',
          accessor: 'float4',
          style: {
            width: cols(8),
          },
        },
      ],
    },
  ],
  traffic: [
    {
      Header: 'Date',
      accessor: 'date1',
      style: {
        width: cols(8),
      },
    },
    {
      Header: 'Affiliate',
      accessor: 'string1',
      sortType: 'caseInsensitive',
      style: {
        width: cols(8),
      },
    },
    {
      Header: 'Advertiser',
      accessor: 'string2',
      sortType: 'caseInsensitive',
      style: {
        width: cols(8),
      },
    },
    {
      Header: 'Url',
      accessor: 'string3',
      sortType: 'caseInsensitive',
      style: {
        width: cols(8),
      },
    },
    {
      Header: 'Clicks',
      accessor: 'number1',
      style: {
        width: cols(8),
      },
    },
    {
      Header: 'Conversions',
      accessor: 'number2',
      style: {
        width: cols(8),
      },
    },
    {
      Header: 'Sales($)',
      accessor: 'float1',
      style: {
        width: cols(8),
      },
    },
    {
      Header: 'Payout($)',
      accessor: 'float2',
      style: {
        width: cols(8),
      },
    },
  ],
  payment: [
    {
      Header: 'Brand',
      accessor: 'brand',
      sortType: 'caseInsensitive',
      style: {
        width: cols(4),
      },
    },
    {
      Header: 'Number of Orders (sales, leads, bonuses)',
      accessor: 'orders',
      style: {
        width: cols(4),
      },
    },
    {
      Header: 'Sales',
      accessor: 'sales',
      style: {
        width: cols(4),
      },
    },
    {
      Header: 'Payout',
      accessor: 'payout',
      style: {
        width: cols(4),
      },
    },
  ],
};

const Container = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '10px',
});

export const SECONDARY_BACKGROUND = '#E6E0EC';

// Load Special styles
export const addProp = (column, prop): any => {
  column.style = prop;
  column.columns.forEach((subColumn) => {
    subColumn.style = { ...subColumn.style, ...prop };
  });
};

const DataTables: React.FC<Props> = ({
  data,
  columns,
  search,
  variant,
  showPagination = true,
  ...props
}) => {
  // Load variant Headers
  if (variant !== undefined && headers[variant] !== undefined) {
    columns = columns ?? headers[variant];
  }

  if (variant === 'conversion') {
    if (columns?.length === 2) {
      addProp(columns[1], { background: SECONDARY_BACKGROUND });
    }
  }

  data = useMemo(() => data, [data]) ?? [];
  columns = useMemo(() => columns, [columns]) ?? [];

  const tableProps = { data, columns, search, variant };

  return (
    <Container {...props}>
      <Table
        {...tableProps}
        showPagination={showPagination}
      />
    </Container>
  );
};

export default DataTables;
