import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';

import { animation } from './../../molecules/Header/config';

import SocialItems from './../../molecules/SocialItems/SocialItems';
import CursiveLink from './../../atoms/CursiveLink/CursiveLink';

interface Element {
  label: string;
  src: string;
}

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  navCollection?: Element[];
  isScroll?: boolean;
}

const Container = styled('div', {
  width: '100%',
  textAlign: 'center',
  background: '#f0f0f0',
  display: 'none',
  '@bp3': {
    display: 'block',
  },
});

const Wrapper = styled('div', {
  margin: '0 auto',
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  ...animation,
  transitionProperties: 'padding',
  '@bp3': {
    maxWidth: '900px',
  },
  '@bp5': {
    maxWidth: '1080px',
  },
  variants: {
    isScroll: {
      true: {
        padding: '1px 0 0',
      },
      false: {
        padding: '12px 0 10px',
      },
    },
  },
});

const Animation = styled('div', {
  ...animation,
  transitionProperties: 'transform',
  variants: {
    isScroll: {
      true: {
        transform: 'translateY(3px)',
      },
    },
  },
});

const Login = styled('div', {
  ...animation,
  transitionProperties: 'transform, font',
  display: 'inline-flex',
  justifyContent: 'center',
  width: '273px',
  paddingLeft: '15px',
  variants: {
    isScroll: {
      true: {
        transform: 'translateY(1px) translateX(22px)',
      },
    },
  },
});

const CursiveStyles = styled(CursiveLink, {
  lineHeight: '1',
  ...animation,
  transitionProperties: 'transform, font',
  '&:first-of-type': {
    marginRight: '45px',
  },
  variants: {
    isScroll: {
      true: {
        ...animation,
        transitionProperties: 'transform, font',
        fontSize: '18px',
        transform: 'translateY(0px)',
      },
      false: {
        transform: 'translateY(-5px)',
      },
    },
  },
});

const Heading: React.FC<Props> = ({ isScroll = false, ...props }) => {
  return (
    <Container {...props}>
      <Wrapper isScroll={isScroll}>
        <Animation isScroll={isScroll}>
          <SocialItems
            type="navbar"
            fontAwesomeIcon={true}
            isScroll={isScroll}
          />
        </Animation>

        <Login isScroll={isScroll}>
          <CursiveStyles
            href="/login"
            isScroll={isScroll}
          >
            log in
          </CursiveStyles>
          <CursiveStyles
            href="https://www.brandcycle.com/apply-now"
            isScroll={isScroll}
          >
            apply now
          </CursiveStyles>
        </Login>
      </Wrapper>
    </Container>
  );
};

export default Heading;
