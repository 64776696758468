// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useMemo } from 'react';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
} from 'react-table';
import { matchSorter } from 'match-sorter';
import { styled, type ThemeProps } from './../../../theme/index';

import useTableTotals, {
  type VariantProps,
} from '../../../hook/useTableTotals/useTableTotals';
import Image from './../../atoms/Image/Image';
import TotalsBar from './../../atoms/DataTables/TotalsBar';
import GlobalFilter from './GlobalFilter';
import Pagination from './Pagination';

import sort_asc from './../../../images/sort_asc.png';
import sort_desc from './../../../images/sort_desc.png';
import sort_both from './../../../images/sort_both.png';

export interface Props extends ThemeProps, VariantProps {
  columns?: object[];
  data: object[];
  search?: boolean;
  showPagination?: boolean;
}

const caseInsensitiveSort = (rowA, rowB, columnId): number => {
  const a = rowA.values[columnId].toString().toLowerCase();
  const b = rowB.values[columnId].toString().toLowerCase();
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

const fuzzyText = (rows, id, filterValue): any[] =>
  matchSorter(rows, filterValue, {
    keys: [(row: { values }) => row?.values[id]],
  });

fuzzyText.autoRemove = (val: boolean) => !val;

const border = {
  border: '1px solid $gray4',
  borderBottomWidth: '0',
};

const cell = {
  ...border,
  textAlign: 'left',
  verticalAlign: 'top',
  fontSize: '$size15',
  lineHeight: '1.5',
  color: '$black',
  overflowWrap: 'break-word',
};

const TableWrapper = styled('div', {
  width: '100%',
  overflow: 'scroll',
});

const HtmlTable = styled('table', {
  width: '100%',
  borderSpacing: '0',
  margin: '0 auto',
  variants: {
    type: {},
  },
});

const Thead = styled('thead', {
  '& > tr > td': {
    borderBottomWidth: '2px',
  },
});

const Tbody = styled('thead', {
  '& tr:nth-of-type(odd)': {
    backgroundColor: '$clear4',
  },
});

const Th = styled('th', {
  ...border,
  ...cell,
  fontSize: '14px',
  margin: '0',
  padding: '3px 19px 5px 9px',
  position: 'relative',
  cursor: 'pointer',
  wordBrake: 'break-all',
  backgroundColor: '#f6f6f6',

  variants: {
    type: {
      conversion: {
        backgroundColor: '$clear',
        borderColor: '$black',
        borderWidth: '2px 1px 0',
      },
    },
  },
  '@bp3': {
    fontSize: '15px',
  },
});

const Td = styled('td', {
  ...border,
  fontFamily: '$body',
  margin: '0',
  padding: '0.5rem',
  variants: {
    type: {
      summary: {
        padding: '3px 8px 4px',
      },
      brands: {
        padding: '3px 8px 4px',
      },
      conversion: {
        borderColor: '$black',
        borderWidth: '2px 1px 0',
      },
    },
  },
});

const Tr = styled('tr', {
  ...cell,
  fontFamily: '$heading',
  fontSize: '14px',
  variants: {
    type: {
      payment: {
        fontFamily: '$body',
      },
    },
  },
  '@bp3': {
    fontSize: '15px',
  },
});

const IconBox = styled('span', {
  position: 'absolute',
  right: '0',
  top: 'calc(50% - 9.5px)',
});

const iconStyles = {
  display: 'block',
  width: '19px',
  height: '19px',
};

const Table: React.FC<Props> = ({
  columns,
  data,
  search,
  variant = 'default',
  showPagination = true,
  ...props
}) => {
  const sortTypes = {
    caseInsensitive: caseInsensitiveSort,
  };

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText,
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        }),
    }),
    [],
  );

  const defaultColumn = useMemo(() => ({ Filter: GlobalFilter }), []);

  const {
    page,
    state: { pageIndex, pageSize, globalFilter },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    prepareRow,
    headerGroups,
    getTableProps,
    setGlobalFilter,
    getTableBodyProps,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn,
      filterTypes,
      sortTypes,
    },
    useResizeColumns,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const paginationProps = {
    canPreviousPage,
    previousPage,
    nextPage,
    canNextPage,
    pageIndex,
    pageSize,
    setPageSize,
  };

  let dataForTotals = data;

  if (data.length > pageSize) {
    const newTotalData = [];
    for (let x = 0; x < pageSize; x++) {
      if (page[x]?.original !== undefined) {
        newTotalData.push(page[x].original);
      }
    }

    dataForTotals = newTotalData;
  }

  const totals = useTableTotals({
    columns,
    data: dataForTotals,
    variant: variant as Props['variant'],
  });

  const isFloatNumber = (str): boolean => {
    return /^d*.?d+$/.test(str);
  };

  return (
    <>
      {(search as boolean) && (
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      <TableWrapper {...props}>
        <HtmlTable
          type={variant}
          {...getTableProps()}
        >
          <Thead>
            {(headerGroups as boolean) &&
              headerGroups.map((headerGroup, a) => {
                return (
                  <Tr
                    type={variant}
                    {...headerGroup.getHeaderGroupProps()}
                    key={`header-tr-${a}`}
                  >
                    {(headerGroup?.headers as boolean) &&
                      headerGroup.headers.map((column, b) => (
                        <Th
                          type={variant}
                          {...column.getHeaderProps([
                            column.getSortByToggleProps(),
                            {
                              className: column.className,
                              style: column.style,
                            },
                          ])}
                          key={`header-th-${b}`}
                        >
                          <span>{column.render('Header')}</span>
                          <IconBox>
                            {(column.isSorted as boolean) ? (
                              (column.isSortedDesc as boolean) ? (
                                <Image
                                  src={sort_desc}
                                  css={iconStyles}
                                />
                              ) : (
                                <Image
                                  src={sort_asc}
                                  css={iconStyles}
                                />
                              )
                            ) : (
                              <Image
                                src={sort_both}
                                css={iconStyles}
                              />
                            )}
                          </IconBox>
                        </Th>
                      ))}
                  </Tr>
                );
              })}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {(page as boolean) &&
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    key={`row-tr-${i}`}
                  >
                    {(row?.cells as boolean) &&
                      row.cells.map((cell, j) => {
                        let cellValue = null;
                        if (
                          typeof cell.value === 'number' ||
                          isFloatNumber(cell.value)
                        ) {
                          cellValue = Number(
                            parseFloat(cell.value).toFixed(2),
                          ).toLocaleString();
                        } else if (typeof cell.value === 'string') {
                          if (
                            variant === 'payment' &&
                            j >= 2 &&
                            cell.value.includes('.') !== false
                          ) {
                            cellValue = Number(
                              parseFloat(cell.value).toFixed(2),
                            ).toLocaleString();
                          } else {
                            cellValue = cell.value;
                          }
                        } else {
                          cellValue = cell.render('Cell');
                        }

                        return (
                          <Td
                            {...cell.getCellProps([
                              {
                                className: cell.column.className,
                                style: cell.column.style,
                              },
                            ])}
                            key={`td-${j}`}
                            type={variant}
                          >
                            {(variant === 'summary' || variant === 'brands') &&
                            j >= 3
                              ? '$'
                              : null}
                            {variant === 'conversion' &&
                            j >= 3 &&
                            j !== 5 &&
                            j <= 7
                              ? '$'
                              : null}
                            {variant === 'payment' && j >= 2 ? '$' : null}
                            {variant === 'traffic' && j >= 6 ? '$' : null}
                            {cellValue}
                          </Td>
                        );
                      })}
                  </Tr>
                );
              })}
          </Tbody>
          {Boolean(variant) && (
            <TotalsBar
              totals={totals}
              variant={variant as Props['variant']}
              pageSize={paginationProps.pageSize}
            />
          )}
        </HtmlTable>
      </TableWrapper>

      {showPagination ? <Pagination {...paginationProps} /> : null}
    </>
  );
};

export default Table;
