// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useEffect, useContext, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from './../../../theme/index';
import Input from './../../atoms/Input/Input';
import Select from './../../atoms/Select/Select';
import { Button } from '../../atoms/Button/Button';
import client from '../../api';
import { UserContext } from '../../../context/UserContext';
import ErrorMessage from '../../atoms/ErrorMessage/ErrorMessage';

const Container = styled('div', {
  maxWidth: '480px',
});

const Form = styled('form', {
  margin: '15px 0',
});

const Advice = styled('div', {
  fontFamily: '$body',
  marginBottom: '10px',
});

const SelectorLabel = styled('label', {
  fontFamily: '$label',
  fontWeight: '$bold',
  margin: 0,
  '>div': {
    fontWeight: '$regular',
    fontFamily: '$body',
    padding: 0,
    margin: 0,
  },
});

const inputStyle = {
  marginBottom: '10px',
};

const errors = {
  firstName:
    'First Name must be longer than two characters and contain only letters.',
  lastName:
    'Last Name must be longer than two characters and contain only letters.',
  userName: 'Username must be at least four characters.',
  email: 'E-mail address field is required. Please enter valid E-mail address.',
  password: 'Password must be at least six characters.',
  confirmation:
    'Confirmation Password must be at least six characters and match with the password',
  passMatch: 'The passwords do not match.',
};
const Error = styled('div', {
  fontFamily: '$label',
  color: '#a94442',
  border: '1px solid #ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  padding: '15px 30px',
  fontSize: '$size16',
});
const Success = styled('div', {
  fontFamily: '$label',
  color: '#325e1c',
  border: '1px solid #ebccd1',
  borderColor: '#c9e1bd',
  backgroundColor: '#f3faef',
  borderRadius: '2px',
  padding: '15px 30px',
  fontSize: '$size16',
});

export const AccountDetailsForm: React.FC = () => {
  const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState('');
  const [supportError, setSupportError] = useState(false);
  const [successMessages, setSuccessMessages] = useState('');
  const { uid } = useParams();
  const [role, setRole] = useState([]);
  const [optionsSelect] = useState([
    {
      value: 'link_creator',
      label: 'Link Creator',
    },
    {
      value: 'analyst',
      label: 'Analyst',
    },
  ]);

  const inputFirstName = useRef(null);
  const inputLastName = useRef(null);
  const inputUserName = useRef(null);
  const inputEMail = useRef(null);
  const inputPassword = useRef(null);
  const inputConfirmation = useRef(null);

  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client
        .get(`/api/subusers/get_details/${uid}?_format=json`, {
          headers: { csrf_token },
          auth: { username, password },
        })
        .then(({ data }) => {
          inputFirstName.current.value = data.firstName;
          inputLastName.current.value = data.lastName;
          inputUserName.current.value = data.username;
          inputEMail.current.value = data.email;
          setRole(data.role);
        })
        .catch((e) => {
          console.log('error', e);
          setSupportError(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, username, csrf_token]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const submitHandler = (event: {
    preventDefault: () => void;
    target: any[];
  }): void => {
    event.preventDefault();
    setSuccessMessages('');
    setErrorMessages('');

    const newRole = optionsSelect.find((o) => o.label === role);

    const firstName = inputFirstName.current.value;
    if (firstName.length < 2 || !/^[A-Za-z]*$/.test(firstName)) {
      setErrorMessages(errors.firstName);
      return;
    }
    const lastName = inputLastName.current.value;
    if (lastName.length < 2 || !/^[A-Za-z]*$/.test(lastName)) {
      setErrorMessages(errors.lastName);
      return;
    }
    if (validateEmail(inputEMail.current.value) === null) {
      setErrorMessages(errors.email);
      return;
    }

    if (
      inputPassword.current.value.length > 0 &&
      inputConfirmation.current.value.length > 0
    ) {
      const pass = inputPassword.current.value;
      if (pass.length < 6) {
        setErrorMessages(errors.password);
        return;
      }
      const confirmPass = inputConfirmation.current.value;
      if (confirmPass.length < 6) {
        setErrorMessages(errors.confirmation);
        return;
      }
    }

    const newData = {
      firstName: inputFirstName.current.value,
      lastName: inputLastName.current.value,
      email: inputEMail.current.value,
      password: inputPassword.current.value,
      confirmation: inputConfirmation.current.value,
      role: newRole.value,
    };

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { password, username, csrf_token } = userCredentials;
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      void client
        .post(`/api/subusers/save/${uid}?_format=json`, newData, {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            csrf_token,
          },
          auth: { username, password },
        })
        .then((response) => {
          setSuccessMessages(response.data);
          window.scrollTo(0, 0);
        })
        .catch((e) => {
          setErrorMessages(e.response.data);
          window.scrollTo(0, 0);
        });
    }
  };

  const validateEmail = (email): any => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  return (
    <Container>
      {errorMessages !== '' ? <Error>{errorMessages}</Error> : ''}
      {supportError && <ErrorMessage />}
      {successMessages !== '' ? <Success>{successMessages}</Success> : ''}

      <Form>
        <Input
          type="text"
          label="First Name"
          view="tertiary"
          placeholder="First Name"
          inputRef={inputFirstName}
          css={inputStyle}
        />
        <Input
          type="text"
          label="Last Name"
          view="tertiary"
          placeholder="Last Name"
          inputRef={inputLastName}
          css={inputStyle}
        />
        <Input
          type="text"
          label="Username"
          view="tertiary"
          placeholder="username"
          inputRef={inputUserName}
          css={inputStyle}
          lockValue={true}
        />
        <Input
          type="email"
          label="E-mail Address"
          view="tertiary"
          placeholder="email@email.com"
          inputRef={inputEMail}
          css={inputStyle}
        />

        <Advice>
          A valid e-mail address. All e-mails from the system will be sent to
          this address. The e-mail address is not made public and will only be
          used if you wish to receive a new password or wish to receive certain
          news or notifications by e-mail.
        </Advice>

        <Input
          type="password"
          label="Password"
          view="tertiary"
          inputRef={inputPassword}
          css={inputStyle}
        />
        <Input
          type="password"
          label="Confirm Password"
          view="tertiary"
          inputRef={inputConfirmation}
          css={inputStyle}
        />

        <SelectorLabel htmlFor="options">
          Role *
          <Select
            id="options"
            label="Role"
            placeholder="- Select -"
            options={optionsSelect}
            value={{ label: role }}
            isForm
            setValue={(e): void => {
              setRole(e.label);
            }}
          />
        </SelectorLabel>
      </Form>
      <Button
        type="action"
        onClick={submitHandler}
      >
        SUBMIT
      </Button>
    </Container>
  );
};
