// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState } from 'react';
import { styled } from './../../../theme/index';
import PromotionDetail from './../../atoms/PromotionDetail/PromotionDetail';
import Heading from './../../atoms/Heading/Heading';
import ShareActions from './../../molecules/ShareActions/ShareActions';
import LoadingOverlay from '../../atoms/LoadingOverlay/LoadingOverlay';
import ErrorMessage from '../../atoms/ErrorMessage/ErrorMessage';
import NavigationLink from '../../atoms/NavigationLink/NavigationLink';

interface Details {
  start: Date;
  end: Date | string;
  deal: string;
  description: string;
  coupon?: string;
}

export interface Props {
  details: Details;
  dealID?: string;
  title: string;
  titleUrl?: string;
  asItem?: boolean;
  offSet?: boolean;
  shortUrl?: string;
  dealLink?: string;
}

const Container = styled('div', {
  variants: {
    asItem: {
      true: {
        '&:not(:last-of-type)': {
          paddingBottom: '40px',
          borderBottom: '1px solid $lightgray3',
        },
      },
      false: {
        boxShadow: '0 1px 3px 0 rgba(77, 77, 77,0.5)',
        padding: '8px',
        '@bp3': {
          padding: '33px 39px 32px 22px',
        },
      },
    },
  },
});

const Grid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridGap: '10px',
  '@bp3': {
    gridGap: '12px',
  },
  variants: {
    asItem: {
      true: {
        gridGap: '20px',
        '@bp3': {
          gridGap: '31px',
          gridTemplateColumns: '1fr 140px 22.1%',
        },
      },
      false: {
        '@bp3': {
          gridTemplateColumns: '1fr 25%',
        },
      },
    },
  },
});

const titleStyle = {
  color: '$black',
  fontSize: '$size18',
  paddingBottom: '11px',
  marginBottom: '10px',
  fontFamily: '$heading',
};

const styleShareActions = {
  width: 'none',
  '@bp1': {
    margin: '0 auto',
  },
  '@bp3': {
    width: '200.33px',
    paddingLeft: 0,
    margin: 0,
  },
};

const PromotionCard: React.FC<Props> = ({
  details,
  title,
  titleUrl,
  dealID,
  asItem = false,
  offSet,
}) => {
  const [errorMessages, setErrorMessages] = useState(false);
  let options = [
    { label: 'Start Date:', value: details.start },
    { label: 'End Date:', value: details.end },
    { label: 'Deal:', value: details.deal },
    { label: 'Description:', value: details.description },
  ];
  const [loading, setLoading] = useState(false);
  if (details?.coupon !== undefined) {
    options = [...options, { label: 'Coupon Code', value: details.coupon }];
  }

  const handleToggleLoading = (): void => {
    setLoading((prevState) => !prevState);
  };

  return (
    <Container asItem={asItem}>
      {loading && <LoadingOverlay style={{ zIndex: '100' }} />}
      {errorMessages && <ErrorMessage message="Could not get a short url." />}

      {!asItem && (
        <Heading
          as="h2"
          css={titleStyle}
        >
          <NavigationLink
            href={`/brands/${titleUrl}`}
            style={{ backgroundColor: '#fff' }}
            target="_blank"
          >
            {title}
          </NavigationLink>
        </Heading>
      )}
      <Grid asItem={asItem}>
        <PromotionDetail
          offSet={offSet}
          options={options}
          view={asItem}
        />

        <ShareActions
          column={asItem}
          label={asItem ? 'Copy' : 'Copy Deal'}
          dealID={dealID}
          icon={!asItem}
          css={styleShareActions}
          handleToggleLoading={handleToggleLoading}
          setErrorMessages={setErrorMessages}
        />
      </Grid>
    </Container>
  );
};

export default PromotionCard;
