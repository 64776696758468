// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useContext, useState } from 'react';
import { styled, type ThemeProps } from '../../../theme/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../atoms/Button/Button';
import CopyDisclaimer from '../../atoms/CopyDisclaimer/CopyDisclaimer';
import useCopy from '../../../hook/useCopy/useCopy';
import useSharingLinks from '../../../hook/useSharingLinks/useSharingLinks';
import { UserContext } from '../../../context/UserContext';
import client from '../../api';

interface Props extends ThemeProps {
  label?: string;
  dealID?: string;
  icon?: boolean;
  toCopy?: string;
  column?: boolean;
  parentPage?: string;
  handleToggleLoading?: (loading: boolean) => void;
  setErrorMessages?: (error: boolean) => void;
}

const Card = styled('div', {
  // maxWidth: '100%',
  button: {
    height: '44px',
    padding: '13px',
  },
});

const Icon = styled('span', {
  marginRight: '12px',
});

const Grid = styled('div', {
  display: 'flex',
  marginTop: '5px',
  '.social': {
    marginTop: '12px',
  },
  '.last': {
    marginLeft: '25px',
  },
  variants: {
    column: {
      true: {
        marginTop: 0,
        maxWidth: '100%',
        display: 'block',
        '.last': {
          marginLeft: 0,
        },
        '@bp0': {
          display: 'flex',
          width: '320px',
          '.last': {
            marginLeft: '25px',
          },
        },
        '@bp3': {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          '.last': {
            marginLeft: 0,
          },
        },
      },
    },
  },
});

const copyDisclaimerCss = {
  margin: '19px 0 0',
  variants: {
    column: {
      true: {
        margin: '19px 0 5px',
      },
    },
  },
};

const SafariPopupDiv = styled('div', {
  // position: 'absolute',
  // backgroundColor: 'rgba(0,0,0, 0.7)',
  // top: 0,
  // right: 0,
  // width: '100%',
  // height: '100%'
});
const SafariInputField = styled('input', {
  border: '1px solid rgb(159, 161, 168) !important',
  // fontSize: '1rem',
  height: '38px',
  lineHeight: 1,
  marginBottom: '10px',
  padding: '6px 12px',
  width: '100%',
});
const buttonCss = {
  letterSpacing: '0.8px',
  fontWeight: '$bold',
  padding: '13px 0 13px',
};

const ShareActions: React.FC<Props> = ({
  column,
  label = 'Copy Deal',
  dealID,
  toCopy,
  icon = true,
  parentPage = '',
  handleToggleLoading,
  setErrorMessages,
  ...props
}) => {
  const { userCredentials } = useContext(UserContext);
  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  const [copyAction, copied] = useCopy();
  const { shareOnTwitter, shareOnFacebook } = useSharingLinks();
  const [showSafariURL, setShowSafariURL] = useState(false);
  const [urlString, setUrlString] = useState(
    toCopy === undefined ? '' : toCopy,
  );

  let isApple = false;
  if (navigator.userAgent.match(/Macintosh|ipad|iphone/i) !== null) {
    isApple = true;
  }

  const showLoading = (): void => {
    handleToggleLoading(true);
  };
  const hideLoading = (): void => {
    handleToggleLoading(false);
  };

  const handleCopyAction = (): void => {
    if (toCopy === undefined) {
      showLoading();

      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client
        .get(`/api/spromotion/${dealID}/dealLink/get?_format=json`, {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            csrf_token,
          },
          auth: { username, password },
        })
        .then(({ data }) => {
          copyAction(data);
          hideLoading();
        })
        .catch(() => {
          // console.log("error", e);
          setErrorMessages(true);
          hideLoading();
        });
    } else {
      copyAction(toCopy);
    }
  };

  const handleCopyActionSafari = (): void => {
    if (toCopy === undefined) {
      copyAction(urlString);
    } else {
      copyAction(toCopy);
    }
  };

  const getURLSafari = (): void => {
    if (toCopy === undefined) {
      showLoading();

      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client
        .get(`/api/spromotion/${dealID}/dealLink/get?_format=json`, {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            csrf_token,
          },
          auth: { username, password },
        })
        .then(({ data }) => {
          setUrlString(data);
          setShowSafariURL(true);

          hideLoading();
        })
        .catch(() => {
          // console.log("error", e);
          setErrorMessages(true);
          hideLoading();
        });
    } else {
      copyAction(toCopy);
    }
  };

  const handleSocialMediaShare = (media): void => {
    if (toCopy === undefined) {
      showLoading();

      if (isApple) {
        // apple device shit
        if (media === 'facebook') {
          shareOnFacebook(urlString);
        } else if (media === 'twitter') {
          shareOnTwitter(urlString);
        }

        hideLoading();
      } else {
        void client
          .get(`/api/spromotion/${dealID}/dealLink/get?_format=json`, {
            headers: {
              'Content-type': 'application/x-www-form-urlencoded',
              csrf_token,
            },
            auth: { username, password },
          })
          .then(({ data }) => {
            if (data !== '') {
              if (media === 'facebook') {
                shareOnFacebook(data);
              } else if (media === 'twitter') {
                shareOnTwitter(data);
              }
            }

            hideLoading();
          })
          .catch((e) => {
            console.log('error', e);
            setErrorMessages(true);
            hideLoading();
          });
      }
    } else {
      if (media === 'facebook') {
        shareOnFacebook(toCopy);
      } else if (media === 'twitter') {
        shareOnTwitter(toCopy);
      }
    }
  };

  const socialBlock = (): any => {
    return (
      <Grid
        column={column}
        className="socialButtons"
      >
        <Button
          onClick={() => {
            // console.log("get shortUrl for facebook");
            handleSocialMediaShare('facebook');
          }}
          className="social"
          css={{ color: '$blue2' }}
          type="social"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </Button>

        <Button
          onClick={() => {
            // console.log("get shortUrl for twitter");
            handleSocialMediaShare('twitter');
          }}
          className="social last"
          css={{ color: '$blue1' }}
          type="social"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </Button>
      </Grid>
    );
  };

  const ShowAppleActionBlock = (): Element => {
    if (typeof toCopy === 'string') {
      return (
        <SafariPopupDiv className="safariPopup">
          <SafariInputField
            type="text"
            value={urlString}
            disabled={true}
            style={{ display: 'none' }}
          />
          <Button
            css={buttonCss}
            onClick={() => {
              handleCopyActionSafari();
            }}
          >
            <Icon>
              <FontAwesomeIcon icon={faLink} />
            </Icon>
            Copy link
          </Button>

          {socialBlock()}
        </SafariPopupDiv>
      );
    } else {
      return (
        <SafariPopupDiv className="safariPopup">
          <SafariInputField
            type="text"
            value={urlString}
            disabled={true}
          />
          <Button
            css={buttonCss}
            onClick={() => {
              handleCopyActionSafari();
            }}
          >
            <Icon>
              <FontAwesomeIcon icon={faLink} />
            </Icon>
            Copy link
          </Button>
        </SafariPopupDiv>
      );
    }
  };

  const ShowAppleActionBtn = (): Element => {
    if (!showSafariURL) {
      if (toCopy === undefined) {
        return (
          <Button
            css={buttonCss}
            onClick={() => {
              getURLSafari();
            }}
          >
            <Icon>
              <FontAwesomeIcon icon={faLink} />
            </Icon>
            GENERATE LINK
          </Button>
        );
      } else if (typeof toCopy === 'string') {
        return (
          <SafariPopupDiv className="safariPopup">
            <SafariInputField
              type="text"
              value={urlString}
              disabled={true}
              style={{ display: 'none' }}
            />
            <Button
              css={buttonCss}
              onClick={() => {
                handleCopyActionSafari();
              }}
            >
              <Icon>
                <FontAwesomeIcon icon={faLink} />
              </Icon>
              Copy link
            </Button>

            {socialBlock()}
          </SafariPopupDiv>
        );
      }
    }
  };

  return (
    <Card {...props}>
      {showSafariURL && <ShowAppleActionBlock />}

      <div>
        {isApple && <ShowAppleActionBtn />}
        {!isApple && (
          <Button
            css={buttonCss}
            onClick={() => {
              handleCopyAction();
            }}
          >
            {icon && (
              <Icon>
                <FontAwesomeIcon icon={faLink} />
              </Icon>
            )}
            {label}
          </Button>
        )}

        {copied && (
          <CopyDisclaimer
            column={column}
            css={copyDisclaimerCss}
          >
            Link Copied
          </CopyDisclaimer>
        )}
      </div>

      {(parentPage === '' && !isApple) || (isApple && showSafariURL) ? (
        <Grid
          column={column}
          className="socialButtons"
        >
          <Button
            onClick={() => {
              // console.log("get shortUrl for facebook");
              handleSocialMediaShare('facebook');
            }}
            className="social"
            css={{ color: '$blue2' }}
            type="social"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </Button>

          <Button
            onClick={() => {
              // console.log("get shortUrl for twitter");
              handleSocialMediaShare('twitter');
            }}
            className="social last"
            css={{ color: '$blue1' }}
            type="social"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </Button>
        </Grid>
      ) : null}
    </Card>
  );
};

export default ShareActions;
