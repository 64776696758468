// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useContext, useEffect, useState } from 'react';
import { styled } from './../../../theme/index';
import { UserInformation } from './../../molecules/UserInformation/UserInformation';
import PaymentForm from './../../organisms/PaymentForm/PaymentForm';
import client from '../../api';
import { UserContext } from '../../../context/UserContext';
import LoadingOverlay from '../../atoms/LoadingOverlay/LoadingOverlay';
import ErrorMessage from '../../atoms/ErrorMessage/ErrorMessage';

const Wrapper = styled('div', {
  marginTop: '20px',
  marginBottom: '10px',
  '@bp3': {
    marginTop: '41px',
    marginBottom: '50px',
  },
});

export const Account: React.FC = () => {
  const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState(false);
  const [name, setName] = useState('');
  const [details, setDetails] = useState([
    {
      label: 'Email',
      value: '',
    },
    {
      label: 'Phone',
      value: '',
    },
    {
      label: 'Address',
      value: '',
    },
    {
      label: 'City',
      value: '',
    },
    {
      label: 'Zipcode',
      value: '',
    },
    {
      label: 'Country',
      value: '',
    },
    {
      label: 'Company',
      value: '',
    },
    {
      label: 'Business Model',
      value: '',
    },
    {
      label: 'Product Categories',
      value: '',
    },
    {
      label: 'Target Audience',
      value: '',
    },
    {
      label: 'Join date',
      value: '',
    },
  ]);
  const [showPayMethod, setShowPayMethod] = useState(false);
  const [loading, setLoading] = useState(true);

  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      setLoading(true);

      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client
        .get('/api/user/get/details/part?_format=json', {
          headers: { csrf_token },
          auth: { username, password },
        })
        .then(({ data }) => {
          setShowPayMethod(data.payMethod.show);

          setName(data.user_info.userName);
          setDetails(data.user_info);
          setLoading(false);
        })
        .catch((e) => {
          console.log('error', e);
          setErrorMessages(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, username, csrf_token]);

  return (
    <>
      {loading && (
        <LoadingOverlay style={{ position: 'absolute', zIndex: '100' }} />
      )}
      {errorMessages && <ErrorMessage />}

      <UserInformation
        name={name}
        details={details}
      />

      {showPayMethod && (
        <Wrapper>
          <PaymentForm />
        </Wrapper>
      )}
    </>
  );
};

export default Account;
