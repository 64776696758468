// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useEffect, useContext } from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import { UserContext } from '../../../context/UserContext';
import client from '../../api';
import TitleSection from './../../atoms/TitleSection/TitleSection';
import Checkbox from './../../atoms/Checkbox/Checkbox';
import Button from '../../../components/atoms/Button/Button';

const Container = styled('div', {
  display: 'block',
  width: '100%',
  paddingLeft: 0,
  paddingRight: 0,
  alignItems: 'center',
  fontFamily: '$body',
  a: {
    color: 'black',
    fontWeight: '$regular',
    textDecoration: 'none',
    cursor: 'pointer',
    marginRight: '3px',
    marginLeft: '3px',
    wordBreak: 'break-word',
  },
  '.title-section': {
    padding: '15px 20px',
    justifyContent: 'left',
  },
  '.text-bold': {
    fontWeight: '$bold',
  },
  '.paragraph-bold': {
    fontFamily: '$label',
    marginBottom: '6px',
    fontWeight: '$bold',
    fontSize: 'size16',
    lineHeight: 1.4,
    color: '$darkgray',
  },
});

const Error = styled('div', {
  fontFamily: '$label',
  color: '#a94442',
  border: '1px solid #ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  padding: '15px 30px',
  textAlign: 'center',
  fontSize: '$size16',
});

const PrivacyPolicy: React.FC<ThemeProps> = ({
  lockValue = true,
  showCheck = false,
  ...props
}) => {
  const { userCredentials } = useContext(UserContext);
  const [nameValue, setNameValue] = useState('');
  const [errorMessages, setErrorMessages] = useState('');
  const [isAcceptedPrivacy, setIsAcceptedPrivacy] = useState(false);

  const userCred = JSON.parse(window.localStorage.getItem('user_credit'));

  // const isLock, = lockValue!==null ? lockValue : userCred.user.privacy;
  let isAccept = true;
  if (userCred.user.privacy === 0) {
    isAccept = false;
  }

  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;

  useEffect(() => {
    setNameValue(userCred.user.privacy === 1 ? 'option' : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateAccept = (): void => {
    setIsAcceptedPrivacy(true);
  };

  const handleChanged = (): void => {
    // const htmlEle = values.target.outerHTML;
    // let splits = htmlEle.split('data-state="');
    // splits = splits[1].split('"');
    // if (splits[0]==="checked") {
    //   console.log("4-> unchcked");
    // }
    // else {
    // update the window.localStorage Obj.
    if (isAcceptedPrivacy) {
      const userDetails = JSON.parse(
        window.localStorage.getItem('user_credit', {}),
      );
      userDetails.user.privacy = 1;
      window.localStorage.setItem('user_credit', JSON.stringify(userDetails));

      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client
        .get('/api/user/privacypolicy/set?_format=json', {
          headers: { csrf_token },
          auth: { username, password },
        })
        .then(() => {
          window.location.href = '/';
        })
        .catch((e) => {
          // console.log("error", e);
          setErrorMessages(e.response.data.message);
        });
    } else {
      setErrorMessages('You must Accept the Privacy Policy to continue.');
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
    // }
  };

  return (
    <Container {...props}>
      {errorMessages !== '' ? <Error>{errorMessages}</Error> : ''}

      <TitleSection
        as="h3"
        className="title-section"
      >
        PRIVACY POLICY
      </TitleSection>
      <div
        style={{
          padding: '20px',
        }}
      >
        <p dir="ltr">Last updated: April 13, 2021</p>

        <p dir="ltr">Effective Date: April 13, 2021</p>

        <p dir="ltr">
          BrandCycle,Inc. (“BrandCycle,” “we” or “us”) is committed to
          protecting the privacy of our users’ personal information. This
          Privacy Policy describes how our website www.brandcycle.com (the
          “Website” or the “Site”) collects, uses, stores and discloses our
          visitors’ Personal Information.{' '}
        </p>

        <p dir="ltr">
          This Privacy Policy is incorporated into and subject to our Terms and
          Conditions. By accessing our Website, you are demonstrating your
          consent to the terms and conditions in our Privacy Policy.&nbsp; If
          you do not agree with the terms and conditions described in this
          policy, you are requested not to access the Site or otherwise provide
          your Personal Information.&nbsp;
        </p>

        <p dir="ltr">
          <strong>Scope of this Privacy Policy</strong>
        </p>

        <p dir="ltr">
          This Privacy Policy applies to the processing of Personal Information
          collected by us when you:
        </p>

        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Visit our Website or other websites that display or link to this
              Privacy Policy;&nbsp;&nbsp;
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Register and open an account with us as a publisher or retail
              partner;
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">Access the BrandCycle proprietary platform;</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">Make or receive a payment;&nbsp;</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">Visit our branded social media pages;</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">Visit our offices;</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Receive communications from us, including emails, phone calls,
              texts or fax; and
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Register for, attend or take part in our events, webinars, or
              contests.
            </p>
          </li>
        </ul>

        <p dir="ltr">
          <strong>Changes to Our Privacy Policy</strong>
          <br />
          We reserve the right to modify this Privacy Policy at any time and for
          any reason in our sole discretion.&nbsp; If we decide to change our
          Privacy Policy, we will post those changes on this page with a 30-day
          notice period before the changes become effective.&nbsp; We may also
          notify our registered users via email of the changes.&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          The effective date of the policy is written above. Please check back
          regularly to review any changes to this Privacy Policy. If you use or
          access the Site after this policy is modified, you are providing your
          consent to the changes. If you do not agree to any changed version of
          this Privacy Policy, you should not use or access our Website.&nbsp;
        </p>

        <p dir="ltr">
          <strong>Personal Information</strong>
          <br />
          Personal Information is any information, alone or in combination, that
          can identify an individual, such as your name, email address and phone
          number and that is not otherwise publicly available. The legal
          definition of Personal Information depends on the law applicable to
          where you are physically located and may include other types of
          information. Only the legal definition that applies to your physical
          location will apply to you under this Privacy Policy.
        </p>

        <p dir="ltr">
          <strong>
            What Personal Information Do We Collect and How Do We Use It?
          </strong>
          <br />
          <em>Information we collect automatically </em>- You can browse the
          Site without providing us with any information, but we gather some
          statistical data to understand how the Site is used, such as the
          Internet Protocol (IP) address of a computer requesting a page, the
          pages requested, computer and connection information such as device
          and browser type and operating system. We do not associate this
          statistical information with your Personal Information that we
          otherwise collect. We may use third party analytics providers to help
          us understand and use this statistical data.&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          <em>Information you provide directly</em> -- Providing Personal
          Information such as your name or email address is entirely
          voluntary.&nbsp;&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          For example, when you register and open an account as a publisher or
          retail partner, we ask that you provide your name, website, address,
          phone number and information about your business (for example, your
          business model, target audience, traffic, social media profiles,
          etc.). You will also need to register and set up an account to access
          our proprietary platform.&nbsp; To create an account, you will need to
          create a User ID and unique password.&nbsp; We collect this
          information to set up and manage your account as one of our publishers
          or retail partners.&nbsp; We also use this information to respond to
          your inquiries and to send you informational and promotional emails
          and updates.
        </p>

        <p dir="ltr">
          We retain Personal Information only for as long as necessary to
          provide the requested services, comply with our legal obligations,
          resolve disputes, and enforce our agreements.
        </p>

        <p dir="ltr">
          We do not collect any “special categories” of Personal Information
          about you (this includes details about your race or ethnicity,
          religious or philosophical beliefs, sex life, sexual orientation,
          political opinions, trade union membership, information about your
          health, and genetic and biometric data).
        </p>

        <p dir="ltr">
          If you believe that your Personal Information has been provided to us
          improperly or want to exercise your rights relating to your Personal
          Information, please contact us as described below.
        </p>

        <p dir="ltr">
          <strong>Payment Information</strong>
          <br />
          When you make or receive a payment through the Site as a publisher or
          retail partner, you will be directed from our Site to our third-party
          payment vendor, Payoneer. Payoneer will collect your bank information
          and other related information in order to process the transaction.
          Payoneer has implemented security measures to protect your Personal
          Information.&nbsp; As Payoneer is a third-party vendor, the collection
          of your information for payment purposes is covered by Payoneer’s
          Privacy Policy and not by this Privacy Policy. You may view the
          Payoneer Privacy Policy at&nbsp;
          <a
            href="https://www.payoneer.com/legal/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.payoneer.com/legal/privacy-policy/
          </a>
          . &nbsp;BrandCycle.com will have access to the information collected
          by Payoneer for the purposes of managing your account, although the
          information we receive from Payoneer will be encrypted and we will not
          retain or store any of the data.
        </p>

        <p dir="ltr">
          <strong>Do We Use Cookies or other Technologies?</strong>
        </p>

        <p dir="ltr">
          A cookie is a piece of data stored on your computer’s hard drive that
          contains information about you and your computer. The cookies used on
          the Website do not contain any identification that would be useful to
          another individual or organization. We use cookies, for example, to
          remember your login information so you do not have to enter a username
          and password each time you return to the site (should you choose this
          option). We also use cookies to provide statistical information on
          site usage and personalize and enhance the online experience. Some of
          these cookies may be active during the time you are viewing a website
          (“session cookies”). Other cookies may remain on your computer after
          you have closed your browser or turned off your computer (“persistent
          cookies”). We do use tracking cookies on other websites, such as to
          serve you with targeted ads, either on our Website or third-party
          websites.&nbsp;
        </p>

        <p dir="ltr">
          &nbsp;The following cookies are deployed on the Website:&nbsp;
        </p>

        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Strictly Necessary Cookies: These cookies are strictly necessary
              to provide the Website. For example, they are used to authenticate
              and identify returning users.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Performance and Functionality Cookies: These cookies provide
              statistical information on site usage, such as web
              analytics.&nbsp; They also help us to personalize and enhance your
              online experience.
            </p>
          </li>
        </ul>

        <p dir="ltr">
          Just like any other usage information we collect, information from
          cookies allows us to improve the Site and customize your online
          experience. You can set your browser to accept all cookies, reject all
          cookies, or notify you when a cookie is set.&nbsp; See
          www.allaboutcookies.org to learn how to disable cookies on your
          browser or otherwise opt out of cookies.&nbsp; if you set your browser
          to disable all cookies or opt out, some features of the Website may
          not function properly.&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          We also use web beacons and pixels on Site and in emails. For example,
          we may place a pixel in marketing emails that notify us when you click
          on a link in the email. We use these technologies to operate and
          improve our Site and marketing emails.
        </p>

        <p dir="ltr">
          This Privacy Policy does not cover the use of cookies used by third
          parties, and we are not responsible for their privacy policies and
          practices. Please be aware that cookies placed by third parties may
          continue to track your activities online even after you have left our
          Site.
        </p>

        <p dir="ltr">
          The Site uses Google Analytics to help us manage and improve the Site
          and our services. Google provides a Browser Add-On that allows you to
          opt-out by downloading and installing the add-on for your web browser,
          available at{' '}
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            target="_blank"
            rel="noreferrer"
          >
            https://tools.google.com/dlpage/gaoptout
          </a>
          .&nbsp;&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          <strong>How Do We Protect Your Information?</strong>
          <br />
          We have implemented reasonable precautions to protect Personal
          Information from loss, misuse, unauthorized access, disclosure,
          alteration and destruction. We follow generally accepted standards to
          protect the Personal Information submitted to us, both during
          transmission and once we receive it. For example, your Personal
          Information is retained in secured networks and is only accessible by
          a limited number of persons who have special access rights to such
          systems and are required to keep the information confidential.&nbsp;
        </p>

        <p dir="ltr">
          Be advised, however, no method of transmission over the Internet, or
          method of electronic storage, is 100% secure, however. Therefore, we
          cannot guarantee the absolute security of information we collect and
          retain.&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          If you establish an account with us, please do not provide your login
          information, including your password, to another person. We cannot be
          responsible for a data breach or other incident if a password is used
          by unauthorized persons. You are solely responsible for protecting
          your password, limiting access to your devices and signing out of
          websites after your sessions.&nbsp;
        </p>

        <p dir="ltr">
          <strong>Do We Disclose Personal Information to Third Parties?</strong>
          <br />
          We do not disclose your Personal Information to third parties except
          to trusted third parties that enable us to operate the Site and
          conduct our business. These third partis include our contracted
          service providers and professional advisors, who provide services such
          as IT and system administration and hosting, payment processing,
          research and analytics, marketing, legal and accounting services and
          customer support.&nbsp; These service providers are contractually
          obligated to protect the security of Personal Information we provide
          to them and not use it for any other purpose or party.&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          We may also disclose your information to our retail partners and/or
          publishers that may be a good referral or revenue source for your
          business.&nbsp;
        </p>

        <p dir="ltr">
          We may also disclose your information when we believe release is
          appropriate to comply with the law, enforce our Site policies and
          terms, detect or prevent fraud, security or technical issues, or
          protect our or others’ rights, property, or safety. This includes
          exchanging information with other companies and organizations for
          fraud protection and spam/malware prevention.&nbsp;
        </p>

        <p dir="ltr">
          We may provide non-personally identifiable visitor information to
          other parties for marketing, advertising, or other uses.
        </p>

        <p dir="ltr">
          If our assets are merged with or purchased by a third party, your
          Personal Information will be transferred to that third party.
        </p>

        <p dir="ltr">
          <strong>Third Party Sites and Links</strong>
          <br />
          Our Site may contain links to other websites that are independent, are
          not owned or operated by us, are not covered by this Privacy Policy
          and which may incorporate third party information. These third-party
          sites have separate and independent privacy policies. If you access
          other sites using the links provided, the operators of these sites may
          collect information from you, which will be used by them in accordance
          with their privacy policy and terms of service, which may differ from
          ours. We therefore have no responsibility or liability for the content
          and activities of other websites, even if they are linked to our Site.
        </p>

        <p dir="ltr">
          <strong>Social Media Features</strong>
          <br />
          Our Site includes features from social media websites such as the
          Facebook Like button and features on Twitter, Google+ and Instagram
          that run on our Site. Please note that our Site is not sponsored or
          endorsed by Facebook, Twitter, Google+ or Instagram, which are
          trademarked and owned by third parties and not by us. These features
          may collect your IP address or other Personal Information, which page
          you are visiting on our Site, and may set a cookie to enable the
          feature to function properly.&nbsp;
        </p>

        <p dir="ltr">
          Social media websites may disclose your Personal Information to us, in
          accordance with your agreement with the social media website and the
          terms of that site’s privacy policy. By connecting to our Site through
          a social media website, you are authorizing us to collect, store, use
          and disclose your information in accordance with our Privacy
          Policy.&nbsp;&nbsp;The social media website’s collection, use, storage
          and disclosure of your Personal Information is not covered by this
          Privacy Policy.
        </p>

        <p dir="ltr">
          <strong>Located in the United States</strong>
          <br />
          BrandCycle is located in the United States and our Site is owned and
          operated by us in the United States. If you are located outside the
          United States, your Personal Information may be collected, transferred
          to and stored by us in the United States and by our affiliates and
          categories of third parties disclosed above, which are based in the
          United States other countries. Therefore, your Personal Information
          may be processed outside your jurisdiction and that may not provide
          for the same level of data protection as your jurisdiction, such as
          the EEA. We ensure that the recipient of your Personal Information
          offers an adequate level of protection and security, for instance by
          entering into the appropriate data processing agreements and, if
          required, standard contractual clauses or an alternative mechanism for
          the transfer of data as approved by the European Commission (Art. 46
          GDPR) or other applicable regulator. Because our servers that store
          your information are located in the United States, your information
          may be available to US government entities or agencies under a lawful
          court order or other legal process in the US.
        </p>

        <p dir="ltr">
          <strong>EEA Disclosures</strong>
          <br />
          <em>The Legal Basis for Using EEA Personal Data</em> -- For residents
          of the European Economic Area (EEA), we advise that your Personal
          Information will be transferred to and processed in the United States,
          which has data protection laws that are different than those in your
          country and may not be as protective. The United States has not sought
          or received a finding of “adequacy” from the European Union under
          Article 45 of the General Data Protection Regulation (GDPR).&nbsp; Our
          legal basis for collecting and using your Personal Information is to
          do so with your consent; where we need the Personal Information for
          performance of a contract or requested service, or where the
          collection and use is in our or another’s legitimate interests and not
          overridden by your data protection interests or fundamental rights and
          freedoms. In some cases, we may also have a legal obligation to
          collect the Personal Information in question. If we collected your
          Personal Information with your consent, you may withdraw your consent
          at any time.&nbsp;
        </p>

        <p dir="ltr">
          Our retention of your Personal Information and any subsequent
          communications are based on our legitimate interest in providing you
          with new and potentially relevant materials based on your geography,
          role, or company. As always, you can elect to opt out from receiving
          such future communications.&nbsp;
        </p>

        <p dir="ltr">
          To the extent that we transfer Personal Information from the EEA to a
          jurisdiction outside the EEA that has not been adduced by the European
          Commission as providing adequate data protections (such as the United
          States), we will ensure that such Personal Information is safeguarded
          through appropriate contractual terms or other approved
          mechanisms.&nbsp;
        </p>

        <p dir="ltr">
          <em>Rights for EEA Residents</em> - If you are a resident of the EEA,
          you have the right to:&nbsp;
        </p>

        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Find out if we use your personal information, to access your
              Personal Information, and receive copies of your Personal
              Information.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Withdraw any express consent that you have provided to the
              processing of your Personal Information at any time without
              penalty.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Access your Personal Information and have it corrected or amended
              if it is inaccurate or incomplete.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Obtain a transferable copy of some of your Personal Information
              which can be transferred to another provider when the Personal
              Information was processed based on your consent.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              If you believe your Personal Information is inaccurate, no longer
              necessary for our business purposes, or if you object to our
              processing of your Personal Information, you also have the right
              to request that we restrict the processing of your data pending
              our investigation and/or verification of your claim.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Request your Personal Information be deleted or restricted under
              certain circumstances. For example, if is using your Personal
              Information on the basis of your consent and has no other legal
              basis to use such, you may request your Personal Information be
              deleted when you withdraw your consent.&nbsp;
            </p>
          </li>
        </ul>

        <p dir="ltr">
          Withdrawing your consent will not affect the lawfulness of any
          processing we conducted prior to your withdrawal, nor will it affect
          processing of your Personal Information conducted in reliance on
          lawful processing grounds other than consent.&nbsp;
        </p>

        <p dir="ltr">
          If we ask you to provide Personal Information to us to comply with a
          legal requirement or enter into a contract, we will inform you of this
          and let you know whether providing us with your Personal Information
          is required and if not, the consequences of not sharing your Personal
          Information with us.&nbsp; Similarly, if we collect and use your
          Personal Information in reliance on our or a third party&apos;s
          legitimate interests and those interests are not already described
          above, we will let you know what those legitimate interests
          are.&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          We endeavor to apply suitable safeguards to protect the privacy and
          security of your Personal Information and to use it only consistent
          with your relationship with us and the practices described in this
          Privacy Information.&nbsp;&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          To withdraw consent or exercise these rights, please contact us via
          email at{' '}
          <a href="mailto:support@brandcycle.com">support@brandcycle.com</a>
          .&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          If you are not satisfied with our response, or believe we are
          processing your Personal Information in violation of the law, you have
          the right to lodge a complaint with the Supervisory Authority (also
          known as Data Protection Authority) or other appropriate governmental
          authority in your EEA Member State.&nbsp; A list of Supervisory
          Authorities is available at:{' '}
          <a
            href="https://edpb.europa.eu/about-edpb/board/members_en"
            target="_blank"
            rel="noreferrer"
          >
            https://edpb.europa.eu/about-edpb/board/members_en
          </a>
          .&nbsp;
        </p>

        <p dir="ltr">
          <strong>Canada Disclosures</strong>
          <br />
          For visitors or users of the Site in Canada, we take appropriate steps
          to comply with applicable Canadian data privacy requirements,
          including Canada’s Anti-Spam Legislation (CASL) and the Personal
          Information Protection and Electronic Documents Act (PIPEDA).&nbsp;
          Note, however, Personal Information stored or processed outside of
          Canada, including in the United States or another foreign country, may
          not be subject to the PIPEDA.&nbsp; In addition, Personal Information
          stored or processed outside of Canada is subject to the laws of that
          other country and may be available to the foreign government of the
          country in which the Personal Information or the entity controlling it
          is located pursuant to a lawful order in that jurisdiction.&nbsp; This
          Privacy Policy is intended to provide the required notices regarding
          our collection and use of Personal Information, including transfer to
          the United States.&nbsp;&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          You may request access to or correction of your Personal Information,
          or withdraw consent to our collection, use or disclosure of your
          Personal Data, by contacting at support@brandcycle.com.&nbsp; These
          rights are subject to applicable contractual and legal restrictions
          and reasonable notice. We may take reasonable steps to verify your
          identity before honoring any such requests.&nbsp;
        </p>

        <p dir="ltr">
          <strong>
            California Do Not Track Disclosures/Your California Privacy Rights
          </strong>
          <br />
          If you are a resident of the State of California, under California
          Civil Code sections 1798.83-1798.84 (“Shine The Light” law), you have
          the right to ask us for a notice identifying the categories of
          Personal Information which we share with our affiliates and/or third
          parties for direct marketing purposes. Since we do not share personal
          Information with third parties for direct marketing purposes, we are
          exempt from this requirement.&nbsp;&nbsp;&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          For California residents under the age of 18 and registered users of
          our Site, California law (Business and Professionals Code § 22581)
          provides that you can request the removal of content or information
          you posted on Websites.&nbsp; Any such request should be sent to us at
          support@brandcycle.com along with a description of the posted content
          or other information to be removed.&nbsp; Be advised, however, that
          applicable law may not permit us to completely or comprehensively
          remove your deleted content or for other reasons as set forth in this
          California law.&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          We honor Do Not Track signals and do not track, plant cookies, or use
          advertising when a Do Not Track browser mechanism is in place.&nbsp;
        </p>

        <p dir="ltr">
          BrandCycle does not share or transfer Personal Information that would
          constitute a “sale” under the California Consumer Privacy Act.&nbsp;
        </p>

        <p dir="ltr">
          <strong>Your Choices</strong>
          <br />
          Registered publishers or retail partners can access and update their
          Personal Information from within their account.&nbsp;
        </p>

        <p dir="ltr">
          Subject to applicable law, an individual has the right to request from
          us a copy of what Personal Information that we have collected or to
          ask to correct or update their it.&nbsp; Individuals can also request
          that we delete their Personal Information from our systems and
          records.&nbsp; We strive to respond to these requests with 30 days or
          as otherwise required by applicable law.&nbsp;&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          Whenever you directly provide us with your Personal Information,
          please be sure it is accurate and complete.&nbsp; We cannot be
          responsible for any information you provide to us that is incomplete
          or incorrect.&nbsp;&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          We will not collect, use, or disclose your Personal Information in any
          ways or for any purposes that are materially different from those set
          forth herein. However, if we wish to do so in the future, we will
          obtain your consent first, and offer you the choice to opt-out of such
          proposed collection, use, or disclosure.&nbsp;&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          If we send you promotional or marketing messages, you will be able to
          opt out of receiving our marketing or promotional email communications
          by using the “Unsubscribe” feature at the bottom of each email from us
          or by requesting to opt out by emailing us at{' '}
          <a
            href="mailto:support@brandcycle.com"
            target="_blank"
            rel="noreferrer"
          >
            support@brandcycle.com
          </a>
          . &nbsp;We may also request that we not provide your information to
          our retail partners and/or publishers that we believe may be a good
          referral or revenue source for your business.&nbsp;
        </p>

        <p dir="ltr">
          Please note that you cannot opt out of receiving transactional
          communications from us regarding services you request, your account
          with us or other transactional or administrative issues.&nbsp;
        </p>

        <p dir="ltr">
          <strong>Children’s Privacy</strong>
          <br />
          The Site is not intended for users under 16 years old.&nbsp; You must
          be at least 16 years old to register as a publisher and access our
          proprietary platform.&nbsp; If you are under 16, you should not access
          our Site, create an account, or provide any information to
          us.&nbsp;&nbsp;&nbsp;
        </p>

        <p dir="ltr">
          We do not knowingly collect Personal Information from persons under 16
          years of age. If you believe that we might have collected any Personal
          Information from a child under 16, please email us at{' '}
          <a href="mailto:support@brandcycle.com">support@brandcycle.com</a> so
          we may remove it.&nbsp;
        </p>

        <p dir="ltr">
          <strong>Contacting Us</strong>
          <br />
          Please contact us with any questions about this Privacy Policy:
          <br />
          BrandCycle, Inc. Email:{' '}
          <a href="mailto:support@brandcycle.com">support@brandcycle.com</a> |
          Address: 16 Rae Ave, Needham MA 02492
        </p>

        {showCheck === true ? (
          <>
            <Checkbox
              name={nameValue}
              className="text-bold"
              readOnly={lockValue}
              isAccept={isAccept}
              callback={handleUpdateAccept}
            >
              Accept Privacy Policy *
            </Checkbox>

            <Button
              type="action"
              style={{ width: '10rem', marginTop: '2rem' }}
              onClick={() => {
                handleChanged();
              }}
            >
              Continue
            </Button>
          </>
        ) : null}
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
