import React from 'react';
import * as RadixTabs from '@radix-ui/react-tabs';
import { styled } from './../../../theme/index';
import { type TabsTriggerProps } from '@radix-ui/react-tabs';

const Container = styled(RadixTabs.Trigger, {
  background: 'none',
  font: 'inherit',
  cursor: 'initial',
  outline: 'inherit',
  border: '2px solid',
  borderColor: '$black',
  color: '$black',
  fontWeight: 'bold',
  fontFamily: '$heading',
  lineHeight: '1',
  textTransform: 'uppercase',
  borderRadius: '0',
  marginRight: '-1px',
  position: 'relative',
  borderBottom: 'none',
  padding: '12px 20px',
  fontSize: '$size14',
  '&[aria-selected="true"]': {
    background: '$clear',
    zIndex: '1',
  },
  '&[aria-selected="false"]': {
    cursor: 'pointer',
    background: '#edeef4',
    zIndex: '0',
  },
  '@bp5': {
    padding: '15px 31px',
    fontSize: '$size16',
  },
});

const Tab: React.FC<TabsTriggerProps> = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};

export default Tab;
