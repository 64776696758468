// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState } from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import Image from './../../atoms/Image/Image';
import paypal from './../../../images/paypal_logo.png';
import payoneer from './../../../images/payoneer_logo.png';

export interface Props extends ThemeProps {
  type: 'paypal' | 'payoneer';
  handler: (value: string) => void;
}

const ButtonElement = styled('button', {
  cursor: 'pointer',
  backgroundColor: '$clear4',
  borderColor: '$black',
  color: '$black',
  border: '2px solid #9FA1A8',
  lineHeight: '45px',
  marginRight: '12px',
  marginBottom: '5px',
  padding: '16.5px 20px',
  variants: {
    selected: {
      true: {
        backgroundColor: '$clear4',
        borderColor: '$black',
      },
      false: {
        img: {
          opacity: 0.3,
        },
      },
    },
  },
  '@bp1': {
    marginBottom: 0,
  },
});

export const PaymentButton: React.FC<Props> = ({
  handler,
  type = '',
  ...props
}) => {
  const [payment, setPayment] = useState(type);

  const clickHandler = (type): void => {
    setPayment(type);
    handler?.(type);
  };

  return (
    <div {...props}>
      <ButtonElement
        selected={type === 'paypal' || payment === 'paypal'}
        onClick={() => {
          clickHandler('paypal');
        }}
        css={{ width: '180px', height: '81px' }}
      >
        <Image
          src={paypal as unknown as string}
          alt="Payoneer"
          css={{
            height: '100%',
            span: {
              paddingTop: '40%',
            },
            img: { transform: 'translateY(4px)' },
          }}
        />
      </ButtonElement>

      <ButtonElement
        selected={type === 'payoneer' || payment === 'payoneer'}
        onClick={() => {
          clickHandler('payoneer');
        }}
        css={{ width: '180px', height: '81px' }}
      >
        <Image
          src={payoneer as unknown as string}
          alt="Payoneer"
          css={{
            height: '100%',
            span: {
              paddingTop: '40%',
            },
          }}
        />
      </ButtonElement>
    </div>
  );
};
