// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import {
  Bar,
  Line,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  LineChart,
  ResponsiveContainer,
} from 'recharts';
import Dayjs from 'dayjs';
import { styled } from './../../../theme/index';
import moment from 'moment';

export interface GraphItem {
  name: string;
  sales: number;
  clicks: number;
  orders: number;
  payouts: number;
}

interface Props {
  data: GraphItem[];
  type: string;
}

const Container = styled('div', {
  fontSize: '11px',
  fontFamily: '$body',
  '*, *:before, *:after': {
    outline: 'none !important',
  },
});

const TooltipWrapper = styled('div', {
  width: '120px',
  background: 'rgb(249,249,249)',
  border: '1px solid #008080',
  padding: '4px',
  fontSize: '12px',
  fontWeight: '$bold',
  textTransform: 'capitalize',
  boxShadow: '$shadow',
  overflow: 'hidden',
});

const Div = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'none',
});

const TooltipLabel = styled('span', {
  whiteSpace: 'nowrap',
  fontFamily: '$body',
  variants: {
    type: {
      clicks: {
        color: '#808080',
      },
      sales: {
        color: '#008080',
      },
      payouts: {
        color: '#800080',
      },
      orders: {
        color: '#a9a9a9',
      },
    },
  },
});

const roundUp = (number): number => {
  // return Math.ceil(number / 100) * 100
  return Math.ceil((parseFloat(number) + 1) / 10) * 10;
};

const formatLabel = (label): string => {
  const regex = /([0-9]+(\/[0-9]+)+)@([0-9]+(\/[0-9]+)+)/i;

  if (regex.test(label)) {
    return label.replaceAll('/', '-').replace('@', ' - ');
  } else if (moment(label, 'YYYY-MM-DD', true).isValid()) {
    return Dayjs(label).format('MMM, DD');
  }
  return label;
};

const BoxTooltip = ({
  active,
  payload,
  label,
}: {
  active: boolean;
  payload: any[];
  label: string;
}): Element | null => {
  if (active && payload?.length > 0) {
    return (
      <TooltipWrapper>
        <Div>
          <TooltipLabel>{label}</TooltipLabel>
          {payload.map((item, index) => (
            <TooltipLabel
              type={item.name}
              key={`tooltip-${index}`}
            >
              ● {item.name}:{' '}
              {item.name.includes('sales') === true ||
              item.name.includes('payouts') === true
                ? `$${item.value}`
                : item.value}
            </TooltipLabel>
          ))}
        </Div>
      </TooltipWrapper>
    );
  }

  return null;
};

const Graph: React.FC<Props> = ({ type = '', data }) => {
  const maxValue = { sales: 0, clicks: 0, orders: 0, payouts: 0 };

  const DataFormater = (number): string => {
    const roundNumber = Math.ceil(number);
    if (roundNumber > 1000000000) {
      return Math.ceil(roundNumber / 1000000000).toString() + 'B';
    } else if (roundNumber > 1000000) {
      return Math.ceil(roundNumber / 1000000).toString() + 'M';
    } else if (roundNumber > 1000) {
      return Math.ceil(roundNumber / 1000).toString() + 'K';
    } else {
      return roundNumber.toString();
    }
  };

  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item.sales > maxValue.sales) {
        maxValue.sales = item.sales;
      }
      if (item.clicks > maxValue.clicks) {
        maxValue.clicks = item.clicks;
      }
      if (type !== 'conversion') {
        if (item.orders > maxValue.orders) {
          maxValue.orders = item.orders;
        }
      }
      if (item.payouts > maxValue.payouts) {
        maxValue.payouts = item.payouts;
      }
    }
  }

  const mainYaxis = Object.keys(maxValue).reduce((a, b) =>
    maxValue[a] > maxValue[b] ? a : b,
  );

  return (
    <Container>
      <div style={{ width: '100%', height: 668 }}>
        <ResponsiveContainer>
          <LineChart
            width={500}
            height={668}
            data={data}
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <Tooltip
              content={BoxTooltip}
              cursor={{ stroke: '#ebf4ff', strokeWidth: '15%' }}
            />
            <CartesianGrid vertical={false} />

            <Line
              type="monotone"
              dataKey="sales"
              dot={{ strokeWidth: 2 }}
              strokeWidth={2}
              activeDot={{
                stroke: '#008080',
                fill: '#ffffff',
                strokeWidth: 4,
                r: 5,
              }}
              stroke="#008080"
              fill="#008080"
              yAxisId={mainYaxis === 'sales' ? undefined : 'rightSales'}
            />
            <YAxis
              width={35}
              tickCount={10}
              interval="preserveStartEnd"
              domain={[0, roundUp(maxValue.sales)]}
              tickLine={false}
              axisLine={false}
              yAxisId={mainYaxis === 'sales' ? undefined : 'rightSales'}
              dataKey="sales"
              orientation="right"
              stroke="#008080"
              tickFormatter={DataFormater}
            />

            <Line
              type="monotone"
              dataKey="clicks"
              dot={{ strokeWidth: 2 }}
              strokeWidth={2}
              activeDot={{
                stroke: '#808080',
                fill: '#ffffff',
                strokeWidth: 4,
                r: 5,
              }}
              stroke="#808080"
              fill="#808080"
              yAxisId={mainYaxis === 'clicks' ? undefined : 'leftClick'}
            />
            <YAxis
              width={35}
              tickCount={10}
              interval="preserveStartEnd"
              domain={[0, roundUp(maxValue.clicks)]}
              tickLine={false}
              axisLine={false}
              yAxisId={mainYaxis === 'clicks' ? undefined : 'leftClick'}
              dataKey="clicks"
              stroke="#808080"
            />

            {type !== 'conversion' && (
              <>
                <Line
                  type="monotone"
                  dataKey="orders"
                  dot={{ strokeWidth: 2 }}
                  strokeWidth={2}
                  activeDot={{
                    stroke: '#a9a9a9',
                    fill: '#ffffff',
                    strokeWidth: 4,
                    r: 5,
                  }}
                  stroke="#a9a9a9"
                  fill="#a9a9a9"
                  yAxisId={mainYaxis === 'orders' ? undefined : 'rightOrders'}
                />
                <YAxis
                  width={35}
                  tickCount={10}
                  interval="preserveStartEnd"
                  domain={[0, roundUp(maxValue.orders)]}
                  tickLine={false}
                  axisLine={false}
                  yAxisId={mainYaxis === 'orders' ? undefined : 'rightOrders'}
                  dataKey="orders"
                  orientation="right"
                  stroke="#a9a9a9"
                />
              </>
            )}

            <Line
              type="monotone"
              dataKey="payouts"
              dot={{ strokeWidth: 2 }}
              strokeWidth={2}
              activeDot={{
                stroke: '#800080',
                fill: '#ffffff',
                strokeWidth: 4,
                r: 5,
              }}
              stroke="#800080"
              fill="#800080"
              yAxisId={mainYaxis === 'payouts' ? undefined : 'leftPayouts'}
            />
            <YAxis
              width={35}
              tickCount={10}
              interval="preserveStartEnd"
              domain={[0, roundUp(maxValue.payouts)]}
              tickLine={false}
              axisLine={false}
              yAxisId={mainYaxis === 'payouts' ? undefined : 'leftPayouts'}
              dataKey="payouts"
              stroke="#800080"
            />

            <XAxis
              dataKey="name"
              tickFormatter={formatLabel}
            />

            <Bar
              dataKey="pv"
              barSize={0}
              fill="transparent"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Container>
  );
};

export default Graph;
