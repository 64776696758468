export interface MultiSelectItem {
  id: number;
  name: string;
}

export interface MultiSelectFilterProps {
  items: MultiSelectItem[];
  selectedItems: MultiSelectItem[];
  onSelectionChange: (selected: MultiSelectItem[]) => void;
  label?: string;
  breakpoint?: string;
  tagLayout?: TagListLayout;
}

export interface MultiSelectFilterDropdownProps {
  items: MultiSelectItem[];
  selectedItems: MultiSelectItem[];
  onSelectionChange: (items: MultiSelectItem[]) => void;
  label: string;
}

export interface MultiSelectFilterTagsProps {
  items: MultiSelectItem[];
  selectedItems: MultiSelectItem[];
  handleRemoveTag: (item: MultiSelectItem) => void;
  layoutClass: string;
}

export enum TagListLayout {
  Carousel = 'carousel',
  Wrap = 'wrap',
}

export interface MultiSelectItem {
  id: number;
  name: string;
}

export interface MultiSelectFilterTagsProps {
  selectedItems: MultiSelectItem[];
  handleRemoveTag: (item: MultiSelectItem) => void;
  layoutClass: string;
}
