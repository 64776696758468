// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { styled } from './../../../theme/index';
import Password from './../../molecules/Password/Password';
import { Button } from '../../atoms/Button/Button';
import Tooltips from './../../atoms/Tooltip/Tooltips';
import client from '../../api';
import axios from 'axios';
import Header from './../../molecules/Header/Header';
import { menuLinks } from './../../../theme/menuLinks';
import HeroBanner from '../../atoms/HeroBanner/HeroBanner';
import Footer from './../../organisms/Footer/Footer';
import LoadingOverlay from '../../atoms/LoadingOverlay/LoadingOverlay';
import Text from '../../atoms/Text/Text';
import Link from './../../atoms/Link/Link';

const ENV = process.env.REACT_APP_ENV;
let baseURL = '';
if (ENV === 'production') {
  baseURL = process.env.REACT_APP_PROD_DOMAIN;
} else if (ENV === 'stage') {
  baseURL = process.env.REACT_APP_STAGE_DOMAIN;
} else if (ENV === 'local') {
  baseURL = process.env.REACT_APP_LOCAL_DOMAIN;
}

const Container = styled('div', {
  margin: 'auto',
  width: '100%',
  textAlign: 'center',
});

// const Form = styled('form', {
//   width: '45%',
//   margin: '0 auto',
//
//   div: {
//     width: '100%',
//     padding: '0.4rem 0px 0px 2rem'
//   }
// })

const ContentForm = styled('div', {
  position: 'relative',
  zIndex: 1,
  marginTop: 0,
  padding: '16px 15px 98px',
  form: {
    backgroundColor: '$clear',
  },
  '@bp2': {
    marginTop: '-64px',
    paddingTop: 0,
  },
});

// const SaveButton = styled(Button, {
//   backgroundColor: '#595F7B',
//   color: '$clear',
//   textTransform: 'none',
//   padding: '15px 22px',
//   fontFamily: '$heading',
//   fontWeight: '$bold',
//   lineHeight: 1,
//   letterSpacing: '0.8px',
//   width: 'auto',
//   '&:hover': {
//     backgroundColor: '#484d66'
//   }
// })

// const ButtonSection = styled('div', {
//   display: 'flex'
// })

const Error = styled('div', {
  fontFamily: '$label',
  color: '#a94442',
  border: '1px solid #ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  padding: '15px 30px',
  textAlign: 'center',
  fontSize: '$size16',
});
const errors = {
  missingNewPassword: 'You must confirm the password.',
  mismatchPasswords: 'Passwords Do not match',
  passwordLessThanSixChars: 'Password must be at least 6 characters long.',
  mustEnterValidPasswords: 'You must enter valid passwords.',
};

const Success = styled('div', {
  fontFamily: '$label',
  color: '#325e1c',
  border: '1px solid #ebccd1',
  borderColor: '#c9e1bd',
  backgroundColor: '#f3faef',
  borderRadius: '2px',
  padding: '15px 30px',
  textAlign: 'center',
  fontSize: '$size16',
});

const Title = styled(Text, {
  color: '$darkgray',
  margin: '0 auto',
  fontSize: '24px',
  fontWeight: '$bold',
  textAlign: 'center',
  letterSpacing: '.5px',
  fontFamily: '$heading',
  textTransform: 'uppercase',
  paddingTop: '5px',
});

const Card = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '710px',
  padding: '25px 25px 50px',
  boxShadow: '0 1px 4px 0 rgb(0 0 0 / 50%)',
  margin: '0 auto 28px',
});

const Body = styled('div', {
  width: '100%',
  maxWidth: '475px',
  margin: '0 auto',
});

const UserEditForgotPass: React.FC = () => {
  const { uid, dateExpire, resetToken } = useParams();
  const [username, setUsername] = useState('');
  const [errorMessages, setErrorMessages] = useState('');
  const [successMessages, setSuccessMessages] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [confirmValue, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [alreadyUsed, setAlreadyUsed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    void client
      .post(
        '/api/user/validateForgotPasswordUser?_format=json',
        {
          uid,
          dateExpire,
          resetToken,
        },
        {
          headers: { 'Content-type': 'application/x-www-form-urlencoded' },
        },
      )
      .then((response) => {
        console.log('response', response);
        if (response.status === 226) {
          setAlreadyUsed(true);
        } else {
          setUsername(response.data.userName);
        }
      })
      .catch(() => {
        // console.log("error", response);
        setErrorMessages(
          'Error happen during validation. Please contact support.',
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSubmit = (event: {
    preventDefault: () => void;
    target: any[];
  }): void => {
    event.preventDefault();
    setLoading(true);
    setSuccessMessages('');
    setErrorMessages('');

    const newPass = passwordValue ?? '';
    const confirmPass = confirmValue ?? '';

    if (newPass === '' && confirmPass === '') {
      setErrorMessages(errors.mustEnterValidPasswords);
      setLoading(false);
      return null;
    }
    if (newPass !== confirmPass) {
      setErrorMessages(errors.mismatchPasswords);
      setLoading(false);
      return null;
    }
    if (newPass.length < 6) {
      setErrorMessages(errors.passwordLessThanSixChars);
      setLoading(false);
      return null;
    }

    void axios
      .post(
        baseURL + '/api/user/forgotPass/save?_format=json',
        {
          uid,
          dateExpire,
          resetToken,
          passwordValue,
          confirmValue,
          forgotPass: true,
        },
        {
          headers: { 'Content-type': 'application/x-www-form-urlencoded' },
        },
      )
      .then((response) => {
        setSuccessMessages('New password saved. Please login.');
        setTimeout(function () {
          window.location.href = '/';
        }, 3000);
      })
      .catch((e) => {
        console.log('while saving user: ', e);
        if (e.response.data.message !== undefined) {
          setErrorMessages(e.response.data.message);
        }
      });
  };

  return (
    <Container>
      <Header
        navCollection={menuLinks}
        isScroll={false}
      />
      <HeroBanner title="" />

      {loading && <LoadingOverlay />}

      <ContentForm>
        <Card>
          <Body className="form-body">
            <Title>SET NEW PASSWORD</Title>

            {alreadyUsed && (
              <Error>Link already used. Please request new link.</Error>
            )}

            {!alreadyUsed && (
              <div>
                {errorMessages !== '' ? <Error>{errorMessages}</Error> : ''}
                {successMessages !== '' ? (
                  <Success>{successMessages}</Success>
                ) : (
                  ''
                )}

                <div style={{ padding: '39px 0 20px' }}>
                  <Tooltips content="To change the current user password, enter the new password in both fields.">
                    <Password
                      confirmPassword={confirmValue}
                      password={passwordValue}
                      setConfirmPassword={setConfirmPassword}
                      setPassword={setPasswordValue}
                    />
                  </Tooltips>
                </div>
                <div style={{ padding: '44px 0 10px' }}>
                  <Button
                    type="login"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </Body>
        </Card>

        <Text
          className="view-cta"
          css={{ textAlign: 'center' }}
        >
          <Link
            onClick={() => {
              navigate('/login');
            }}
            type={'fourth'}
            css={{ textDecoration: 'underline', display: 'inline-block' }}
          >
            Go to login
          </Link>
        </Text>
      </ContentForm>

      <Footer isLogin={false} />
    </Container>
  );
};

export default UserEditForgotPass;
