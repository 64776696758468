import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import DealsList from './DealsList';
import { type DealsProps, Deal } from 'src/types/dealTypes';
import ErrorMessage from 'src/components/atoms/ErrorMessage/ErrorMessage';
import MultiSelectFilter from 'src/components/atoms/MultiSelectFilter/MultiSelectFilter';
import { type MultiSelectItem as Brand } from 'src/types/formTypes';
import BaseButton from 'src/components/atoms/BaseButton/BaseButton';
import { BaseButtonVariant } from 'src/types/baseComponentTypes';
import { ReactComponent as IconFeedback } from 'src/icons/iconStarEmpty.svg';
import {
  default as client,
  addCamelCaseRespInterceptor,
  ejectCamelCaseRespInterceptor,
} from 'src/components/api';
import { UserContext } from 'src/context/UserContext';
import 'src/components/templates/Deals/deals.scss';

const defaultSelectedBrands = [
  "Kohl's",
  'Walmart',
  'lululemon',
  'Kate Spade Outlet',
  'Target',
];

const Deals: React.FC<DealsProps> = () => {
  const { userCredentials } = useContext(UserContext);
  const { password = '', username = '', csrf_token = '' } = userCredentials;
  const [dealsListData, setDealsListData] = useState<Deal[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<Brand[]>([]);
  const [errorMessages, setErrorMessages] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // username and password are coming async from local storage therefore it's a race condition
    if (username.length === 0 || password.length === 0) return;

    const fetchDeals = async (retries = 3) => {
      const interceptorId = addCamelCaseRespInterceptor(client);

      try {
        const { data } = await client.get<Deal[]>('/api/deals?_format=json', {
          headers: { csrf_token },
          auth: { username, password },
        });

        const uniqueDeals = data.filter(
          (deal, index, self) =>
            index === self.findIndex((d) => d.rowNum === deal.rowNum),
        );
        setDealsListData(uniqueDeals);
        setErrorMessages(false);
      } catch (error) {
        if (retries > 0) {
          fetchDeals(retries - 1);
        } else {
          setErrorMessages(true);
          console.error('Error fetching deals:', error);
        }
      } finally {
        ejectCamelCaseRespInterceptor(client, interceptorId);
        setLoading(false);
      }
    };

    fetchDeals();
  }, [csrf_token, password, username]);

  const brands = useMemo(() => {
    const brandSet = new Set<string>();
    return dealsListData
      .filter((deal) => {
        const key = deal.brandName.toLowerCase();
        if (brandSet.has(key)) return false;
        brandSet.add(key);
        return true;
      })
      .map((deal) => ({ id: deal.rowNum, name: deal.brandName }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [dealsListData]);

  useEffect(() => {
    const filteredBrandsList = brands.filter((brand) =>
      defaultSelectedBrands.includes(brand.name),
    );
    setSelectedBrands(filteredBrandsList);
  }, [brands]);

  const handleSelectionChange = useCallback((selected: Brand[]) => {
    setSelectedBrands(selected);
  }, []);

  const filteredDeals = useMemo(() => {
    return selectedBrands.length
      ? dealsListData.filter((deal) =>
          selectedBrands.some((brand) => brand.name === deal.brandName),
        )
      : [];
  }, [selectedBrands, dealsListData]);

  const handleSelectFeaturedBrands = () => {
    const filteredBrandsList = brands.filter((brand) =>
      defaultSelectedBrands.includes(brand.name),
    );
    setSelectedBrands(filteredBrandsList);
  };

  return (
    <section
      className={`deals ${filteredDeals.length === 0 ? 'deals--empty' : ''}`}
    >
      <div className="deals__title">
        <h2 className="deals__titleText">
          Trending&nbsp;<span className="deals__titleTextBeta">beta</span>
        </h2>
        <BaseButton
          className="deals__feedbackButton"
          href="/contact-support"
          text="Give Feedback"
          icon={IconFeedback}
          variant={BaseButtonVariant.Black}
        />
      </div>
      <div className="deals__subtitle">
        <p className="deals__subtitleText">
          Discover trending products and best-sellers to share. Pick your
          favorites, grab your link, and start earning!
        </p>
      </div>
      <div className="deals__filters">
        {errorMessages && <ErrorMessage message="Error fetching deals" />}
        <label className="deals__filtersLabel">Filter by brand</label>
        <MultiSelectFilter
          items={brands}
          selectedItems={selectedBrands}
          onSelectionChange={handleSelectionChange}
          label="brand"
          breakpoint="1300px"
        />
      </div>
      <DealsList
        className="deals__dealsList"
        dealsList={filteredDeals}
      />
      {!loading && filteredDeals.length === 0 && (
        <div className="deals__emptyGrid">
          <p className="deals__emptyGridText">
            No brands selected. Explore our featured brands to find great deals.
          </p>
          <BaseButton
            onClick={handleSelectFeaturedBrands}
            className="deals__emptyGridButton"
            text="Featured Brands"
          />
        </div>
      )}
    </section>
  );
};

export default Deals;
