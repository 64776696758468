import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import Text from './../../atoms/Text/Text';
import Image from './../../atoms/Image/Image';
import Download from './../../../images/download-on-the-app-store-badge.png';

const Container = styled('div', {
  display: 'block',
  maxWidth: '100%',
});

const lH = { lineHeight: 1.562 };

const Paragraph = styled(Text, {
  marginBottom: '10px',
  letterSpacing: '-0.2px',
  ...lH,
});

const Heading = styled('div', {
  fontFamily: '$heading',
  fontSize: '18px',
  fontWeight: '$bold',
  letterSpacing: '0.5px',
  lineHeight: 1,
  textTransform: 'uppercase',
  color: '$black',
  margin: '40px 0 20px',
});

const fontBase = {
  fontFamily: '$body',
  fontSize: '16px',
  letterSpacing: '-0.2px',
  ...lH,
};

const OrderedList = styled('ol', {
  paddingLeft: '16px',
  margin: '0 0 10px',
});

const ListElement = styled('li', { ...fontBase });

const LogoWrapper = styled('a', {
  display: 'block',
  maxWidth: '200px',
  img: {
    maxWidth: '100%',
  },
});

const Link = styled('a', {
  color: '$black',
  '&:hover': {
    color: '#275153',
  },
});

const MobileAppContent: React.FC<ThemeProps> = ({ ...props }) => {
  return (
    <Container {...props}>
      <Heading>The BrandCycle iOS App is Here!</Heading>
      <Paragraph>
        Link sharing just got easier. With the new BrandCycle app, you can
        create custom tracking links on the go, making it an even easier process
        to monetize your content.
      </Paragraph>

      <Paragraph>
        Our app also lets you build links, track reporting stats, favorite
        brands, and more!
      </Paragraph>

      <Link
        href="https://apps.apple.com/us/app/brandcycle/id1644978168"
        target="_blank"
        rel="nofollow noreferrer"
      >
        Download Our App Today!
      </Link>
      <Paragraph></Paragraph>

      <LogoWrapper
        href="https://apps.apple.com/us/app/brandcycle/id1644978168"
        target="_blank"
        rel="nofollow noreferrer"
      >
        <Image
          src={Download}
          alt="app store logo"
          aspectRatio={0.34}
        />
      </LogoWrapper>

      <Heading>How to Get Started:</Heading>

      <OrderedList>
        <ListElement>
          Download the{' '}
          <Link
            href="https://apps.apple.com/us/app/brandcycle/id1644978168"
            target="_blank"
            rel="nofollow noreferrer"
          >
            BrandCycle iOS App from the App Store.
          </Link>
        </ListElement>

        <ListElement>
          Sign in with your BrandCycle username and password.
        </ListElement>

        <ListElement>
          Start sharing your favorite brands and earning commissions!
        </ListElement>
      </OrderedList>

      <Paragraph css={{ fontStyle: 'italic' }}>Come Grow With Us!</Paragraph>
    </Container>
  );
};

export default MobileAppContent;
