import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';

interface Props extends ThemeProps {
  label: string;
  value: string;
  inline?: boolean;
  bmkFlag?: boolean;
}

const Container = styled('div', {
  variants: {
    inline: {
      true: {
        display: 'grid',
        alignItems: 'end',
        gridTemplateColumns: '48.6% 1fr',
        gridGap: '16px',
      },
      false: {
        textAlign: 'center',
      },
    },
  },
});
const ContainerBMK = styled('div', {
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '48.6% 1fr',
  gridGap: '16px',
  textAlign: 'start',
});

const Label = styled('span', {
  color: '$blue3',
  fontFamily: '$deco',
  letterSpacing: '0.31px',
  fontWeight: 'normal',
  display: 'block',
  variants: {
    inline: {
      true: {
        fontSize: '20px',
        lineHeight: 1.5,
      },
      false: {
        paddingBottom: '27px',
        fontSize: '20px',
        lineHeight: 0.85,
        '@bp1': {
          paddingBottom: '27px',
        },
        '@bp2': {
          paddingBottom: '28px',
        },
        '@bp3': {
          fontSize: '25px',
          paddingBottom: '24px',
          lineHeight: 0.96,
        },
      },
    },
  },
});
const LabelBMK = styled('span', {
  color: '$blue3',
  fontFamily: '$deco',
  letterSpacing: '0.31px',
  fontWeight: 'normal',
  display: 'block',
  variants: {
    inline: {
      true: {
        fontSize: '20px',
        lineHeight: 1.5,
      },
      false: {
        paddingBottom: '27px',
        fontSize: '20px',
        lineHeight: 0.85,
        '@bp1': {
          paddingBottom: '27px',
        },
        '@bp2': {
          paddingBottom: '28px',
        },
        '@bp3': {
          fontSize: '25px',
          paddingBottom: '24px',
          lineHeight: 0.96,
        },
      },
    },
  },
});

const Value = styled('span', {
  fontFamily: '$quote',
  color: '$black',
  fontWeight: 'normal',
  display: 'block',
  variants: {
    inline: {
      true: {
        fontSize: '13px',
        lineHeight: 1,
      },
      false: {
        letterSpacing: '1px',
        lineHeight: 0.78,
        fontSize: '$size26',
        '@bp4': {
          fontSize: '33px',
          lineHeight: 0.96,
        },
      },
    },
  },
});

const HighlightStat: React.FC<Props> = ({
  inline = false,
  label,
  value,
  bmkFlag = false,
  ...props
}) => {
  if (bmkFlag) {
    return (
      <ContainerBMK>
        <LabelBMK inline={true}>{label}</LabelBMK>
        <Value inline={true}>
          {value?.includes('%') ? value + ' Of Sale' : value}
        </Value>
      </ContainerBMK>
    );
  }

  return (
    <Container
      inline={inline}
      {...props}
    >
      <Label inline={inline}>{label}</Label>
      <Value inline={inline}>{value}</Value>
    </Container>
  );
};

export default HighlightStat;
