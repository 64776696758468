// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useId } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpDownLeftRight } from '@fortawesome/free-solid-svg-icons';
import { styled } from './../../../theme/index';
import Input from './../../atoms/Input/Input';

export interface Item {
  id: number;
  value: string;
  order: number;
}

interface Props {
  item: Item;
  editWeight: boolean;
  onWeightChange: (id: number, value: number) => void;
  onChangeText: (id: number, value: string) => void;
  lengthOptions: number[];
}

const Row = styled('div', {
  display: 'grid',
  gridGap: '16px',
  gridTemplateColumns: '30px 1fr',
  alignItems: 'center',
  padding: '8px',
  borderTop: '1px solid $gray4',
  variants: {
    even: {
      true: {
        backgroundColor: '$clear4',
      },
      false: {
        backgroundColor: '$clear',
      },
    },
    order: {
      true: {
        gridTemplateColumns: '30px 1fr minmax(60px, 11%)',
      },
    },
  },
});

const Handle = styled('div', {
  paddingLeft: '8px',
  cursor: 'grab',
  opacity: 0.5,
  '&:hover': {
    opacity: 1,
  },
});

const Select = styled('select', {
  background: '$clear',
  border: '1px solid',
  borderColor: '$darkgray',
});

const DragDropItem: React.FC<Props> = ({
  editWeight,
  item,
  lengthOptions,
  onWeightChange,
  onChangeText,
}) => {
  const id = useId();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: item?.id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const onChange = (e): void => {
    onWeightChange(item.id, e.target.value);
  };

  const updateText = (e): void => {
    onChangeText(item.id, e.target.value);
  };

  return (
    <Row
      ref={setNodeRef}
      style={style}
      order={editWeight}
      even={item.order % 2 === 0}
    >
      <Handle>
        {!editWeight && (
          <span
            {...listeners}
            {...attributes}
          >
            <FontAwesomeIcon icon={faUpDownLeftRight} />
          </span>
        )}
      </Handle>
      <Input
        type="text"
        view="secondary"
        value={item.value}
        onChange={updateText}
        css={{
          input: {
            fontSize: '12px',
            height: '29px',
          },
        }}
      />
      {editWeight && (
        <div style={{ alignSelf: 'flex-start' }}>
          <Select
            defaultValue={item.order}
            onChange={onChange}
          >
            {lengthOptions.map((option) => (
              <option
                key={`${id}-weight-${option}`}
                value={option}
              >
                {option}
              </option>
            ))}
          </Select>
        </div>
      )}
    </Row>
  );
};

export default DragDropItem;
