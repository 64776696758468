// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useEffect, useState } from 'react';
import DeepLinkingBoxMBK from './molecules/DeepLinkingBoxMBK/DeepLinkingBoxMBK';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from './atoms/Button/Button';

import { styled } from '../theme/index';
import Heading from './atoms/Heading/Heading';
import SliderCard from './SliderCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { Navigation, Mousewheel, Keyboard } from 'swiper';
import ImageOverlay from './atoms/LoadingOverlay/ImageOverlay';

import 'swiper/swiper.min.css';

const Head = styled(Heading, {
  marginBottom: '36px',
  fontFamily: '$heading',
  fontSize: '$size18',
  fontWeight: '$bold',
  letterSpacing: '0.5px',
  lineHeight: 1,
  '@bp5': {
    marginBottom: '10px',
  },
});

const MediaShare = styled('div', {
  display: 'grid',
  gridTemplateColumns: '17% 10% 10% 1fr',
  gridGap: '0',
});

const ControlledCarousel: React.FC<Props> = ({
  shortUrl,
  longUrl = null,
  showLoad,
  ...props
}): Element => {
  const imageList = props.imagesList;
  const [selected, setSelected] = useState(0);
  const [imageOverlay, setImageOverlay] = useState(null);
  const imageHtml =
    '<a href="[long_url]" rel="nofollow" >' +
    '  <img src="[img_link]" alt="[img_alt]" />' +
    '</a>' +
    '<img src=""[long_url]"" width="0" height="0" style="position:absolute;visibility:hidden;" border="0" />';

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [longUrl]);

  const handleMagnifyClick = (image): void => {
    setImageOverlay(image);
  };

  const handleCloseClick = (): void => {
    setImageOverlay(null);
  };

  return (
    <section>
      {imageOverlay !== null && (
        <ImageOverlay
          showImage={imageOverlay}
          handleCloseClick={handleCloseClick}
        />
      )}

      <Head>Build an image link</Head>

      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        freeMode={true}
        navigation={true}
        className="mySwiper"
        modules={[Navigation, Mousewheel, Keyboard]}
        onClick={(item) => {
          showLoad(true);
          setSelected(item.clickedIndex);
          setTimeout(function () {
            showLoad(false);
          }, 2000);
        }}
      >
        {props.imagesList.map((item, index) => (
          <SwiperSlide
            key={index}
            id={index}
          >
            <SliderCard
              image={item.url}
              handleMagnifyClick={handleMagnifyClick}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <section>
        <DeepLinkingBoxMBK
          title="Full Link"
          value={imageHtml
            .replaceAll('[long_url]', longUrl)
            .replaceAll('[img_link]', imageList[selected].url)
            .replaceAll('[img_alt]', imageList[selected].alt)}
          style={{ marginTop: '2rem' }}
        />

        <MediaShare>
          <p>Share Link:</p>

          {/* <a href={`https://twitter.com/intent/tweet?text=${shortUrl}`} target="_blank" rel="noreferrer" > */}
          <a
            href={`https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3D${encodeURIComponent(
              shortUrl,
            )}%26amp%253Bsrc%3Dsdkpreparse&&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_US`}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              type="social"
              css={{
                color: '#ffffff',
                backgroundColor: '#3b5998',
                width: '70%',
                height: '80%',
                border: 'none',
              }}
            >
              <FontAwesomeIcon
                icon={{
                  icon: [
                    320,
                    512,
                    [],
                    'f39e',
                    'M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z',
                  ],
                  iconName: 'facebook-f',
                  prefix: 'fab',
                }}
              />
            </Button>
          </a>

          {/* <a href={`https://www.twitter.com/sharer/sharer.php?u=${shortUrl}`} target="_blank" rel="noreferrer" > */}
          <a
            href={`https://twitter.com/intent/post?text= - ${encodeURIComponent(
              shortUrl,
            )}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              css={{
                color: '#ffffff',
                backgroundColor: '#55acee',
                width: '70%',
                height: '80%',
                border: 'none',
              }}
              type="social"
            >
              <FontAwesomeIcon
                icon={{
                  icon: [
                    512,
                    512,
                    [],
                    'f099',
                    'M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z',
                  ],
                  iconName: 'twitter',
                  prefix: 'fab',
                }}
              />
            </Button>
          </a>
        </MediaShare>
      </section>
    </section>
  );
};

export default ControlledCarousel;
