// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useEffect } from 'react';
import { styled } from './../../../theme/index';
import {
  faAngleRight,
  faAnglesRight,
  faAngleLeft,
  faAnglesLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '../../atoms/Button/Button';
import BlogSnippet, {
  type Props as BlogSnippetProps,
} from './../../molecules/BlogSnippet/BlogSnippet';

export interface Props {
  collection: BlogSnippetProps[];
}

const Container = styled('div', {
  display: 'block',
  width: '100%',
});

const Controls = styled('div', {
  display: 'inline-flex',
  width: '100%',
  justifyContent: 'center',
  padding: '17px 0',
});

const ControlsButton = styled(Button, {
  margin: '0 8px',
  width: 'max-content',
});

const BlogSlider: React.FC<Props> = ({ collection }) => {
  const slide = collection;
  const [index, setIndex] = useState(0);
  const total = collection.length - 1;

  const handleChange = (direction): void => {
    let nextIndex = index;
    if (direction === 'next' && index < collection.length) {
      ++nextIndex;
    }
    if (direction === 'prev' && index > 0) {
      --nextIndex;
    }
    if (direction === 'last') {
      nextIndex = total;
    }
    if (direction === 'first') {
      nextIndex = 0;
    }
    setIndex(nextIndex);
  };

  const controls = [
    {
      type: 'first',
      icon: faAnglesLeft,
      condition: index > 0,
    },
    {
      type: 'prev',
      icon: faAngleLeft,
      condition: index > 0,
    },
    {
      type: 'next',
      icon: faAngleRight,
      condition: index !== total,
    },
    {
      type: 'last',
      icon: faAnglesRight,
      condition: index !== total,
    },
  ];

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  return (
    <Container>
      <BlogSnippet
        type="featured"
        featured={true}
        {...slide[index]}
      />

      <Controls>
        {controls.map((item, key) => {
          return (
            <ControlsButton
              key={`blog-banner-slider-element-${key}`}
              type="cleanIcon"
              onClick={() => {
                handleChange(item.type);
              }}
              style={!item.condition ? { display: 'none' } : {}}
            >
              <FontAwesomeIcon icon={item.icon} />
            </ControlsButton>
          );
        })}
      </Controls>
    </Container>
  );
};

export default BlogSlider;
