// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';

const Icon = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 10.77 20"
    >
      <path
        className="icon-path"
        id="Icon-Facebook"
        d="M6.923,0A3.846,3.846,0,0,0,3.077,3.846V6.154H0V9.231H3.077V20H6.154V9.231H9.615l.769-3.077H6.154V3.846a.77.77,0,0,1,.769-.769h3.846V0Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export default Icon;
