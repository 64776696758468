import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import CopyDisclaimer from './../../atoms/CopyDisclaimer/CopyDisclaimer';

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  value?: string;
}

const Container = styled('div', {
  display: 'block',
  letterSpacing: '-0.2px',
  '@bp3': {
    display: 'flex',
    alignItems: 'center',
  },
});

const Code = styled('div', {
  width: 'fit-content',
  minWidth: '280px',
  maxWidth: '100%',
  backgroundColor: '$clear2',
  border: '1px solid $green2',
  padding: '15px 50px 15px 15px',
  fontFamily: '$body',
  wordBreak: 'break-all',
  fontSize: '14px',
  color: '$green3',
  lineHeight: 1,
  fontWeight: '600',
  marginTop: '12px',
  '@bp3': {
    fontSize: '17px',
    marginTop: 0,
  },
});

const TrackingUrlOutput: React.FC<Props> = ({
  value = '',
  parentPage = '',
  ...props
}) => {
  return (
    <Container {...props}>
      <CopyDisclaimer css={{ marginRight: '20px' }}>
        Your custom tracking link is ready
      </CopyDisclaimer>
      {parentPage === '' ? (
        <Code>{value}</Code>
      ) : (
        <Code style={{ width: '66%' }}>{value}</Code>
      )}
    </Container>
  );
};

export default TrackingUrlOutput;
