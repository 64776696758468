// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useEffect, useMemo } from 'react';
import * as RadixTabs from '@radix-ui/react-tabs';
import { findKey } from 'lodash';
import { styled, type ThemeProps } from './../../../theme/index';
import Tab from './../../atoms/Tab/Tab';
import SelectInput, { type Option } from './../../atoms/Select/Select';

export interface TabProps {
  label?: string;
  content?: React.ReactNode | undefined;
}

interface Props extends ThemeProps {
  tabs: TabProps[];
  defaultTab?: number;
}

const SelectWrapper = styled('div', {
  display: 'initial',
  '@bp3': {
    display: 'none',
  },
});

const Content = styled(RadixTabs.Content);

const List = styled(RadixTabs.List, {
  display: 'none',
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    display: 'block',
    position: 'absolute',
    left: 0,
    bottom: 0,
    content: ' ',
    width: '100%',
    borderBottom: '2px solid $black',
    pointerEvents: 'none',
  },
  '@bp3': {
    display: 'flex',
  },
});

const Tabs: React.FC<Props> = ({ defaultTab = 0, tabs = [], ...props }) => {
  const [mounted, setMounted] = useState(false);
  const [value, setValue] = useState<Option | null>(null);

  const options = useMemo(
    () =>
      tabs.map((value) => ({
        label: value.label,
        value: value.label,
      })) as Option[],
    [tabs],
  );

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      setValue(options[defaultTab]);
    }
  }, [tabs, mounted, defaultTab, options]);

  const selectHandler = (data): void => {
    setValue(data);
    window.history.pushState(
      { pageTitle: data.label },
      '',
      data.label.toLowerCase().replaceAll(' ', '-'),
    );
  };

  const tabClick = (value: string): void => {
    const key = findKey(options, { value });
    if (key !== undefined) {
      setValue(options[key]);
      window.history.pushState(
        { pageTitle: options[key] },
        '',
        options[key].value
          .toLowerCase()
          .replaceAll(' ', '-')
          .replace('-report', ''),
      );
    }
  };

  return (
    <div>
      <RadixTabs.Root
        activationMode="manual"
        defaultValue={options[defaultTab].value}
        value={value?.value}
        onValueChange={tabClick}
      >
        <List aria-label="make-it-prop">
          {tabs.map((tab, index) => (
            <Tab
              key={`trigger-${index}`}
              value={tab.label as string}
            >
              {tab.label}
            </Tab>
          ))}
        </List>
        <SelectWrapper>
          <SelectInput
            options={options}
            value={value}
            setValue={selectHandler}
          />
        </SelectWrapper>
        {tabs.map((tab, index) => (
          <Content
            key={`content-${index}`}
            value={tab.label as string}
            {...props}
          >
            <div>{tab.content}</div>
          </Content>
        ))}
      </RadixTabs.Root>
    </div>
  );
};

export default Tabs;
