// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import Text from '../../atoms/Text/Text';
import useCopy from '../../../hook/useCopy/useCopy';
import { Button } from '../../atoms/Button/Button';
import CopyDisclaimer from '../../atoms/CopyDisclaimer/CopyDisclaimer';
// import CopyDisclaimer from './../../atoms/CopyDisclaimer/CopyDisclaimer'
import { FullStory } from '@fullstory/browser';

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  value: string;
}

const Container = styled('div', {
  display: 'grid',
  letterSpacing: '-0.2px',
  gridTemplateRows: 'fit-content fit-content',
});

const Label = styled(Text, {
  fontSize: '$size15',
  fontWeight: '$bold',
});

const LinkSection = styled('section', {
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  justifyContent: 'start',
  gridGap: '1rem',
});

const YourLink = styled('input', {
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '12px',
  fontSize: '$size15',
  fontFamily: '$body',
  fontWeight: '$bold',
  lineHeight: 1.37,
  border: '1px solid $blue7',
  borderRadius: '1px',
  width: '100%',
  maxWidth: '100%',
  // maxWidth: '275px',
  minHeight: '44px',
  maxHeight: 'fit-content',
  margin: '10px 0',
  color: '#666',
});

let oldValue = '';

const DeepLinkingBoxMBK: React.FC<Props> = ({
  title,
  value,
  parentPage = '',
  ...props
}) => {
  const [copyLink, setCopyLink] = useState(false);
  const [copyAction] = useCopy();

  useEffect(() => {
    if (oldValue !== value) {
      setCopyLink(false);
      oldValue = value;
    }
  }, [value]);

  // FS set events for clicking on copy
  const setFSClick = (value): void => {
    if (value.indexOf('brandcycle.shop') !== -1) {
      void FullStory('trackEvent', {
        name: 'bookmarklet short-link copy',
      });
    } else if (value.indexOf('brandcycle.go2cloud.org') !== -1) {
      void FullStory('trackEvent', {
        name: 'bookmarklet full-link copy',
      });
    }
  };

  return (
    <Container {...props}>
      <Label>{title}</Label>
      <LinkSection>
        <YourLink
          type="text"
          value={value}
          readOnly={true}
        ></YourLink>
        <Button
          type="action"
          onClick={() => {
            copyAction(value);
            setCopyLink(true);
            setFSClick(value);
          }}
          style={{ marginTop: '10px' }}
        >
          COPY THIS LINK
          {copyLink && (
            <CopyDisclaimer
              css={{
                marginTop: '0.3rem',
              }}
            >
              Link Copied
            </CopyDisclaimer>
          )}
        </Button>
      </LinkSection>
    </Container>
  );
};

export default DeepLinkingBoxMBK;
