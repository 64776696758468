// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import client from '../components/api';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function Logout() {
  const { userCredentials } = useContext(UserContext);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token, logout_token } = userCredentials;
  // useEffect(() => {
  if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
    window.localStorage.removeItem('userInfo');
    window.localStorage.removeItem('user_credit');
    window.localStorage.removeItem('level');
    window.localStorage.removeItem('menus');
    window.localStorage.removeItem('forgotPass');

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    void client
      .get(`/logout?_format=json&token=${logout_token}`, {
        headers: { csrf_token },
        auth: { username, password },
      })
      .then(() => {
        window.location.href = '/';
      })
      .catch((e) => {
        console.log('error', e);
      });
  }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [password, username, csrf_token, logout_token]);
  //
  // return;
}

export default Logout;
