import React from 'react';
import { styled } from './../../../theme/index';

interface Props {
  value: string;
  label: string;
}

const Container = styled('div', {
  display: 'block',
  textAlign: 'center',
});

const Value = styled('span', {
  display: 'block',
  width: '100%',
  fontSize: '18px',
  lineHeight: 1.055,
  letterSpacing: '1.45px',
  fontFamily: '$quote',
  fontWeight: 'normal',
  paddingBottom: '20px',
  color: '$darkgray',
  '@bp2': {
    lineHeight: 1.77,
  },
  '@bp3': {
    fontSize: '19px',
    lineHeight: 2.31,
    letterSpacing: '1.91px',
    paddingBottom: 0,
  },
  '@bp4': {
    fontSize: '29px',
    lineHeight: 1.51,
  },
  '@bp5': {
    fontSize: '24px',
    lineHeight: 1.83,
  },
  '@bp6': {
    fontSize: '37px',
    lineHeight: 1.18,
  },
});

const Label = styled('span', {
  display: 'block',
  color: '$black',
  fontSize: '13px',
  lineHeight: '1.30',
  letterSpacing: '1px',
  paddingTop: '3px',
  paddingBottom: '11px',
  fontFamily: '$body',
  '@bp2': {
    paddingTop: '13px',
  },
  '@bp3': {
    fontSize: '17px',
    lineHeight: 1,
  },
});

const NumberData: React.FC<Props> = ({ value, label }) => {
  return (
    <Container>
      <Value>{value}</Value>
      <Label>{label}</Label>
    </Container>
  );
};

export default NumberData;
