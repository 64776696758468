import React, {
  createContext,
  useEffect,
  useState,
  type ReactNode,
} from 'react';
import type {
  UserCredentialsProps,
  UserContextProps,
} from '../types/userTypes';

const initialUserCredentials: UserCredentialsProps = {
  password: '',
  username: '',
  csrf_token: '',
  logout_token: '',
  user: '',
};

export const UserContext = createContext<UserContextProps>({
  userCredentials: initialUserCredentials,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateUserCredentials: () => {},
});

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [userCredentials, setUserCredentials] = useState(
    initialUserCredentials,
  );

  const updateUserCredentials = (
    newCredentials: Partial<UserCredentialsProps>,
  ): void => {
    setUserCredentials((prevCredentials) => ({
      ...prevCredentials,
      ...newCredentials,
    }));
  };

  useEffect(() => {
    const storedUserCredentials = window.localStorage.getItem('user_credit');
    if (storedUserCredentials !== null) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { password, username, csrf_token, logout_token, user } = JSON.parse(
        storedUserCredentials,
      );
      const newCredentials = {
        password,
        username,
        csrf_token,
        logout_token,
        user,
      };
      updateUserCredentials(newCredentials);
    }
  }, []);

  return (
    <UserContext.Provider value={{ userCredentials, updateUserCredentials }}>
      {children}
    </UserContext.Provider>
  );
};
