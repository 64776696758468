import React, { type Dispatch, type SetStateAction } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faAnglesLeft,
  faAnglesRight,
} from '@fortawesome/free-solid-svg-icons';
import { styled } from './../../../theme/index';
import usePagination from '../../../hook/usePagination/usePagination';

export interface Props {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const List = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  listStyle: 'none',
  width: '100%',
});

const Item: any = styled('button', {
  border: '1px solid #9ca0b2',
  backgroundColor: '$clear',
  color: '$black2',
  margin: '0 8px 8px',
  fontFamily: '$heading',
  lineHeight: 1.1,
  textAlign: 'center',
  cursor: 'pointer',
  padding: '2px 6.5px',
  fontSize: '11px',
  span: {
    padding: '5px 0',
  },
  '@bp3': {
    fontSize: '19px',
    padding: '1px 12px',
  },
  '&:hover': {
    span: {
      borderBottom: '1px solid',
      borderColor: '#408487',
    },
  },
  variants: {
    icon: {
      true: {
        borderColor: 'transparent',
      },
    },
    current: {
      true: {
        backgroundColor: '$black',
        fontWeight: '$bold',
        border: 'none',
        color: '$clear',
        cursor: 'default',
        padding: '2px 14px',
        '&:hover span': {
          border: 'none',
        },
      },
    },
  },
});

export const Pagination: React.FC<Props> = ({
  currentPage,
  setCurrentPage,
  totalCount,
  pageSize,
}) => {
  const [paginationRange, totalPageCount] = usePagination({
    currentPage,
    totalCount,
    pageSize,
  });

  const onPageChange = (page: number): void => {
    if (page > totalPageCount) return;
    setCurrentPage(page);
  };

  const onNext = (): void => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = (): void => {
    onPageChange(currentPage - 1);
  };

  return (
    <List>
      {currentPage !== 1 && (
        <>
          <Item
            icon
            tabIndex={0}
            role="button"
            aria-label="First Page"
            onClick={() => {
              onPageChange(1);
            }}
          >
            <span>
              <FontAwesomeIcon icon={faAnglesLeft} />
            </span>
          </Item>
          <Item
            icon
            tabIndex={0}
            role="button"
            aria-label="Previous Page"
            onClick={onPrevious}
          >
            <span>
              <FontAwesomeIcon icon={faAngleLeft} />
            </span>
          </Item>
        </>
      )}
      {paginationRange.map((pageNumber) => {
        const args = {
          tabIndex: 0,
          role: 'button',
          'aria-label': `Page ${pageNumber}`,
          'aria-current': pageNumber === currentPage ? 'page' : undefined,
          current: pageNumber === currentPage,
        };
        return (
          <Item
            key={`pagination-item-${pageNumber}`}
            onClick={() => {
              onPageChange(pageNumber);
            }}
            {...args}
          >
            <span>{pageNumber}</span>
          </Item>
        );
      })}
      {currentPage < totalPageCount && (
        <>
          <Item
            icon
            tabIndex={0}
            role="button"
            aria-label="Next Page"
            onClick={onNext}
          >
            <span>
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </Item>
          <Item
            icon
            tabIndex={0}
            role="button"
            aria-label="Last Page"
            onClick={() => {
              onPageChange(totalPageCount);
            }}
          >
            <span>
              <FontAwesomeIcon icon={faAnglesRight} />
            </span>
          </Item>
        </>
      )}
    </List>
  );
};

export default Pagination;
