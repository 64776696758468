// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import { styled } from './../../../theme/index';
import Input from './../../atoms/Input/Input';
import { type CSS } from '@stitches/react';

interface Props {
  globalFilter?: string;
  setGlobalFilter: (value: string) => void;
  searchStyles?: CSS;
}

const Container = styled('label', {
  fontSize: '16px',
  fontWeight: 'bold',
  fontFamily: '$label',
  display: 'inline-flex',
  alignSelf: 'flex-end',
  alignItems: 'center',
  marginBottom: '5px',
  marginRight: '7.5px',
});

const Text = styled('span', {
  fontSize: '17px',
  margin: '0 2px 1px 0',
});

const InputField = styled(Input, {
  marginLeft: '7.5px',
});

const GlobalFilter: React.FC<Props> = ({
  globalFilter,
  setGlobalFilter,
  searchStyles,
  ...props
}) => {
  const [value, setValue] = React.useState(
    globalFilter !== undefined ? globalFilter : '',
  );

  const callback = (e): void => {
    const value = (e.target as HTMLInputElement).value;
    setValue(value);
    setGlobalFilter(value);
  };

  return (
    <Container
      css={searchStyles}
      {...props}
    >
      <Text>Search: </Text>
      <InputField
        value={value}
        onChange={callback}
        name={'search input'}
        type={'text'}
        view={'search'}
      />
    </Container>
  );
};

export default GlobalFilter;
