import React from 'react';
import { styled } from './../../../theme/index';

export interface Props {
  label: string;
  value: string;
}

const Container = styled('div', {
  display: 'block',
  fontSize: '$size16',
  fontFamily: '$body',
  fontWeight: '$regular',
});

const Text = styled('span', {
  display: 'block',
  lineHeight: 1,
});

const AccountDetail: React.FC<Props> = ({ label, value }) => {
  return (
    <Container>
      <Text>{label}:</Text>
      <Text style={{ fontWeight: '700', lineHeight: '1.375' }}>{value}</Text>
    </Container>
  );
};

export default AccountDetail;
