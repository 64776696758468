// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import { styled } from '../theme/index';
import magnifyPic from '../images/magnifier1.png';

const Card = styled('div', {
  width: '190px',
  height: '280px',
  border: '1px solid black',
  textAlign: 'center',
  padding: '0',
  margin: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Magnify = styled('div', {
  position: 'absolute',
  height: '27px',
  width: '25px',
  bottom: '0px',
  right: '0px',
  background: `url(${magnifyPic}) no-repeat 0 0`,
  marginRight: '10px',
  cursor: 'pointer',
});

/* eslint-disable react/prop-types */
const SliderCard = ({ image, handleMagnifyClick }): Element => {
  return (
    <Card>
      <img
        src={image}
        style={{ maxWidth: '150px', maxHeight: '150px' }}
      />

      <Magnify
        href={image}
        className="open"
        data-type="image"
        data-title=""
        data-width="1200"
        data-toggle="lightbox"
        data-gallery="remoteload"
        tabIndex="0"
        onClick={() => {
          handleMagnifyClick(image);
        }}
      ></Magnify>
    </Card>
  );
};

export default SliderCard;
