import { useMemo } from 'react';
import { formatDateRange } from 'src/utils/dateUtils';

const useFormattedDateRange = (
  startDateStr: string | undefined,
  endDateStr: string | undefined,
): string => {
  return useMemo(() => {
    if (!endDateStr) {
      return '';
    }
    return formatDateRange(startDateStr, endDateStr);
  }, [startDateStr, endDateStr]);
};

export default useFormattedDateRange;
