import React from 'react';
import {
  BaseButtonProps,
  BaseButtonVariant,
} from 'src/types/baseComponentTypes';
import BaseIcon from 'src/components/atoms/BaseIcon/BaseIcon';
import LoadingDots from 'src/components/atoms/LoadingDots/LoadingDots';
import 'src/components/atoms/BaseButton/baseButton.scss';

const BaseButton: React.FC<BaseButtonProps> = ({
  href,
  onClick,
  text,
  variant = BaseButtonVariant.Primary,
  icon,
  iconSize = 14,
  iconPosition = 'left',
  className,
  isDisabled = false,
  isLoading = false,
}) => {
  if (!href && !onClick) {
    console.error('baseButton requires either a url or an onClick prop.');
  }

  const Tag = href ? 'a' : 'button';
  const tagAction = href ? { href } : { onClick };

  return (
    <Tag
      {...tagAction}
      className={`baseButton ${className} baseButton--${variant} ${
        icon ? `baseButton--${iconPosition}Icon` : ''
      }`}
      {...(isDisabled ? { disabled: true } : {})}
    >
      {isLoading ? (
        <LoadingDots size={10} />
      ) : (
        icon && (
          <BaseIcon
            className="baseButton__icon"
            icon={icon}
            size={iconSize}
          />
        )
      )}
      {!isLoading ? <span className="baseButton__text">{text}</span> : null}
    </Tag>
  );
};

export default BaseButton;
