import { faker } from '@faker-js/faker';

export const data = [
  {
    name: '2023-02-20',
    sales: faker.datatype.float({ min: 0, max: 2000 }),
    clicks: faker.datatype.number({ min: 0, max: 2000 }),
    orders: faker.datatype.number({ min: 0, max: 2000 }),
    payouts: faker.datatype.float({ min: 0, max: 2000 }),
  },
  {
    name: '2023-02-21',
    sales: faker.datatype.float({ min: 0, max: 2000 }),
    clicks: faker.datatype.number({ min: 0, max: 2000 }),
    orders: faker.datatype.number({ min: 0, max: 2000 }),
    payouts: faker.datatype.float({ min: 0, max: 2000 }),
  },
  {
    name: '2023-02-22',
    sales: faker.datatype.float({ min: 0, max: 2000 }),
    clicks: faker.datatype.number({ min: 0, max: 2000 }),
    orders: faker.datatype.number({ min: 0, max: 2000 }),
    payouts: faker.datatype.float({ min: 0, max: 2000 }),
  },
  {
    name: '2023-02-23',
    sales: faker.datatype.float({ min: 0, max: 2000 }),
    clicks: faker.datatype.number({ min: 0, max: 2000 }),
    orders: faker.datatype.number({ min: 0, max: 2000 }),
    payouts: faker.datatype.float({ min: 0, max: 2000 }),
  },
];
export const options = [
  {
    value: 'free',
    label: 'Between Dates',
  },
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: 'yesterday',
    label: 'Yesterday',
  },
  {
    value: '7-days-past',
    label: 'Last 7 Days',
  },
  {
    value: 'month',
    label: 'This Month',
  },
  {
    value: 'month-1',
    label: 'Last Month',
  },
  {
    value: 'year',
    label: 'This Year',
  },
  {
    value: 'year-1',
    label: 'Last Year',
  },
];

export const lengthFilter = [
  {
    value: 'day',
    label: 'Day',
  },
  {
    value: 'week',
    label: 'Week',
  },
  {
    value: 'month',
    label: 'Month',
  },
  {
    value: 'year',
    label: 'Year',
  },
];

export const status = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'approved',
    label: 'Approved',
  },
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'rejected',
    label: 'Rejected',
  },
];

export const paidStatus = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Not Paid',
    value: 'not-paid',
  },
  {
    label: 'Paid',
    value: 'paid',
  },
];

export const offers = [
  { value: '', label: 'All' },
  { value: '1-800-Baskets.com', label: '1-800-Baskets.com' },
  { value: '1-800-Flowers.com', label: '1-800-Flowers.com' },
  { value: '1-800-PetMeds', label: '1-800-PetMeds' },
  { value: '2Modern', label: '2Modern' },
  { value: '2XIST', label: '2XIST' },
  { value: '6pm', label: '6pm' },
  { value: '7 For All Mankind', label: '7 For All Mankind' },
  { value: '24 Hour Fitness', label: '24 Hour Fitness' },
  { value: '32 Degrees', label: '32 Degrees' },
  { value: '47Brand', label: '47Brand' },
  { value: '100% Pure', label: '100% Pure' },
  { value: 'ABLE', label: 'ABLE' },
  { value: 'Academy Sports + Outdoors', label: 'Academy Sports + Outdoors' },
  { value: 'Ace Hardware', label: 'Ace Hardware' },
  { value: 'Acer Online Store', label: 'Acer Online Store' },
  { value: 'Addison Lee UK', label: 'Addison Lee UK' },
  { value: 'adidas', label: 'adidas' },
  { value: 'Adorama', label: 'Adorama' },
  { value: 'Advance Auto Parts', label: 'Advance Auto Parts' },
  { value: 'Aeropostale', label: 'Aeropostale' },
  { value: 'Aerosoles', label: 'Aerosoles' },
  { value: 'Age of Learning', label: 'Age of Learning' },
  { value: 'Airbnb Host', label: 'Airbnb Host' },
  { value: 'Airfarewatchdog', label: 'Airfarewatchdog' },
  { value: 'AJ Madison', label: 'AJ Madison' },
  { value: 'Alamo Rent a Car', label: 'Alamo Rent a Car' },
  { value: 'ALEX AND ANI', label: 'ALEX AND ANI' },
  { value: 'Alice + Olivia', label: 'Alice + Olivia' },
  { value: 'AliveCor', label: 'AliveCor' },
  { value: 'Allbirds', label: 'Allbirds' },
  { value: 'AllModern', label: 'AllModern' },
  { value: 'Alloy Apparel', label: 'Alloy Apparel' },
  { value: 'AllPosters.com', label: 'AllPosters.com' },
  { value: 'Allswell Home', label: 'Allswell Home' },
  { value: 'Allure Beauty Box', label: 'Allure Beauty Box' },
  { value: 'Alo Yoga', label: 'Alo Yoga' },
  { value: 'Amara', label: 'Amara' },
  { value: 'American Apparel', label: 'American Apparel' },
  { value: 'American Eagle Outfitters', label: 'American Eagle Outfitters' },
  { value: 'American Girl', label: 'American Girl' },
  { value: "Annie's Craft Store", label: "Annie's Craft Store" },
  { value: "Annie's Kit Club", label: "Annie's Kit Club" },
  { value: 'Ann Taylor', label: 'Ann Taylor' },
  { value: 'Ann Taylor Factory', label: 'Ann Taylor Factory' },
  { value: 'Anthropologie', label: 'Anthropologie' },
  { value: 'Aosom', label: 'Aosom' },
  { value: 'A Pea in the Pod', label: 'A Pea in the Pod' },
  { value: 'Apple', label: 'Apple' },
  { value: 'Apple Music', label: 'Apple Music' },
  { value: "Appleseed's", label: "Appleseed's" },
  { value: 'Apt2B', label: 'Apt2B' },
  { value: 'Aquatalia', label: 'Aquatalia' },
  { value: 'Arhaus', label: 'Arhaus' },
  { value: 'Art.com', label: 'Art.com' },
  { value: 'Artifact Uprising', label: 'Artifact Uprising' },
  { value: 'Ashley Furniture', label: 'Ashley Furniture' },
  { value: 'Ashley Stewart', label: 'Ashley Stewart' },
  { value: 'ASICS', label: 'ASICS' },
  { value: 'ASOS', label: 'ASOS' },
  { value: 'Assouline', label: 'Assouline' },
  { value: 'AT&amp;T Internet', label: 'AT&amp;T Internet' },
  { value: 'Athleta', label: 'Athleta' },
  { value: 'Atom Tickets', label: 'Atom Tickets' },
  { value: 'Audiobooks.com', label: 'Audiobooks.com' },
  { value: 'Avenue', label: 'Avenue' },
  { value: 'Avis Rent A Car', label: 'Avis Rent A Car' },
  { value: 'Away', label: 'Away' },
  { value: 'Babbel', label: 'Babbel' },
  { value: 'Babiators', label: 'Babiators' },
  { value: 'Backcountry', label: 'Backcountry' },
  { value: 'Backyard Ocean', label: 'Backyard Ocean' },
  { value: 'Bailey 44', label: 'Bailey 44' },
  { value: 'Balsam Hill', label: 'Balsam Hill' },
  { value: 'Banana Republic', label: 'Banana Republic' },
  { value: 'Banana Republic Factory', label: 'Banana Republic Factory' },
  { value: 'Bar &amp; Cocoa', label: 'Bar &amp; Cocoa' },
  { value: 'bareMinerals', label: 'bareMinerals' },
  { value: 'Bare Necessities', label: 'Bare Necessities' },
  { value: 'BarkBox', label: 'BarkBox' },
  { value: 'BarkShop', label: 'BarkShop' },
  { value: 'Barnes &amp; Noble', label: 'Barnes &amp; Noble' },
  { value: 'Bass Pro Shops', label: 'Bass Pro Shops' },
  { value: 'BaubleBar', label: 'BaubleBar' },
  { value: 'Baukjen', label: 'Baukjen' },
  { value: 'BCBGMAXAZRIA', label: 'BCBGMAXAZRIA' },
  { value: 'Bead &amp; Reel', label: 'Bead &amp; Reel' },
  { value: 'Bean Box', label: 'Bean Box' },
  { value: 'Bear Naked Custom Granola', label: 'Bear Naked Custom Granola' },
  { value: 'Beauty Bakerie', label: 'Beauty Bakerie' },
  { value: 'BeautyBar.com', label: 'BeautyBar.com' },
  { value: 'Beautycon', label: 'Beautycon' },
  { value: 'Beautyhabit.com', label: 'Beautyhabit.com' },
  { value: 'Beauty Plus Salon', label: 'Beauty Plus Salon' },
  { value: 'Bebe', label: 'Bebe' },
  { value: 'Bed Bath &amp; Beyond', label: 'Bed Bath &amp; Beyond' },
  { value: 'Bedford Fair', label: 'Bedford Fair' },
  { value: 'Belk', label: 'Belk' },
  { value: 'BelleChic', label: 'BelleChic' },
  { value: 'Bergdorf Goodman', label: 'Bergdorf Goodman' },
  { value: 'Best Choice Products', label: 'Best Choice Products' },
  { value: 'Betabrand', label: 'Betabrand' },
  { value: 'Betsey Johnson', label: 'Betsey Johnson' },
  { value: 'BHLDN', label: 'BHLDN' },
  { value: 'Big Dot of Happiness', label: 'Big Dot of Happiness' },
  { value: 'Billion Dollar Brows', label: 'Billion Dollar Brows' },
  { value: 'BioClarity', label: 'BioClarity' },
  { value: 'Biotherm', label: 'Biotherm' },
  { value: 'BirchBox', label: 'BirchBox' },
  { value: 'Birch Lane', label: 'Birch Lane' },
  { value: 'Birthday in a Box', label: 'Birthday in a Box' },
  { value: "BJ's Wholesale Club", label: "BJ's Wholesale Club" },
  { value: 'Blair', label: 'Blair' },
  { value: 'Blick Art Materials', label: 'Blick Art Materials' },
  { value: 'Blinds.com', label: 'Blinds.com' },
  { value: 'Blink Health', label: 'Blink Health' },
  { value: 'BlissLiving', label: 'BlissLiving' },
  { value: 'Bliss World', label: 'Bliss World' },
  { value: "Bloomingdale's", label: "Bloomingdale's" },
  { value: 'BloomsyBox', label: 'BloomsyBox' },
  { value: 'BloomThat', label: 'BloomThat' },
  { value: 'Blue Apron', label: 'Blue Apron' },
  { value: 'Bluefly', label: 'Bluefly' },
  { value: 'Blueland', label: 'Blueland' },
  { value: 'Bluemercury', label: 'Bluemercury' },
  { value: 'Blurb', label: 'Blurb' },
  { value: 'Bluum', label: 'Bluum' },
  { value: 'Bobbie', label: 'Bobbie' },
  { value: 'Bobeau', label: 'Bobeau' },
  { value: 'Boden', label: 'Boden' },
  { value: 'Boll &amp; Branch', label: 'Boll &amp; Branch' },
  { value: 'Bombas Socks', label: 'Bombas Socks' },
  { value: 'Bombfell', label: 'Bombfell' },
  { value: 'Bon-Ton', label: 'Bon-Ton' },
  { value: 'boohoo', label: 'boohoo' },
  { value: 'boohooMAN', label: 'boohooMAN' },
  { value: 'Booking.com', label: 'Booking.com' },
  { value: 'BookingBuddy', label: 'BookingBuddy' },
  { value: 'Book of the Month', label: 'Book of the Month' },
  { value: 'BooksAMillion.com', label: 'BooksAMillion.com' },
  { value: 'Boost Mobile', label: 'Boost Mobile' },
  { value: 'Borghese', label: 'Borghese' },
  { value: 'Born Shoes', label: 'Born Shoes' },
  { value: "Boscov's Department Stores", label: "Boscov's Department Stores" },
  { value: 'Bose.com', label: 'Bose.com' },
  { value: 'Bowflex', label: 'Bowflex' },
  { value: 'Boxed', label: 'Boxed' },
  { value: 'BrandCycle ', label: 'BrandCycle ' },
  { value: 'BrandsMart USA', label: 'BrandsMart USA' },
  { value: 'Breville', label: 'Breville' },
  {
    value: 'Briggs &amp; Riley Travelware',
    label: 'Briggs &amp; Riley Travelware',
  },
  { value: 'BrightChamps', label: 'BrightChamps' },
  { value: 'Brookstone', label: 'Brookstone' },
  { value: 'Buckle', label: 'Buckle' },
  { value: 'Buddha Groove', label: 'Buddha Groove' },
  { value: 'Budget Rent a Car', label: 'Budget Rent a Car' },
  { value: 'Buffalo David Bitton', label: 'Buffalo David Bitton' },
  { value: 'Build-A-Bear', label: 'Build-A-Bear' },
  { value: 'Burlington Coat Factory', label: 'Burlington Coat Factory' },
  { value: 'Burt’s Bees Baby', label: 'Burt’s Bees Baby' },
  { value: 'ButcherBox', label: 'ButcherBox' },
  { value: 'BuyBuy BABY', label: 'BuyBuy BABY' },
  { value: "Cabela's", label: "Cabela's" },
  { value: 'CafePress', label: 'CafePress' },
  { value: 'CALIA', label: 'CALIA' },
  { value: 'Caliper CBD', label: 'Caliper CBD' },
  { value: 'Calvin Klein', label: 'Calvin Klein' },
  { value: 'Campmor', label: 'Campmor' },
  { value: 'Candid', label: 'Candid' },
  { value: 'CandyClub', label: 'CandyClub' },
  { value: 'Canon', label: 'Canon' },
  { value: 'CanvasChamp.com', label: 'CanvasChamp.com' },
  { value: 'Caraway Home', label: 'Caraway Home' },
  { value: 'Care.com', label: 'Care.com' },
  { value: 'Cariloha', label: 'Cariloha' },
  { value: 'Carmen Sol', label: 'Carmen Sol' },
  { value: "Carter's", label: "Carter's" },
  { value: 'Casetify', label: 'Casetify' },
  { value: 'Cato Fashions', label: 'Cato Fashions' },
  { value: 'Caviar', label: 'Caviar' },
  { value: 'CB2', label: 'CB2' },
  { value: 'Cents of Style', label: 'Cents of Style' },
  {
    value: 'Century 21 Department Stores',
    label: 'Century 21 Department Stores',
  },
  { value: 'Chaco', label: 'Chaco' },
  { value: 'Champion', label: 'Champion' },
  { value: 'Champs Sports', label: 'Champs Sports' },
  { value: 'Charlotte Russe', label: 'Charlotte Russe' },
  { value: 'Charming Charlie', label: 'Charming Charlie' },
  { value: 'Chasing Fireflies', label: 'Chasing Fireflies' },
  { value: 'CheapAir.com', label: 'CheapAir.com' },
  { value: "Cheryl's", label: "Cheryl's" },
  { value: 'Chewy', label: 'Chewy' },
  { value: 'Chicco', label: 'Chicco' },
  { value: "Chico's", label: "Chico's" },
  { value: "Chico's Off The Rack", label: "Chico's Off The Rack" },
  { value: 'Chicwish', label: 'Chicwish' },
  { value: 'Chilewich', label: 'Chilewich' },
  { value: 'Chinese Laundry', label: 'Chinese Laundry' },
  { value: 'Choice Hotels', label: 'Choice Hotels' },
  { value: 'CHOiES', label: 'CHOiES' },
  {
    value: 'Christmas Tree Shops andThat',
    label: 'Christmas Tree Shops andThat',
  },
  { value: 'City Chic', label: 'City Chic' },
  { value: "Claire's", label: "Claire's" },
  { value: 'Clarks', label: 'Clarks' },
  { value: 'ClassPass', label: 'ClassPass' },
  { value: 'Clinique Online', label: 'Clinique Online' },
  { value: 'Cloud 9', label: 'Cloud 9' },
  { value: 'Club Monaco', label: 'Club Monaco' },
  { value: 'Clé de Peau Beauté', label: 'Clé de Peau Beauté' },
  { value: 'Coach', label: 'Coach' },
  { value: 'Coach Outlet', label: 'Coach Outlet' },
  { value: 'Coach Outlet Canada', label: 'Coach Outlet Canada' },
  { value: 'Cocofloss', label: 'Cocofloss' },
  { value: 'codeSpark', label: 'codeSpark' },
  { value: 'Cole Haan', label: 'Cole Haan' },
  { value: 'Columbia Sportswear', label: 'Columbia Sportswear' },
  { value: 'Converse', label: 'Converse' },
  { value: "Cookie's Kids", label: "Cookie's Kids" },
  { value: 'Coop Home Goods', label: 'Coop Home Goods' },
  { value: 'Costco', label: 'Costco' },
  { value: 'Costway', label: 'Costway' },
  { value: 'Cotton On', label: 'Cotton On' },
  { value: 'Crabtree &amp; Evelyn', label: 'Crabtree &amp; Evelyn' },
  {
    value: 'Crate &amp; Barrel and Crate&amp;kids',
    label: 'Crate &amp; Barrel and Crate&amp;kids',
  },
  { value: 'Cratejoy', label: 'Cratejoy' },
  { value: 'Crayola', label: 'Crayola' },
  { value: 'Crazy 8', label: 'Crazy 8' },
  { value: 'Creative Live', label: 'Creative Live' },
  { value: 'Cricut', label: 'Cricut' },
  { value: 'Crocs', label: 'Crocs' },
  { value: 'Crowd Cow', label: 'Crowd Cow' },
  { value: 'Cruzee', label: 'Cruzee' },
  { value: 'CuffLinks.com', label: 'CuffLinks.com' },
  { value: 'Cultural Elements', label: 'Cultural Elements' },
  { value: 'CUPSHE', label: 'CUPSHE' },
  { value: 'Current/Elliott', label: 'Current/Elliott' },
  { value: 'Current Air', label: 'Current Air' },
  { value: 'CustomInk', label: 'CustomInk' },
  { value: 'CVS', label: 'CVS' },
  { value: 'DailyBurn.com', label: 'DailyBurn.com' },
  { value: 'Danskin', label: 'Danskin' },
  { value: 'Darden Restaurants', label: 'Darden Restaurants' },
  { value: 'Dashlane', label: 'Dashlane' },
  { value: "David's Bridal", label: "David's Bridal" },
  {
    value: 'Dell Canada - Home &amp; Small Business',
    label: 'Dell Canada - Home &amp; Small Business',
  },
  { value: 'Dell Technologies', label: 'Dell Technologies' },
  { value: 'DELSEY Paris', label: 'DELSEY Paris' },
  { value: 'Denver Broncos Fan Shop', label: 'Denver Broncos Fan Shop' },
  { value: 'Dermstore', label: 'Dermstore' },
  { value: 'Design Within Reach', label: 'Design Within Reach' },
  { value: 'Destination Maternity', label: 'Destination Maternity' },
  { value: 'Dia&amp;Co', label: 'Dia&amp;Co' },
  { value: 'Diapers.com', label: 'Diapers.com' },
  { value: 'DICK’S Sporting Goods', label: 'DICK’S Sporting Goods' },
  { value: 'Diono', label: 'Diono' },
  { value: 'Discount School Supply', label: 'Discount School Supply' },
  { value: 'Dish Network', label: 'Dish Network' },
  { value: 'Disney', label: 'Disney' },
  { value: 'DJI', label: 'DJI' },
  { value: 'DKNY', label: 'DKNY' },
  { value: 'DNA Footwear', label: 'DNA Footwear' },
  { value: 'Dollar Rent-a-Car', label: 'Dollar Rent-a-Car' },
  { value: 'Dollar Shave Club', label: 'Dollar Shave Club' },
  { value: 'Dollar Tree', label: 'Dollar Tree' },
  { value: 'domino', label: 'domino' },
  { value: 'Dona Jo', label: 'Dona Jo' },
  { value: 'Donald J Pliner', label: 'Donald J Pliner' },
  { value: 'Donna Morgan', label: 'Donna Morgan' },
  { value: 'Dooney &amp; Bourke', label: 'Dooney &amp; Bourke' },
  { value: 'DoorDash', label: 'DoorDash' },
  { value: "Dr. Scholl's", label: "Dr. Scholl's" },
  { value: 'Draper James', label: 'Draper James' },
  { value: 'Dressbarn', label: 'Dressbarn' },
  { value: 'DressLily.com', label: 'DressLily.com' },
  { value: 'Drizly', label: 'Drizly' },
  {
    value: 'DSW Designer Shoe Warehouse',
    label: 'DSW Designer Shoe Warehouse',
  },
  { value: 'Dynamite Clothing', label: 'Dynamite Clothing' },
  { value: 'Dyson', label: 'Dyson' },
  { value: 'e.l.f. Cosmetics', label: 'e.l.f. Cosmetics' },
  { value: 'Eargo', label: 'Eargo' },
  { value: 'Eastbay', label: 'Eastbay' },
  { value: 'East Dane', label: 'East Dane' },
  { value: 'Eastern Mountain Sports', label: 'Eastern Mountain Sports' },
  { value: 'EasyClosets', label: 'EasyClosets' },
  { value: 'EasyGarage', label: 'EasyGarage' },
  { value: 'EasyJet Holidays UK', label: 'EasyJet Holidays UK' },
  { value: 'Easy Spirit', label: 'Easy Spirit' },
  { value: 'eBags', label: 'eBags' },
  { value: 'eBay', label: 'eBay' },
  { value: 'ebookers UK', label: 'ebookers UK' },
  { value: 'eBooks.com', label: 'eBooks.com' },
  { value: 'Eco Vessel', label: 'Eco Vessel' },
  { value: 'Edible Arrangements', label: 'Edible Arrangements' },
  { value: 'edX', label: 'edX' },
  { value: 'Electric California', label: 'Electric California' },
  { value: 'EliteBaby', label: 'EliteBaby' },
  { value: 'Elizabeth Arden', label: 'Elizabeth Arden' },
  { value: 'Ellie', label: 'Ellie' },
  { value: 'ELOQUII', label: 'ELOQUII' },
  { value: 'Enfamil', label: 'Enfamil' },
  { value: 'Enterprise Rent a Car', label: 'Enterprise Rent a Car' },
  { value: 'Epic', label: 'Epic' },
  { value: 'Equipment', label: 'Equipment' },
  { value: 'Ergobaby', label: 'Ergobaby' },
  { value: 'Estella', label: 'Estella' },
  { value: 'Etsy', label: 'Etsy' },
  { value: 'Etsy Studio', label: 'Etsy Studio' },
  {
    value: 'Europcar International UK and Ireland',
    label: 'Europcar International UK and Ireland',
  },
  { value: "Eve's Addiction", label: "Eve's Addiction" },
  { value: 'Evenflo Baby', label: 'Evenflo Baby' },
  { value: 'EventDecorDirect.com', label: 'EventDecorDirect.com' },
  { value: 'Everlane', label: 'Everlane' },
  { value: 'EverydayYoga', label: 'EverydayYoga' },
  { value: 'EveryPlate', label: 'EveryPlate' },
  { value: 'Expedia', label: 'Expedia' },
  { value: 'Expedia UK', label: 'Expedia UK' },
  { value: 'ExpertFlyer.com', label: 'ExpertFlyer.com' },
  { value: 'Express', label: 'Express' },
  { value: 'EyeBuyDirect', label: 'EyeBuyDirect' },
  { value: 'EZPZ', label: 'EZPZ' },
  { value: 'FabFitFun', label: 'FabFitFun' },
  { value: 'FabKids', label: 'FabKids' },
  { value: 'Fabletics', label: 'Fabletics' },
  { value: 'Fabric.com', label: 'Fabric.com' },
  { value: 'Factor', label: 'Factor' },
  { value: 'Fanatics', label: 'Fanatics' },
  { value: 'Fandango', label: 'Fandango' },
  { value: 'FandangoNow', label: 'FandangoNow' },
  { value: 'Fannie May Fine Chocolates', label: 'Fannie May Fine Chocolates' },
  { value: 'FansEdge', label: 'FansEdge' },
  { value: 'FarmRio', label: 'FarmRio' },
  { value: 'Fashion to Figure', label: 'Fashion to Figure' },
  { value: 'Fat Brain Toys', label: 'Fat Brain Toys' },
  { value: 'Fawn Design', label: 'Fawn Design' },
  { value: 'Fila.com', label: 'Fila.com' },
  { value: 'Find Your Trainer', label: 'Find Your Trainer' },
  { value: 'FineJewelers.com', label: 'FineJewelers.com' },
  { value: 'FineStationery.com', label: 'FineStationery.com' },
  { value: 'Finish Line', label: 'Finish Line' },
  { value: 'First Day', label: 'First Day' },
  { value: 'Firstleaf Wine Club', label: 'Firstleaf Wine Club' },
  { value: 'Fisher-Price', label: 'Fisher-Price' },
  { value: 'Fitbit', label: 'Fitbit' },
  { value: 'FitFlop', label: 'FitFlop' },
  { value: 'Five Below', label: 'Five Below' },
  { value: 'Five Star', label: 'Five Star' },
  { value: 'Folica.com', label: 'Folica.com' },
  { value: 'Foodstirs.com', label: 'Foodstirs.com' },
  { value: 'Foot Locker', label: 'Foot Locker' },
  { value: 'Forever 21', label: 'Forever 21' },
  { value: 'FORZIERI', label: 'FORZIERI' },
  { value: 'Fossil', label: 'Fossil' },
  { value: 'Fossil Canada', label: 'Fossil Canada' },
  { value: 'Fracture', label: 'Fracture' },
  { value: 'Framebridge', label: 'Framebridge' },
  { value: 'Francesca’s', label: 'Francesca’s' },
  { value: 'Frank And Oak', label: 'Frank And Oak' },
  { value: 'FreedomPop', label: 'FreedomPop' },
  { value: 'Free People', label: 'Free People' },
  { value: 'French Connection', label: 'French Connection' },
  { value: 'FreshDirect', label: 'FreshDirect' },
  { value: 'Freshly', label: 'Freshly' },
  { value: 'FruitBouquets.com', label: 'FruitBouquets.com' },
  { value: 'Fruits &amp; Passion', label: 'Fruits &amp; Passion' },
  { value: 'Frye', label: 'Frye' },
  { value: 'FTD', label: 'FTD' },
  { value: 'Gaffos.com', label: 'Gaffos.com' },
  { value: 'Gaiam.com', label: 'Gaiam.com' },
  { value: 'GameFly', label: 'GameFly' },
  { value: 'GameStop', label: 'GameStop' },
  { value: 'Gap', label: 'Gap' },
  { value: 'Gap Factory', label: 'Gap Factory' },
  { value: 'Garage Clothing', label: 'Garage Clothing' },
  { value: 'Garmin', label: 'Garmin' },
  { value: 'GatheredTable', label: 'GatheredTable' },
  { value: 'Gazelle', label: 'Gazelle' },
  { value: 'G by GUESS', label: 'G by GUESS' },
  { value: 'GDFStudio.com', label: 'GDFStudio.com' },
  { value: 'Geek Fuel', label: 'Geek Fuel' },
  { value: 'Geologie', label: 'Geologie' },
  { value: 'Gerber Childrenswear', label: 'Gerber Childrenswear' },
  { value: 'Ghirardelli Chocolate', label: 'Ghirardelli Chocolate' },
  { value: 'Giant Food', label: 'Giant Food' },
  { value: 'GiftCards.com', label: 'GiftCards.com' },
  { value: 'giggle', label: 'giggle' },
  { value: 'GiGi New York', label: 'GiGi New York' },
  { value: 'Gilt', label: 'Gilt' },
  { value: 'Gilt City', label: 'Gilt City' },
  { value: 'Glasses.com', label: 'Glasses.com' },
  { value: 'GlobeIn', label: 'GlobeIn' },
  { value: 'Gobble', label: 'Gobble' },
  { value: 'Go City Card', label: 'Go City Card' },
  { value: 'Going, Going, Gone', label: 'Going, Going, Gone' },
  { value: 'Goldbelly', label: 'Goldbelly' },
  { value: 'Good American', label: 'Good American' },
  { value: 'Goodnight Macaroon', label: 'Goodnight Macaroon' },
  { value: 'goop', label: 'goop' },
  { value: 'GoPro', label: 'GoPro' },
  { value: 'Gorjana', label: 'Gorjana' },
  { value: 'GoToMeeting', label: 'GoToMeeting' },
  { value: 'Graco', label: 'Graco' },
  { value: 'Graze', label: 'Graze' },
  { value: 'GreaterGood', label: 'GreaterGood' },
  { value: 'Great Pet', label: 'Great Pet' },
  { value: 'Green Chef', label: 'Green Chef' },
  { value: 'Greenvelope', label: 'Greenvelope' },
  { value: 'Grokker', label: 'Grokker' },
  { value: 'GroopDealz', label: 'GroopDealz' },
  { value: 'Groupon', label: 'Groupon' },
  { value: 'Grove Collaborative', label: 'Grove Collaborative' },
  { value: 'GroVia', label: 'GroVia' },
  { value: 'GUESS', label: 'GUESS' },
  { value: 'Guess by MARCIANO', label: 'Guess by MARCIANO' },
  { value: 'GUESS Factory Store', label: 'GUESS Factory Store' },
  { value: 'GUESSKids', label: 'GUESSKids' },
  { value: 'GUNAS New York', label: 'GUNAS New York' },
  { value: 'Gymboree', label: 'Gymboree' },
  { value: 'H&amp;M', label: 'H&amp;M' },
  { value: 'H20+ Beauty', label: 'H20+ Beauty' },
  { value: 'Haband', label: 'Haband' },
  { value: 'Halara', label: 'Halara' },
  { value: 'Handy.com', label: 'Handy.com' },
  { value: 'Hanes.com', label: 'Hanes.com' },
  { value: 'Hanna Andersson', label: 'Hanna Andersson' },
  { value: 'Happiest Baby', label: 'Happiest Baby' },
  { value: 'Happy Socks', label: 'Happy Socks' },
  {
    value: 'Harman/Kardon by HARMAN Online',
    label: 'Harman/Kardon by HARMAN Online',
  },
  { value: 'HarmanAudio.com', label: 'HarmanAudio.com' },
  { value: 'Harmon Face Values', label: 'Harmon Face Values' },
  { value: 'Harrods', label: 'Harrods' },
  { value: 'Harry &amp; David', label: 'Harry &amp; David' },
  { value: 'Harvey Nichols', label: 'Harvey Nichols' },
  { value: 'Hasbro Toy Shop', label: 'Hasbro Toy Shop' },
  { value: 'Hatch Collection', label: 'Hatch Collection' },
  { value: 'Hatley', label: 'Hatley' },
  { value: 'Hautelook', label: 'Hautelook' },
  { value: 'Haven Well Within', label: 'Haven Well Within' },
  { value: 'Hayneedle', label: 'Hayneedle' },
  { value: 'HearthSong', label: 'HearthSong' },
  {
    value: 'Heathrow Airport Parking UK',
    label: 'Heathrow Airport Parking UK',
  },
  { value: 'HelloFresh', label: 'HelloFresh' },
  { value: 'Herschel Supply Company', label: 'Herschel Supply Company' },
  { value: 'Hertz', label: 'Hertz' },
  { value: 'Hey Dude Shoes', label: 'Hey Dude Shoes' },
  { value: 'Highlights for Children', label: 'Highlights for Children' },
  { value: 'Hilton Worldwide', label: 'Hilton Worldwide' },
  { value: 'Hint Water', label: 'Hint Water' },
  { value: 'Hoka One', label: 'Hoka One' },
  { value: 'Hollar', label: 'Hollar' },
  { value: 'HomeAdvisor', label: 'HomeAdvisor' },
  { value: 'HomeAway', label: 'HomeAway' },
  { value: 'Home Chef', label: 'Home Chef' },
  { value: 'HomeDNA', label: 'HomeDNA' },
  { value: 'HomeGoods', label: 'HomeGoods' },
  { value: 'HOMER LEARNING', label: 'HOMER LEARNING' },
  { value: 'Hooked on Phonics', label: 'Hooked on Phonics' },
  { value: 'Hotels.com', label: 'Hotels.com' },
  { value: 'Hotels.com UK', label: 'Hotels.com UK' },
  { value: 'Hotter Shoes', label: 'Hotter Shoes' },
  { value: 'Hot Topic', label: 'Hot Topic' },
  { value: 'Hotwire', label: 'Hotwire' },
  { value: 'House of Brides', label: 'House of Brides' },
  { value: 'Houston Texans Fan Shop', label: 'Houston Texans Fan Shop' },
  { value: 'Houzz', label: 'Houzz' },
  { value: 'HP', label: 'HP' },
  {
    value: 'HSN.com - Home Shopping Network',
    label: 'HSN.com - Home Shopping Network',
  },
  { value: 'Huckberry', label: 'Huckberry' },
  { value: 'HuntAKiller.com', label: 'HuntAKiller.com' },
  { value: 'Hunter Boots', label: 'Hunter Boots' },
  { value: 'Hurley', label: 'Hurley' },
  { value: 'Hurley Outlet', label: 'Hurley Outlet' },
  { value: 'Hush Puppies', label: 'Hush Puppies' },
  { value: 'Hydro Flask', label: 'Hydro Flask' },
  { value: "I'm The Chef Too", label: "I'm The Chef Too" },
  { value: 'Icing', label: 'Icing' },
  { value: 'Igloo Coolers', label: 'Igloo Coolers' },
  {
    value: 'IHG (InterContinental Hotels Group',
    label: 'IHG (InterContinental Hotels Group',
  },
  { value: 'InfiniteAloe', label: 'InfiniteAloe' },
  { value: 'Instacart', label: 'Instacart' },
  { value: 'Instacart - Shoppers', label: 'Instacart - Shoppers' },
  { value: 'Intelligent Blends', label: 'Intelligent Blends' },
  { value: 'Intermix', label: 'Intermix' },
  { value: 'IPSY', label: 'IPSY' },
  { value: "Irv's Luggage", label: "Irv's Luggage" },
  { value: 'Isabella Oliver', label: 'Isabella Oliver' },
  { value: 'IT Cosmetics', label: 'IT Cosmetics' },
  { value: 'Itzy Ritzy', label: 'Itzy Ritzy' },
  { value: 'ivivva by lululemon', label: 'ivivva by lululemon' },
  { value: 'Ivory Ella', label: 'Ivory Ella' },
  { value: 'J.Crew', label: 'J.Crew' },
  { value: 'J.Crew Factory', label: 'J.Crew Factory' },
  { value: 'J. Jill', label: 'J. Jill' },
  { value: 'J.McLaughlin', label: 'J.McLaughlin' },
  { value: 'Jack Rogers', label: 'Jack Rogers' },
  {
    value: 'Jacksonville Jaguars Fan Shop',
    label: 'Jacksonville Jaguars Fan Shop',
  },
  { value: 'James Perse', label: 'James Perse' },
  { value: 'Jane Affiliates', label: 'Jane Affiliates' },
  { value: 'Janie and Jack', label: 'Janie and Jack' },
  { value: 'JanSport', label: 'JanSport' },
  {
    value: 'Jared The Galleria of Jewelry',
    label: 'Jared The Galleria of Jewelry',
  },
  { value: 'JBL by HARMAN Online', label: 'JBL by HARMAN Online' },
  { value: 'JBL Canada', label: 'JBL Canada' },
  { value: 'JCPenney', label: 'JCPenney' },
  { value: 'JellyBelly.com', label: 'JellyBelly.com' },
  { value: 'Jet.com', label: 'Jet.com' },
  { value: 'Jewelry.com', label: 'Jewelry.com' },
  { value: 'Jimmy Choo', label: 'Jimmy Choo' },
  { value: 'Jo-Ann Fabric', label: 'Jo-Ann Fabric' },
  { value: "Joe's Jeans", label: "Joe's Jeans" },
  { value: "Joe's New Balance Outlet", label: "Joe's New Balance Outlet" },
  { value: 'Johnnie-O', label: 'Johnnie-O' },
  { value: 'Johnston &amp; Murphy', label: 'Johnston &amp; Murphy' },
  { value: 'John Varvatos', label: 'John Varvatos' },
  { value: 'Joie Clothing', label: 'Joie Clothing' },
  { value: 'JoJo Maman Bebe', label: 'JoJo Maman Bebe' },
  { value: 'Jomashop', label: 'Jomashop' },
  { value: 'Jonathan Adler', label: 'Jonathan Adler' },
  { value: 'Jos. A. Bank', label: 'Jos. A. Bank' },
  { value: 'Joss &amp; Main', label: 'Joss &amp; Main' },
  { value: 'Joules', label: 'Joules' },
  { value: 'Journeys', label: 'Journeys' },
  { value: 'Joyus', label: 'Joyus' },
  { value: 'JuiceBeauty.com', label: 'JuiceBeauty.com' },
  { value: 'Juicy Couture', label: 'Juicy Couture' },
  { value: 'June Jacobs Spa Collection', label: 'June Jacobs Spa Collection' },
  { value: 'JUS by Julie', label: 'JUS by Julie' },
  { value: 'JustFab', label: 'JustFab' },
  { value: 'Justice', label: 'Justice' },
  { value: 'K-Swiss', label: 'K-Swiss' },
  { value: 'Kali', label: 'Kali' },
  { value: 'Kangaroo', label: 'Kangaroo' },
  { value: 'KarenKane.com', label: 'KarenKane.com' },
  { value: 'Karen Millen', label: 'Karen Millen' },
  { value: 'Kate Spade', label: 'Kate Spade' },
  { value: 'Kate Spade Surprise', label: 'Kate Spade Surprise' },
  { value: 'Kay Jewelers', label: 'Kay Jewelers' },
  { value: 'Kay Jewelers Outlet', label: 'Kay Jewelers Outlet' },
  { value: 'KeaBabies', label: 'KeaBabies' },
  { value: 'Keds', label: 'Keds' },
  { value: 'Keepsake Quilting', label: 'Keepsake Quilting' },
  { value: 'Kelsi Dagger Brooklyn', label: 'Kelsi Dagger Brooklyn' },
  { value: 'Kendra Scott', label: 'Kendra Scott' },
  { value: 'Kenneth Cole', label: 'Kenneth Cole' },
  { value: 'Kerastase', label: 'Kerastase' },
  { value: 'Keurig', label: 'Keurig' },
  { value: 'kidpik', label: 'kidpik' },
  { value: 'kidpik Shop', label: 'kidpik Shop' },
  { value: 'Kids Foot Locker', label: 'Kids Foot Locker' },
  { value: "Kirkland's Home", label: "Kirkland's Home" },
  { value: 'KitchenAid.com ', label: 'KitchenAid.com ' },
  { value: 'Kiwi.com', label: 'Kiwi.com' },
  { value: 'KiwiCo', label: 'KiwiCo' },
  { value: 'Kiyonna Clothing', label: 'Kiyonna Clothing' },
  { value: 'Kmart', label: 'Kmart' },
  { value: "Kohl's", label: "Kohl's" },
  { value: 'Kole Imports', label: 'Kole Imports' },
  { value: 'Kopari Beauty', label: 'Kopari Beauty' },
  { value: 'Kroger Ship', label: 'Kroger Ship' },
  { value: "L'ange Hair", label: "L'ange Hair" },
  { value: "L'Occitane en Provence", label: "L'Occitane en Provence" },
  { value: 'L.K.Bennett UK', label: 'L.K.Bennett UK' },
  { value: 'L.L.Bean', label: 'L.L.Bean' },
  { value: 'Lacoste', label: 'Lacoste' },
  { value: 'Lady Footlocker', label: 'Lady Footlocker' },
  { value: 'Lakeside Collection', label: 'Lakeside Collection' },
  { value: "Lands' End", label: "Lands' End" },
  { value: 'Lane Bryant', label: 'Lane Bryant' },
  { value: 'Lascana', label: 'Lascana' },
  { value: 'LateRooms.com UK', label: 'LateRooms.com UK' },
  { value: 'LE CHÂTEAU', label: 'LE CHÂTEAU' },
  { value: 'Le Creuset', label: 'Le Creuset' },
  { value: 'Lego Brand Retail', label: 'Lego Brand Retail' },
  { value: 'Leisure Arts', label: 'Leisure Arts' },
  { value: 'Lenovo', label: 'Lenovo' },
  { value: 'Leon Max (MaxStudio.com', label: 'Leon Max (MaxStudio.com' },
  { value: "Levi's", label: "Levi's" },
  { value: 'LexMod', label: 'LexMod' },
  { value: 'Liberty Mutual', label: 'Liberty Mutual' },
  { value: 'Life Extension', label: 'Life Extension' },
  { value: 'Life is Good', label: 'Life is Good' },
  { value: 'LightInTheBox.com', label: 'LightInTheBox.com' },
  { value: 'Lilly Pulitzer', label: 'Lilly Pulitzer' },
  { value: 'Limoges Jewelry', label: 'Limoges Jewelry' },
  { value: 'Lindt Chocolate', label: 'Lindt Chocolate' },
  { value: 'LinenSource', label: 'LinenSource' },
  { value: 'Little Lotus', label: 'Little Lotus' },
  { value: 'Little Passports', label: 'Little Passports' },
  { value: 'Little Sleepies', label: 'Little Sleepies' },
  { value: 'LOFT', label: 'LOFT' },
  { value: 'LOFT Outlet', label: 'LOFT Outlet' },
  { value: 'Lookfantastic', label: 'Lookfantastic' },
  { value: 'Loom &amp; Leaf', label: 'Loom &amp; Leaf' },
  { value: 'Loom Decor', label: 'Loom Decor' },
  { value: 'Loot Crate', label: 'Loot Crate' },
  { value: 'Lord &amp; Taylor', label: 'Lord &amp; Taylor' },
  { value: 'Lou &amp; Grey', label: 'Lou &amp; Grey' },
  { value: 'LoveBook Online', label: 'LoveBook Online' },
  { value: 'Lovepop Cards', label: 'Lovepop Cards' },
  { value: 'Love With Food', label: 'Love With Food' },
  { value: 'Lucky Brand', label: 'Lucky Brand' },
  { value: 'Lulu and Georgia', label: 'Lulu and Georgia' },
  { value: 'Lululemon', label: 'Lululemon' },
  { value: 'Lulus', label: 'Lulus' },
  { value: 'Lumens', label: 'Lumens' },
  { value: 'Lumo BodyTech', label: 'Lumo BodyTech' },
  { value: "Mabel's Labels", label: "Mabel's Labels" },
  { value: 'MAC Cosmetics', label: 'MAC Cosmetics' },
  { value: 'Mackage', label: 'Mackage' },
  { value: "Macy's", label: "Macy's" },
  { value: 'Madewell', label: 'Madewell' },
  { value: 'Madison Reed', label: 'Madison Reed' },
  { value: 'Magic Beans', label: 'Magic Beans' },
  { value: 'Maidenform', label: 'Maidenform' },
  { value: 'MakeUp Eraser', label: 'MakeUp Eraser' },
  { value: 'Malwarebytes', label: 'Malwarebytes' },
  { value: 'MANGO', label: 'MANGO' },
  { value: 'Marc Jacobs', label: 'Marc Jacobs' },
  { value: 'Maria Shireen', label: 'Maria Shireen' },
  { value: 'Marika', label: 'Marika' },
  { value: 'Mark &amp; Graham', label: 'Mark &amp; Graham' },
  { value: 'Marshalls', label: 'Marshalls' },
  { value: 'Martha &amp; Marley Spoon', label: 'Martha &amp; Marley Spoon' },
  { value: 'Massdrop', label: 'Massdrop' },
  { value: 'MATCHESFASHION.COM', label: 'MATCHESFASHION.COM' },
  { value: 'Matt &amp; Nat', label: 'Matt &amp; Nat' },
  { value: 'Maurices', label: 'Maurices' },
  { value: 'Maytag', label: 'Maytag' },
  { value: 'McAfee', label: 'McAfee' },
  { value: 'Melissa &amp; Doug', label: 'Melissa &amp; Doug' },
  { value: "Men's Wearhouse", label: "Men's Wearhouse" },
  { value: 'Merrell', label: 'Merrell' },
  { value: 'Meta Quest', label: 'Meta Quest' },
  { value: 'Miami Dolphins Team Shop', label: 'Miami Dolphins Team Shop' },
  { value: 'Michael Kors', label: 'Michael Kors' },
  { value: 'Michaels Stores', label: 'Michaels Stores' },
  { value: 'Michael Stars', label: 'Michael Stars' },
  { value: 'Microsoft', label: 'Microsoft' },
  { value: 'Miku', label: 'Miku' },
  { value: 'Milani Cosmetics', label: 'Milani Cosmetics' },
  { value: 'Milk Makeup', label: 'Milk Makeup' },
  { value: 'Millennium Hotels UK', label: 'Millennium Hotels UK' },
  { value: 'MiniInTheBox.com', label: 'MiniInTheBox.com' },
  { value: 'Ministry of Supply', label: 'Ministry of Supply' },
  { value: 'Minted', label: 'Minted' },
  { value: 'MisterArt', label: 'MisterArt' },
  { value: 'Mixbook', label: 'Mixbook' },
  { value: 'Mixtiles', label: 'Mixtiles' },
  { value: 'Mizuno', label: 'Mizuno' },
  { value: 'MKF Collection', label: 'MKF Collection' },
  { value: 'MLBShop.com', label: 'MLBShop.com' },
  { value: 'MLSStore.com', label: 'MLSStore.com' },
  { value: 'MM.LaFleur', label: 'MM.LaFleur' },
  { value: 'ModCloth', label: 'ModCloth' },
  { value: 'Modern Fertility', label: 'Modern Fertility' },
  { value: 'MonsterProducts.com', label: 'MonsterProducts.com' },
  { value: 'Moosejaw', label: 'Moosejaw' },
  { value: 'Mopnado', label: 'Mopnado' },
  { value: 'Motherhood Maternity', label: 'Motherhood Maternity' },
  { value: 'Motives Cosmetics', label: 'Motives Cosmetics' },
  { value: 'Motley Fool', label: 'Motley Fool' },
  { value: 'Mountain Primal Meat Co', label: 'Mountain Primal Meat Co' },
  { value: 'MOUTH', label: 'MOUTH' },
  { value: 'MPG Sport', label: 'MPG Sport' },
  { value: 'Mrs. Fields', label: 'Mrs. Fields' },
  { value: 'MUK LUKS', label: 'MUK LUKS' },
  { value: 'Munchkin', label: 'Munchkin' },
  { value: 'Murad Skin Care', label: 'Murad Skin Care' },
  { value: 'Muttropolis', label: 'Muttropolis' },
  { value: 'My M&amp;Ms', label: 'My M&amp;Ms' },
  { value: 'MyRegistry.com', label: 'MyRegistry.com' },
  { value: 'Mystery Guild', label: 'Mystery Guild' },
  { value: 'My Wedding Favors', label: 'My Wedding Favors' },
  { value: 'NARS Cosmetics', label: 'NARS Cosmetics' },
  { value: 'Nasty Gal', label: 'Nasty Gal' },
  { value: 'National Car Rental', label: 'National Car Rental' },
  { value: 'NatureBox', label: 'NatureBox' },
  { value: 'Nautica', label: 'Nautica' },
  { value: 'NBAStore.com', label: 'NBAStore.com' },
  { value: 'Necessary Clothing', label: 'Necessary Clothing' },
  { value: 'Nectar Sleep', label: 'Nectar Sleep' },
  { value: 'Neiman Marcus', label: 'Neiman Marcus' },
  { value: 'Neiman Marcus Last Call', label: 'Neiman Marcus Last Call' },
  { value: 'Nest Bedding', label: 'Nest Bedding' },
  { value: 'New Balance', label: 'New Balance' },
  {
    value: 'Newell Brands Home Fragrance: Yankee Candle',
    label: 'Newell Brands Home Fragrance: Yankee Candle',
  },
  { value: 'New Vitality', label: 'New Vitality' },
  { value: 'New York &amp; Company', label: 'New York &amp; Company' },
  { value: 'NFLShop.com', label: 'NFLShop.com' },
  { value: 'Nike', label: 'Nike' },
  { value: 'Nine West', label: 'Nine West' },
  { value: 'Nisolo', label: 'Nisolo' },
  { value: 'NOMAD Goods', label: 'NOMAD Goods' },
  { value: 'NOMATIC.com', label: 'NOMATIC.com' },
  { value: 'Noom', label: 'Noom' },
  { value: 'Noom Mood', label: 'Noom Mood' },
  { value: 'Nordstrom', label: 'Nordstrom' },
  { value: 'Nordstrom Rack', label: 'Nordstrom Rack' },
  { value: 'NordVPN', label: 'NordVPN' },
  { value: 'Norton by Symantec', label: 'Norton by Symantec' },
  { value: 'Nurx', label: 'Nurx' },
  { value: 'Nuts.com', label: 'Nuts.com' },
  { value: 'NY Giants Fan Shop', label: 'NY Giants Fan Shop' },
  { value: 'NYX Professional Makeup', label: 'NYX Professional Makeup' },
  { value: "O'Neil", label: "O'Neil" },
  { value: 'Oakiwear', label: 'Oakiwear' },
  { value: 'Oakley', label: 'Oakley' },
  { value: 'Obé Fitness', label: 'Obé Fitness' },
  { value: 'Off Broadway Shoes', label: 'Off Broadway Shoes' },
  { value: 'Office Depot and OfficeMax', label: 'Office Depot and OfficeMax' },
  { value: 'Oka-B Shoes', label: 'Oka-B Shoes' },
  { value: 'Olay', label: 'Olay' },
  { value: 'Old Navy', label: 'Old Navy' },
  { value: 'Old Pueblo Traders', label: 'Old Pueblo Traders' },
  { value: 'Ollie Pets', label: 'Ollie Pets' },
  { value: 'Olly', label: 'Olly' },
  { value: 'Olympia Sports', label: 'Olympia Sports' },
  { value: 'Omorovicza Cosmetics', label: 'Omorovicza Cosmetics' },
  { value: 'One Kings Lane', label: 'One Kings Lane' },
  { value: 'OnlineShoes.com', label: 'OnlineShoes.com' },
  { value: 'Only Natural Pet', label: 'Only Natural Pet' },
  { value: 'Ooni', label: 'Ooni' },
  { value: 'Openfit', label: 'Openfit' },
  { value: 'Orbitz', label: 'Orbitz' },
  { value: 'Organic Merchants Co', label: 'Organic Merchants Co' },
  { value: 'Oriental Trading Company', label: 'Oriental Trading Company' },
  { value: 'OshKosh B-gosh', label: 'OshKosh B-gosh' },
  { value: 'Osprey Packs', label: 'Osprey Packs' },
  { value: 'Outschool', label: 'Outschool' },
  { value: 'Overstock', label: 'Overstock' },
  { value: 'P.volve', label: 'P.volve' },
  { value: 'Packed Party', label: 'Packed Party' },
  { value: 'Pact Apparel', label: 'Pact Apparel' },
  { value: 'Paper Culture', label: 'Paper Culture' },
  { value: 'Paper Source', label: 'Paper Source' },
  { value: 'Parasuco Jeans', label: 'Parasuco Jeans' },
  { value: 'Park Inn UK', label: 'Park Inn UK' },
  { value: 'PatPat', label: 'PatPat' },
  { value: "Paula's Choice", label: "Paula's Choice" },
  { value: 'Peapod', label: 'Peapod' },
  { value: 'Peek Kids', label: 'Peek Kids' },
  { value: 'Persol', label: 'Persol' },
  { value: 'Personalization Universe', label: 'Personalization Universe' },
  { value: 'Petco', label: 'Petco' },
  { value: 'Peter Thomas Roth', label: 'Peter Thomas Roth' },
  { value: 'Pet Supermarket', label: 'Pet Supermarket' },
  { value: 'Pharmaca', label: 'Pharmaca' },
  { value: 'Phemex', label: 'Phemex' },
  { value: 'PHOOZY', label: 'PHOOZY' },
  { value: 'Physicians Formula', label: 'Physicians Formula' },
  { value: 'Pick Your Plum', label: 'Pick Your Plum' },
  { value: 'Pier 1', label: 'Pier 1' },
  { value: 'Pique Life', label: 'Pique Life' },
  { value: 'PJ Place', label: 'PJ Place' },
  { value: 'Plaid Cow Society', label: 'Plaid Cow Society' },
  { value: 'Planet Blue', label: 'Planet Blue' },
  { value: 'Plant Therapy', label: 'Plant Therapy' },
  { value: 'Plated', label: 'Plated' },
  { value: 'PLAYMOBIL', label: 'PLAYMOBIL' },
  { value: 'Pley', label: 'Pley' },
  { value: 'Plow &amp; Hearth', label: 'Plow &amp; Hearth' },
  { value: 'Points.com', label: 'Points.com' },
  { value: 'Portal', label: 'Portal' },
  { value: 'Postmates', label: 'Postmates' },
  { value: 'Pour La Victoire', label: 'Pour La Victoire' },
  { value: 'prAna', label: 'prAna' },
  { value: 'Pregnancy Bites', label: 'Pregnancy Bites' },
  { value: 'Priceline', label: 'Priceline' },
  { value: 'Priority Pass', label: 'Priority Pass' },
  { value: 'Prive Revaux', label: 'Prive Revaux' },
  { value: 'PRO-Keds', label: 'PRO-Keds' },
  { value: 'ProFlowers', label: 'ProFlowers' },
  { value: 'Project Nursery', label: 'Project Nursery' },
  { value: 'Proozy', label: 'Proozy' },
  { value: 'PUMA', label: 'PUMA' },
  { value: 'Pura Vida Bracelets', label: 'Pura Vida Bracelets' },
  { value: 'Purple Carrot', label: 'Purple Carrot' },
  { value: 'Qatar Airways', label: 'Qatar Airways' },
  { value: 'Quay Australia', label: 'Quay Australia' },
  { value: 'Qustodio', label: 'Qustodio' },
  { value: 'QVC.com', label: 'QVC.com' },
  { value: 'Rachel Pally', label: 'Rachel Pally' },
  { value: 'Rack Room Shoes', label: 'Rack Room Shoes' },
  { value: 'Raddish Kids', label: 'Raddish Kids' },
  { value: 'Radisson Blu Edwardian UK', label: 'Radisson Blu Edwardian UK' },
  { value: 'Radisson Blu UK', label: 'Radisson Blu UK' },
  { value: 'Radisson Hotels', label: 'Radisson Hotels' },
  { value: 'Radisson Hotels UK', label: 'Radisson Hotels UK' },
  { value: 'RAEN', label: 'RAEN' },
  { value: 'rag &amp; bone', label: 'rag &amp; bone' },
  { value: 'Ray-Ban', label: 'Ray-Ban' },
  { value: 'Really Good Stuff', label: 'Really Good Stuff' },
  { value: 'Redbox', label: 'Redbox' },
  { value: 'Red Heart Yarn', label: 'Red Heart Yarn' },
  { value: 'Reebok', label: 'Reebok' },
  { value: 'Reef', label: 'Reef' },
  { value: 'Reitmans', label: 'Reitmans' },
  { value: 'Rejuvenation', label: 'Rejuvenation' },
  { value: 'Rent the Runway', label: 'Rent the Runway' },
  { value: 'Republic Wireless', label: 'Republic Wireless' },
  { value: 'Restaurant.com', label: 'Restaurant.com' },
  { value: 'Revolve', label: 'Revolve' },
  { value: 'RIMOWA', label: 'RIMOWA' },
  { value: 'Rite Aid', label: 'Rite Aid' },
  { value: 'Road Runner Sports', label: 'Road Runner Sports' },
  { value: 'RocketDog', label: 'RocketDog' },
  { value: 'Rocket Languages', label: 'Rocket Languages' },
  { value: 'Rockport', label: 'Rockport' },
  { value: "Rodale's", label: "Rodale's" },
  { value: 'Roofstock', label: 'Roofstock' },
  { value: 'Rosetta Stone', label: 'Rosetta Stone' },
  { value: 'Rue21', label: 'Rue21' },
  { value: 'Rue La La', label: 'Rue La La' },
  { value: 'RushMyPassport.com', label: 'RushMyPassport.com' },
  { value: 'Sabon', label: 'Sabon' },
  { value: 'Saks Fifth Avenue', label: 'Saks Fifth Avenue' },
  { value: 'Saks Fifth Avenue OFF 5TH', label: 'Saks Fifth Avenue OFF 5TH' },
  { value: 'Sally Beauty Supply', label: 'Sally Beauty Supply' },
  { value: "Sam's Club", label: "Sam's Club" },
  { value: 'Samsonite', label: 'Samsonite' },
  { value: 'Samsung', label: 'Samsung' },
  {
    value: 'San Francisco 49ers Team Shop',
    label: 'San Francisco 49ers Team Shop',
  },
  { value: 'Saucony', label: 'Saucony' },
  { value: 'Savage X Fenty', label: 'Savage X Fenty' },
  { value: 'Scentbird', label: 'Scentbird' },
  { value: 'Scholastic', label: 'Scholastic' },
  { value: 'SchoolMaskPack', label: 'SchoolMaskPack' },
  { value: 'Sears', label: 'Sears' },
  { value: 'Sears Outlet', label: 'Sears Outlet' },
  { value: 'SeaWorld Parks', label: 'SeaWorld Parks' },
  { value: 'Sephora', label: 'Sephora' },
  { value: 'SheIn', label: 'SheIn' },
  { value: 'Shindigz', label: 'Shindigz' },
  { value: 'Shipt', label: 'Shipt' },
  { value: 'Shiseido', label: 'Shiseido' },
  { value: 'Shoebuy.com', label: 'Shoebuy.com' },
  { value: 'ShoeDazzle', label: 'ShoeDazzle' },
  { value: 'ShoeMall', label: 'ShoeMall' },
  { value: 'Shoes.com', label: 'Shoes.com' },
  { value: 'Shop.NHL.com', label: 'Shop.NHL.com' },
  { value: 'Shopbop', label: 'Shopbop' },
  { value: 'shopDisney', label: 'shopDisney' },
  { value: 'Shop Hello Fashion', label: 'Shop Hello Fashion' },
  { value: 'Shop Premium Outlets', label: 'Shop Premium Outlets' },
  { value: 'Showtime', label: 'Showtime' },
  { value: 'Shudder', label: 'Shudder' },
  { value: 'Shutterfly', label: 'Shutterfly' },
  { value: 'Shu Uemura', label: 'Shu Uemura' },
  { value: 'Sierra', label: 'Sierra' },
  { value: 'Silver Jeans', label: 'Silver Jeans' },
  { value: 'Simple Mobile', label: 'Simple Mobile' },
  { value: 'Simply Chocolate', label: 'Simply Chocolate' },
  { value: 'SiriusXM', label: 'SiriusXM' },
  { value: 'Sixt', label: 'Sixt' },
  { value: 'Skechers', label: 'Skechers' },
  { value: 'SkinCeuticals', label: 'SkinCeuticals' },
  { value: 'Skip Hop', label: 'Skip Hop' },
  { value: 'Skyscanner', label: 'Skyscanner' },
  { value: 'Sleeping Baby', label: 'Sleeping Baby' },
  { value: 'Sling TV', label: 'Sling TV' },
  { value: 'Snapfish', label: 'Snapfish' },
  { value: 'SnapMade', label: 'SnapMade' },
  { value: 'Soap.com', label: 'Soap.com' },
  { value: 'Society6', label: 'Society6' },
  { value: 'Sofft Shoe', label: 'Sofft Shoe' },
  { value: 'Soft Surroundings', label: 'Soft Surroundings' },
  { value: 'Soft Surroundings Outlet', label: 'Soft Surroundings Outlet' },
  { value: 'SOIA &amp; KYO', label: 'SOIA &amp; KYO' },
  { value: 'Sok-It', label: 'Sok-It' },
  { value: 'SolaWave', label: 'SolaWave' },
  { value: 'Sole Society', label: 'Sole Society' },
  { value: 'Solo Stove', label: 'Solo Stove' },
  { value: 'Soma Intimates', label: 'Soma Intimates' },
  { value: 'Sonos', label: 'Sonos' },
  { value: 'Sorel', label: 'Sorel' },
  { value: 'South Moon Under', label: 'South Moon Under' },
  { value: 'Spafinder', label: 'Spafinder' },
  { value: 'Spanx', label: 'Spanx' },
  { value: 'Speck', label: 'Speck' },
  { value: 'Speedo', label: 'Speedo' },
  { value: 'Sperry Top-Sider', label: 'Sperry Top-Sider' },
  { value: 'SpinLife', label: 'SpinLife' },
  { value: 'SpiritHalloween.com', label: 'SpiritHalloween.com' },
  { value: 'Splendid', label: 'Splendid' },
  { value: 'Sprint', label: 'Sprint' },
  { value: 'SSENSE', label: 'SSENSE' },
  { value: 'Stanley', label: 'Stanley' },
  { value: 'Staples', label: 'Staples' },
  { value: 'Starz', label: 'Starz' },
  { value: 'STATE Bags', label: 'STATE Bags' },
  { value: 'Stein Mart', label: 'Stein Mart' },
  { value: 'Stella &amp; Dot', label: 'Stella &amp; Dot' },
  { value: 'Steve Madden', label: 'Steve Madden' },
  { value: 'Stila Cosmetics', label: 'Stila Cosmetics' },
  { value: 'Stitch Fix', label: 'Stitch Fix' },
  { value: 'Stock Yards', label: 'Stock Yards' },
  { value: 'Straight Talk Wireless', label: 'Straight Talk Wireless' },
  { value: 'Stride Rite', label: 'Stride Rite' },
  { value: 'Stroller Depot', label: 'Stroller Depot' },
  { value: 'Stuart Weitzman Outlet', label: 'Stuart Weitzman Outlet' },
  { value: 'StubHub', label: 'StubHub' },
  { value: 'Sugar &amp; Jade', label: 'Sugar &amp; Jade' },
  { value: 'Sugarfina', label: 'Sugarfina' },
  { value: 'Sunbasket', label: 'Sunbasket' },
  { value: 'Sunglass Hut', label: 'Sunglass Hut' },
  { value: 'Superdry', label: 'Superdry' },
  {
    value: 'SuperShop (previously DailySteals',
    label: 'SuperShop (previously DailySteals',
  },
  { value: 'Sur La Table', label: 'Sur La Table' },
  { value: 'Swap.com', label: 'Swap.com' },
  { value: 'Sweaty Betty', label: 'Sweaty Betty' },
  { value: 'SWELL', label: 'SWELL' },
  { value: 'SwimSpot.com', label: 'SwimSpot.com' },
  { value: 'Swimsuits for All', label: 'Swimsuits for All' },
  { value: 'Talbots', label: 'Talbots' },
  { value: 'Target', label: 'Target' },
  { value: 'Tarte Cosmetics', label: 'Tarte Cosmetics' },
  { value: 'TaskRabbit', label: 'TaskRabbit' },
  { value: 'Tatcha', label: 'Tatcha' },
  { value: "Tate's Bake Shop", label: "Tate's Bake Shop" },
  { value: 'Tea Collection', label: 'Tea Collection' },
  { value: 'Ted Baker', label: 'Ted Baker' },
  { value: 'TeeOff.com', label: 'TeeOff.com' },
  { value: 'TELETIES', label: 'TELETIES' },
  { value: 'Tempo', label: 'Tempo' },
  { value: 'The Art of Shaving', label: 'The Art of Shaving' },
  { value: 'The Body Shop', label: 'The Body Shop' },
  { value: "The Children's Place", label: "The Children's Place" },
  { value: 'The Container Store', label: 'The Container Store' },
  { value: 'The Detox Market', label: 'The Detox Market' },
  { value: 'The Fascination', label: 'The Fascination' },
  { value: 'The Garwood', label: 'The Garwood' },
  { value: 'The Grommet', label: 'The Grommet' },
  { value: 'The Home Depot', label: 'The Home Depot' },
  { value: 'The Honest Company', label: 'The Honest Company' },
  { value: 'The Natural Baby Company', label: 'The Natural Baby Company' },
  { value: 'The Pillow Collection', label: 'The Pillow Collection' },
  { value: 'The Sak', label: 'The Sak' },
  { value: 'The Tot', label: 'The Tot' },
  { value: 'Things Remembered', label: 'Things Remembered' },
  { value: 'ThinkGeek', label: 'ThinkGeek' },
  { value: 'ThirdLove', label: 'ThirdLove' },
  { value: 'Thistle Hotels UK', label: 'Thistle Hotels UK' },
  { value: 'Thoughtfully', label: 'Thoughtfully' },
  { value: 'ThreadLab', label: 'ThreadLab' },
  { value: 'ThredUP', label: 'ThredUP' },
  { value: 'Three Dots', label: 'Three Dots' },
  { value: 'Thrifty Rent-A-Car', label: 'Thrifty Rent-A-Car' },
  { value: 'Tibi.com', label: 'Tibi.com' },
  { value: 'TicketNetwork', label: 'TicketNetwork' },
  { value: 'TIDAL', label: 'TIDAL' },
  { value: 'Tile', label: 'Tile' },
  { value: 'Tillys', label: 'Tillys' },
  { value: 'TimeLife.com', label: 'TimeLife.com' },
  { value: 'Timex', label: 'Timex' },
  { value: 'Tiny Prints', label: 'Tiny Prints' },
  { value: 'TireBuyer.com', label: 'TireBuyer.com' },
  { value: 'TJ Maxx', label: 'TJ Maxx' },
  { value: 'Tog Shop', label: 'Tog Shop' },
  { value: 'Tommy Hilfiger', label: 'Tommy Hilfiger' },
  { value: 'TOMS Shoes', label: 'TOMS Shoes' },
  { value: 'TOMS Shoes Surprise Sale', label: 'TOMS Shoes Surprise Sale' },
  { value: 'Too Faced Cosmetics', label: 'Too Faced Cosmetics' },
  { value: 'Topman', label: 'Topman' },
  { value: 'Topshop UK', label: 'Topshop UK' },
  { value: 'Tory Burch', label: 'Tory Burch' },
  { value: 'Total Wireless', label: 'Total Wireless' },
  { value: 'Tough Mudder', label: 'Tough Mudder' },
  { value: 'Toynk Toys', label: 'Toynk Toys' },
  { value: 'TPO Mobile', label: 'TPO Mobile' },
  { value: 'Travelex Currency Exchange', label: 'Travelex Currency Exchange' },
  { value: 'Travelocity', label: 'Travelocity' },
  { value: 'TravelSmith', label: 'TravelSmith' },
  { value: 'Tria Beauty', label: 'Tria Beauty' },
  { value: 'TripAdvisor', label: 'TripAdvisor' },
  { value: 'Tru Earth', label: 'Tru Earth' },
  { value: 'True Botanicals', label: 'True Botanicals' },
  { value: 'True Religion', label: 'True Religion' },
  { value: 'True Value Hardware', label: 'True Value Hardware' },
  { value: 'Trunk Club', label: 'Trunk Club' },
  {
    value: 'Trusted Tours and Attractions',
    label: 'Trusted Tours and Attractions',
  },
  { value: 'Tumi', label: 'Tumi' },
  { value: 'TunnelBear', label: 'TunnelBear' },
  { value: 'Tupelo', label: 'Tupelo' },
  { value: 'TurboTax', label: 'TurboTax' },
  { value: 'TUSHY', label: 'TUSHY' },
  { value: 'U-Pack', label: 'U-Pack' },
  { value: 'U.S. Polo Assn', label: 'U.S. Polo Assn' },
  { value: 'Uber', label: 'Uber' },
  { value: 'Uber Eats', label: 'Uber Eats' },
  { value: 'Uber Rider', label: 'Uber Rider' },
  { value: 'UFCStore.com', label: 'UFCStore.com' },
  { value: 'UGG', label: 'UGG' },
  { value: 'Ulta Beauty', label: 'Ulta Beauty' },
  { value: "UMI Children's Shoes", label: "UMI Children's Shoes" },
  { value: 'Uncommon Goods', label: 'Uncommon Goods' },
  { value: 'Under Armour', label: 'Under Armour' },
  { value: 'Undercover Mama', label: 'Undercover Mama' },
  { value: 'UNIQLO', label: 'UNIQLO' },
  { value: 'UntilGone', label: 'UntilGone' },
  { value: 'UrbanOG', label: 'UrbanOG' },
  { value: 'Urban Outfitters', label: 'Urban Outfitters' },
  { value: 'Vancaro', label: 'Vancaro' },
  { value: 'Vans', label: 'Vans' },
  { value: 'Venus', label: 'Venus' },
  { value: 'Vera Bradley', label: 'Vera Bradley' },
  { value: 'Vera Bradley Outlet', label: 'Vera Bradley Outlet' },
  { value: 'Verizon Fios', label: 'Verizon Fios' },
  { value: 'Verizon Wireless', label: 'Verizon Wireless' },
  { value: 'Verseo', label: 'Verseo' },
  { value: 'Vestiaire Collective', label: 'Vestiaire Collective' },
  { value: 'Viator', label: 'Viator' },
  {
    value: 'Viator - A TripAdvisor Company UK',
    label: 'Viator - A TripAdvisor Company UK',
  },
  { value: "Victoria's Secret", label: "Victoria's Secret" },
  { value: 'Victoria Emerson', label: 'Victoria Emerson' },
  { value: 'VILLA', label: 'VILLA' },
  { value: 'Vine Oh', label: 'Vine Oh' },
  { value: 'Violet Grey', label: 'Violet Grey' },
  { value: 'VisionDirect.com', label: 'VisionDirect.com' },
  { value: 'Vitamin Shoppe', label: 'Vitamin Shoppe' },
  { value: 'Vitamin World', label: 'Vitamin World' },
  { value: 'Vitamix', label: 'Vitamix' },
  { value: 'Vivid Seats', label: 'Vivid Seats' },
  { value: 'Vizio', label: 'Vizio' },
  { value: 'Volcom', label: 'Volcom' },
  { value: 'Vons.com', label: 'Vons.com' },
  { value: 'VRBO.com', label: 'VRBO.com' },
  { value: 'Vudu.com', label: 'Vudu.com' },
  { value: 'Wacoal America', label: 'Wacoal America' },
  { value: 'Wacoal Outlet', label: 'Wacoal Outlet' },
  { value: 'Wag.com', label: 'Wag.com' },
  { value: 'Wal-mart', label: 'Wal-mart' },
  { value: 'Warby Parker', label: 'Warby Parker' },
  { value: 'Wayfair', label: 'Wayfair' },
  { value: 'Wedding Paper Divas', label: 'Wedding Paper Divas' },
  { value: 'WeightWatchers', label: 'WeightWatchers' },
  { value: 'West Elm', label: 'West Elm' },
  { value: "Wet n' Wild", label: "Wet n' Wild" },
  { value: 'Wet Seal', label: 'Wet Seal' },
  { value: 'Whirlpool', label: 'Whirlpool' },
  { value: 'White House Black Market', label: 'White House Black Market' },
  { value: 'Wildfox', label: 'Wildfox' },
  { value: 'Williams-Sonoma', label: 'Williams-Sonoma' },
  { value: 'Wilsons Leather', label: 'Wilsons Leather' },
  { value: 'Winc', label: 'Winc' },
  { value: 'Windsor', label: 'Windsor' },
  { value: 'Wine Access', label: 'Wine Access' },
  { value: 'Winky Lux', label: 'Winky Lux' },
  { value: 'Wolverine', label: 'Wolverine' },
  { value: 'WonderFold Wagon', label: 'WonderFold Wagon' },
  { value: 'Woot', label: 'Woot' },
  { value: 'Woven Pear', label: 'Woven Pear' },
  { value: 'Wrangler', label: 'Wrangler' },
  { value: 'Wyndham Hotel Group', label: 'Wyndham Hotel Group' },
  { value: 'Yarnspirations', label: 'Yarnspirations' },
  { value: 'YETI', label: 'YETI' },
  { value: 'YLiving', label: 'YLiving' },
  { value: 'Yoga International', label: 'Yoga International' },
  { value: 'YOGASMOGA', label: 'YOGASMOGA' },
  { value: 'Yoto', label: 'Yoto' },
  { value: 'YourMechanic', label: 'YourMechanic' },
  { value: 'Zaful', label: 'Zaful' },
  { value: 'Zales', label: 'Zales' },
  { value: 'Zales Outlet', label: 'Zales Outlet' },
  { value: 'Zappos', label: 'Zappos' },
  { value: 'Zavvi', label: 'Zavvi' },
  { value: 'Zazzle', label: 'Zazzle' },
  { value: 'Zenni Optical', label: 'Zenni Optical' },
  { value: 'Zulily', label: 'Zulily' },
];

// export const payment = [
//   { label: 'All', value: 'All' },
//   { label: 'March 2023', value: 'March 2023' },
//   { label: 'February 2023', value: 'February 2023' },
//   { label: 'January 2023', value: 'January 2023' },
//   { label: 'December 2022', value: 'December 2022' },
//   { label: 'November 2022', value: 'November 2022' },
//   { label: 'October 2022', value: 'October 2022' },
//   { label: 'September 2022', value: 'September 2022' },
//   { label: 'August 2022', value: 'August 2022' },
//   { label: 'July 2022', value: 'July 2022' },
//   { label: 'June 2022', value: 'June 2022' },
//   { label: 'May 2022', value: 'May 2022' },
//   { label: 'April 2022', value: 'April 2022' },
//   { label: 'March 2022', value: 'March 2022' },
//   { label: 'February 2022', value: 'February 2022' },
//   { label: 'January 2022', value: 'January 2022' },
//   { label: 'December 2021', value: 'December 2021' },
//   { label: 'November 2021', value: 'November 2021' },
//   { label: 'October 2021', value: 'October 2021' },
//   { label: 'September 2021', value: 'September 2021' },
//   { label: 'August 2021', value: 'August 2021' },
//   { label: 'July 2021', value: 'July 2021' },
//   { label: 'June 2021', value: 'June 2021' },
//   { label: 'May 2021', value: 'May 2021' },
//   { label: 'April 2021', value: 'April 2021' },
//   { label: 'March 2021', value: 'March 2021' },
//   { label: 'February 2021', value: 'February 2021' },
//   { label: 'January 2021', value: 'January 2021' },
//   { label: 'December 2020', value: 'December 2020' },
//   { label: 'November 2020', value: 'November 2020' },
//   { label: 'October 2020', value: 'October 2020' },
//   { label: 'September 2020', value: 'September 2020' },
//   { label: 'August 2020', value: 'August 2020' },
//   { label: 'July 2020', value: 'July 2020' },
//   { label: 'June 2020', value: 'June 2020' },
//   { label: 'May 2020', value: 'May 2020' },
//   { label: 'April 2020', value: 'April 2020' }
// ]
