// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useContext } from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import Heading from './../../atoms/Heading/Heading';
import LinkGeneratorForm from './../../molecules/LinkGeneratorForm/LinkGeneratorForm';
import client from '../../api';
import { UserContext } from '../../../context/UserContext';
import LinkGeneratorFormBMK from './../../molecules/LinkGeneratorForm/LinkGeneratorFormBMK';
import LoadingOverlay from '../../atoms/LoadingOverlay/LoadingOverlay';

export interface Props extends ThemeProps {
  value?: string;
  brandId?: number;
  bmkFlag: boolean;
}

const Container = styled('div', {
  display: 'block',
  margin: '0 auto',
  paddingBottom: '36px',
  maxWidth: '100%',
  width: '100%',
});

const Head = styled(Heading, {
  marginBottom: '36px',
  fontFamily: '$heading',
  fontSize: '$size18',
  fontWeight: '$bold',
  letterSpacing: '0.5px',
  lineHeight: 1,
  '@bp5': {
    marginBottom: '10px',
  },
});

const Error = styled('div', {
  fontFamily: '$label',
  color: '#a94442',
  border: '1px solid #ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  padding: '15px 30px',
  textAlign: 'center',
  fontSize: '$size16',
});

const TrackingLinkForm: React.FC<Props> = ({
  value,
  brandId = 0,
  bmkFlag = false,
  parentURL,
  shortUrl,
  longUrl,
  updateAffId,
  brandUrl = '',
  display = '',
  enabledDeepLink = false,
  deeplinkUrl,
  ...props
}) => {
  const { userCredentials } = useContext(UserContext);
  const [hasAction, setHasAction] = useState(false);
  const [ready] = useState(true);
  const [generatedLink, setGeneratedLink] = useState('');
  const [errorMessages, setErrorMessages] = useState('');
  const [Loading, setLoading] = useState(false);

  const validateUrl = (destinationUrl: string): boolean => {
    if (brandUrl === destinationUrl) {
      return true;
    } else {
      /*
        domain.com
        www.domain.com
        subdomain.domain.com
       */
      /*
        www.domain.co.ie -> ignore for now
        subdomain.domain.co.ie -> ignore for now
       */
      // prep and process the brandPageUrl (=as seen at the brand page)
      const brandPageUrl = new URL(brandUrl); // parse the brand page Url
      let baseBrandPageUrl = brandPageUrl.hostname.toLowerCase();
      if (baseBrandPageUrl.includes('www')) {
        // check for `www` and remove it if found
        baseBrandPageUrl = baseBrandPageUrl.replace('www.', '');
      }
      const baseUrlElements = baseBrandPageUrl.split('.');

      // process the destinationUrl
      if (destinationUrl.search('http') === -1) {
        destinationUrl = `${brandPageUrl.protocol}//${destinationUrl}`;
      }
      const targetUrl = new URL(destinationUrl);
      let targetUrlString = targetUrl.hostname.toLowerCase();
      if (targetUrlString.includes('www')) {
        // check for `www` and remove it if found
        targetUrlString = targetUrlString.replace('www.', '');
      }
      const targetUrlElements = targetUrlString.split('.');

      if (baseUrlElements.length === 2) {
        // brand url has no subdomain
        if (targetUrlElements.length === 2) {
          return baseBrandPageUrl === targetUrlString;
        } else if (targetUrlElements.length === 3) {
          return (
            baseBrandPageUrl ===
            targetUrlElements[1] + '.' + targetUrlElements[2]
          );
        }
      } else if (baseUrlElements.length === 3) {
        // brand url has subdomain
        if (targetUrlElements.length === 2) {
          // target URL has no subdomain
          return false;
        } else if (targetUrlElements.length === 3) {
          return baseBrandPageUrl === targetUrlString;
        }
      }
    }
  };

  const handleAction = (args: any): void => {
    setErrorMessages('');

    if (args.url === '') {
      setErrorMessages('Destination URL can not be empty.');
      setLoading(false);
    } else {
      setLoading(true);

      // validating url with brands url
      // if (brandUrl.includes(seekUrl)===false) {
      if (!validateUrl(args.url)) {
        setErrorMessages('Destination URL does not match Brand URL.');
        setLoading(false);
      } else {
        if (brandId === 0) {
          brandId = null;
        }

        // set for the 'create short url' checkbox
        let shortLink = false;
        if (args.isShort !== null) {
          shortLink = args.isShort;
        }

        const newData = {
          targetUrl: args.url,
          brandID: brandId,
          short_link: shortLink,
          aff_sub: args.id,
        };

        // Access the user credentials
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { password, username, csrf_token } = userCredentials;
        void client
          .post('/api/deep_linking', newData, {
            headers: {
              'Content-type': 'application/x-www-form-urlencoded',
              csrf_token,
            },
            auth: { username, password },
          })
          .then(({ data }) => {
            if (data.url === '') {
              setErrorMessages(
                'Error while creating short deep linking, try again later or the long link.',
              );
            } else {
              setGeneratedLink(data.url);
              setHasAction(true);

              if (shortLink && data.url === data.url_long) {
                setErrorMessages(
                  'We were unable to create a short link at the moment, please use the long link or try again later.',
                );
              }
            }

            setLoading(false);
          })
          .catch((e) => {
            // console.log("error:", e.response.data.message);
            setErrorMessages(e.response.data.message);
            setLoading(false);
          });
      }
    } // end else args.url===""
  };

  const handleUpdateUrl = (affID): void => {
    setLoading(true);

    updateAffId(affID);
  };

  const handleSetLoading = (): void => {
    setLoading((prevState) => !prevState);
  };

  const handleError = (body): void => {
    setErrorMessages(body);
  };

  return (
    <Container {...props}>
      {!bmkFlag && (
        <>
          {Loading && <LoadingOverlay />}

          <Head>CREATE A CUSTOM TRACKING LINK</Head>

          {errorMessages !== '' ? <Error>{errorMessages}</Error> : ''}

          <LinkGeneratorForm
            brandId={brandId}
            handler={handleAction}
            ready={ready}
            hasAction={hasAction}
            generatedLink={generatedLink}
            handleSetLoading={handleSetLoading}
            setErrorMessages={handleError}
            display={display}
            enabledDeepLink={enabledDeepLink}
            deeplinkUrl={deeplinkUrl}
          />
        </>
      )}
      {bmkFlag && (
        <>
          <Head>Build a Deep Link</Head>

          <LinkGeneratorFormBMK
            brandId={brandId}
            parentURL={parentURL}
            shortUrl={shortUrl}
            longUrl={longUrl}
            updateLinks={handleUpdateUrl}
            enabledDeepLink={enabledDeepLink}
          />
        </>
      )}
    </Container>
  );
};
export default TrackingLinkForm;
