import React from 'react';
import { styled } from '../../../theme/index';
import { Link } from 'react-router-dom';
import type { ErrorMessageProps } from '../../../types/validationTypes';

const Error = styled('div', {
  fontFamily: '$label',
  color: '#a94442',
  border: '1px solid #ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  padding: '15px 30px',
  fontSize: '$size16',
});

const Text = styled('p', {
  margin: 0,
});

const defaultErrorMessage: React.JSX.Element = (
  <Text
    aria-live="polite"
    role="alert"
  >
    Something went wrong, please try again later or{' '}
    <Link to="/contact-support">contact support</Link>
  </Text>
);

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  message = defaultErrorMessage,
  className = '',
}) => {
  return (
    <Error className={`errorMessage ${className}`.trim()}>{message}</Error>
  );
};

export default ErrorMessage;
