// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { styled, type ThemeProps } from '../theme/index';

import { useSpring, animated, type SpringConfig } from 'react-spring';

interface Props extends ThemeProps {
  state?: boolean;
  options?: SpringConfig;
}

const AccordionHeading = styled('div', {
  cursor: 'pointer',
  zIndex: 1,
});

const AnimatedBox = styled(animated.div, {
  overflow: 'hidden',
  zIndex: 0,
});

const optionsDefault = {
  duration: 300,
};
const AccordionProvider: React.FC<Props> = ({ state, options, children }) => {
  const [open, setOpen] = useState(false);
  const [contentMaxHeight, setContentMaxHeight] = useState(0);
  const ref = useRef<HTMLDivElement>();
  options = options === undefined ? optionsDefault : options;

  const isOpen = state ?? open;

  const stateHandler = (): void => {
    setOpen(!open);
  };

  useEffect(() => {
    const calcContentMaxHeight = (): void => {
      ref?.current !== undefined &&
        setContentMaxHeight(ref.current.scrollHeight);
    };

    calcContentMaxHeight();

    window.addEventListener('resize', calcContentMaxHeight);

    return () => {
      window.removeEventListener('resize', calcContentMaxHeight);
    };
  }, [ref, contentMaxHeight]);

  const { scY, ...props } = useSpring({
    scY: isOpen ? -1 : 1,
    opacity: isOpen ? 1 : 0,
    maxHeight: isOpen ? `${contentMaxHeight}px` : '0px',
    config: options,
  });

  return (
    <>
      {state === undefined && (
        <AccordionHeading onClick={stateHandler}>trigger</AccordionHeading>
      )}
      <AnimatedBox
        ref={ref as React.Ref<HTMLDivElement>}
        style={{ ...props }}
      >
        {children}
      </AnimatedBox>
    </>
  );
};

export default AccordionProvider;
