// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';

import Calendar from 'react-calendar';

import 'react-calendar/dist/Calendar.css';

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  callback: (date: Date) => void;
  value: Date | string;
  calendarProps: object;
}

const calendar = '.react-calendar__';

const deepSelections = `
        ${calendar}year-view__months__month, 
        ${calendar}decade-view__years__year, 
        ${calendar}century-view__decades__decade
`;

const collection = `abbr,
        ${calendar}navigation__label,
        ${calendar}navigation__arrow,
        ${calendar}month-view__days__day, 
        ${calendar}month-view__days__day--weekend,
        ${calendar}month-view__weekdays__weekday, 
        ${deepSelections}
`;

const Container = styled('div', {
  // wrapper
  '.react-calendar': {
    borderRadius: '4px',
    backgroundColor: '$blue6',
    position: 'relative',
    marginBottom: '20px',
    '&:before': {
      content: '',
      display: 'inline-block',
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      borderBottom: '7px solid #999999',
      borderTop: 0,
      borderBottomColor: 'rgba(0,0,0,0.2)',
      position: 'absolute',
      top: '-7px',
      left: '5px',
    },
    '&:after': {
      content: '',
      display: 'inline-block',
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      borderBottom: '6px solid $clear',
      borderTop: 0,
      position: 'absolute',
      top: '-7px',
      left: '6px',
    },
  },
  // clear styles
  [collection]: {
    color: '$clear',
    fontFamily: '$label',
    fontSize: '$size16',
  },
  // clear styles
  abbr: {
    textDecoration: 'none !important',
    lineHeight: 1.42,
  },
  // clear styles
  'abbr[title]': {
    textTransform: 'capitalize',
  },
  // Navigation level adjustments
  [`${calendar}navigation__label`]: {
    textTransform: 'capitalize',
    padding: 0,
    span: {
      fontSize: '16px',
      letterSpacing: '0.5px',
    },
  },
  // Navigation arrows pixel perfect adjustment
  [`${calendar}navigation button`]: {
    minWidth: 'auto',
    flex: '0 1 35px',
  },
  [`${calendar}navigation__label__labelText`]: {
    fontWeight: '$bold',
  },
  // navigation bar
  [`${calendar}navigation`]: {
    height: '33px',
    marginBottom: 0,
  },
  // up label & arrows
  [`
        ${calendar}navigation__label, 
        ${calendar}navigation__arrow,
        ${calendar}tile:disabled, 
        ${calendar}navigation button:disabled
    `]: {
    paddingTop: '6px',
    '&:hover, &:focus': {
      backgroundColor: 'transparent !important',
    },
  },
  // Disabled styles
  [`${calendar}tile:disabled, ${calendar}navigation button:disabled`]: {
    backgroundColor: 'transparent',
  },
  // Deep Selections padding
  [`${deepSelections}, ${calendar}year-view__months__month abbr`]: {
    flex: '1 0 33.33% !important',
    textTransform: 'capitalize',
    fontSize: '11px',
    fontWeight: '$regular',
  },
  [`${calendar}tile--now`]: {
    background: 'none',
  },
  // Weekdays
  [`${calendar}month-view__weekdays__weekday`]: {
    padding: '6px 0',
  },
  [`${calendar}month-view__weekdays`]: {
    padding: '0 8px',
  },
  // adjust tiles padding
  [`${calendar}tile`]: {
    padding: '5px 0',
    '&:hover, &:focus': {
      '&:enabled': {
        background: '#eeeeee',
      },
    },
  },
  // Active cell
  [`${calendar}tile--active, ${calendar}tile--hasActive`]: {
    borderRadius: '4px',
    backgroundRepeat: 'repeat-x',
    textShadow: '0 -1px 0 rgba(0, 0, 0, 0.25)',
    backgroundImage: 'linear-gradient(to bottom,#0088cc,#0044cc)',
  },
});

const _Calendar: React.FC<Props> = ({
  callback,
  value,
  calendarProps,
  ...props
}) => {
  const navigationLabel = ({ date }): string =>
    `${date.toLocaleDateString(undefined, {
      month: 'long',
    })} ${date.getFullYear()}`;

  return (
    <Container {...props}>
      <Calendar
        value={value}
        onChange={callback}
        navigationLabel={navigationLabel}
        formatShortWeekday={(_, date) =>
          date.toLocaleDateString(undefined, { weekday: 'short' }).slice(0, 2)
        }
        {...calendarProps}
      />
    </Container>
  );
};

export default _Calendar;
