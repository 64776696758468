// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useContext } from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import Heading from './../../atoms/Heading/Heading';
import Input from './../../atoms/Input/Input';
import Select, {
  type Option as SelectProps,
} from './../../atoms/Select/Select';
import { Button } from '../../atoms/Button/Button';
import client from '../../api';
import { UserContext } from '../../../context/UserContext';

interface Data {
  firstName?: string;
  lastName?: string;
  userName?: string;
  email?: string;
  password?: string;
  confirmation?: string;
  role?: SelectProps;
}
interface Props extends ThemeProps {
  data?: Data;
  // optionsSelect: SelectProps[]
}

const Container = styled('div', {
  maxWidth: '90%',
  margin: '0 auto',
  '@bp2': {
    maxWidth: '353px',
    paddingLeft: '24px',
    margin: 0,
  },
  '@bp3': {
    maxWidth: '334px',
    paddingLeft: 0,
  },
  '@bp5': {
    maxWidth: '422px',
  },
});

const Form = styled('form', {
  margin: '56px 0',
  label: {
    margin: '15px 0 17px',
  },
});

const Advice = styled('div', {
  color: '#7c7c7c',
  fontFamily: '$body',
  fontWeight: '$bold',
  fontSize: '13px',
});

const SelectorLabel = styled('label', {
  display: 'block',
  fontFamily: '$label',
  fontWeight: '$regular',
  fontSize: '$size14',
  marginBottom: '20px',
  margin: 0,
  '>div': {
    fontFamily: '$body',
    padding: 0,
    margin: 0,
  },
});

const Required = styled('span', {
  marginLeft: '12px',
  fontWeight: '$bold',
});

const Title = styled(Heading, {
  margin: '8px 0 20px',
  color: '$darkgray',
  textTransform: 'capitalize',
});

const InputText = styled(Input, {
  label: {
    margin: '15px 0 10px',
  },
  span: {
    marginBottom: '2px',
  },
});

const errors = {
  firstName:
    'First Name must be longer than two characters and contain only letters.',
  lastName:
    'Last Name must be longer than two characters and contain only letters.',
  userName: 'Username must be at least four characters.',
  email: 'E-mail address field is required. Please enter valid E-mail address.',
  password: 'Password must be at least six characters.',
  confirmation:
    'Confirmation Password must be at least six characters and match with the password',
  passMatch: 'The passwords do not match.',
};
const Error = styled('div', {
  fontFamily: '$label',
  color: '#a94442',
  border: '1px solid #ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  padding: '15px 30px',
  textAlign: 'center',
  fontSize: '$size16',
});

export const SubUsersAddForm: React.FC<Props> = ({ data }) => {
  const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState('');
  const [optionsSelect] = useState([
    {
      value: 'link_creator',
      label: 'Link Creator',
    },
    {
      value: 'analyst',
      label: 'Analyst',
    },
  ]);
  const initialState = {
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    userName: data?.userName ?? '',
    email: data?.email ?? '',
    password: data?.password ?? '',
    confirmation: data?.confirmation ?? '',
    role: data?.role ?? optionsSelect[0],
  };

  const [formInfo, setFormInfo] = useState(initialState);

  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;

  // useEffect(() => {
  //   // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  //   void client.get(`/api/subuser/roles/get`, {
  //     headers: { csrf_token },
  //     auth: { username, password }
  //   })
  //     .then(({ data }) => {
  //       console.log("/api/subuser/roles/get answer: ", data);
  //     })
  //     .catch(e => {
  //       console.log("error", e);
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[csrf_token]);

  const changeHandler = (key: string, value: string | SelectProps): void => {
    setFormInfo({ ...formInfo, ...{ [key]: value } });
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const submitHandler = (event) => {
    event.preventDefault();
    setErrorMessages('');

    if (
      formInfo.firstName.length < 2 ||
      !/^[A-Za-z]*$/.test(formInfo.firstName)
    ) {
      setErrorMessages(errors.firstName);
      return;
    }
    if (
      formInfo.lastName.length < 2 ||
      !/^[A-Za-z]*$/.test(formInfo.lastName)
    ) {
      setErrorMessages(errors.lastName);
      return;
    }
    if (formInfo.userName.length < 4) {
      setErrorMessages(errors.userName);
      return;
    }
    if (validateEmail(formInfo.email) === null) {
      setErrorMessages(errors.email);
      return;
    }
    if (formInfo.password.length < 6) {
      setErrorMessages(errors.password);
      return;
    }
    if (formInfo.confirmation.length < 6) {
      setErrorMessages(errors.confirmation);
      return;
    }
    if (formInfo.password !== formInfo.confirmation) {
      setErrorMessages(errors.passMatch);
      return;
    }

    // attempt to save new subUser
    const newData = {
      firstName: formInfo.firstName,
      lastName: formInfo.lastName,
      userName: formInfo.userName,
      eMail: formInfo.email,
      pass1: formInfo.password,
      passConfirm: formInfo.confirmation,
      role: formInfo.role.value,
    };

    void client
      .post(`/api/subusers/create`, newData, {
        headers: { 'Content-Type': 'multipart/form-data', csrf_token },
        auth: { username, password },
      })
      .then((res) => {
        // setSuccessMessages(res.data);
        // window.scrollTo(0, 0);
        window.location.href = '/subusers';
      })
      .catch((e) => {
        // console.log("error", e);
        setErrorMessages(e.response.data);
      });
  };
  const validateEmail = (email): any => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  return (
    <Container>
      <Title
        type="pageTitle"
        css={{ fontSize: '36px' }}
      >
        Add User
      </Title>

      {errorMessages !== '' ? <Error>{errorMessages}</Error> : ''}
      {/* {successMessages !== '' ? <Success>{successMessages}</Success> : ''} */}

      <Form>
        <InputText
          type="text"
          label="First Name"
          view="secondaryRegular"
          required
          asterisk="bold"
          value={formInfo.firstName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            changeHandler('firstName', e.target.value);
          }}
        />
        <InputText
          type="text"
          label="Last Name"
          view="secondaryRegular"
          required
          asterisk="bold"
          value={formInfo.lastName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            changeHandler('lastName', e.target.value);
          }}
        />
        <InputText
          type="text"
          label="Username"
          view="secondaryRegular"
          required
          asterisk="bold"
          value={formInfo.userName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            changeHandler('userName', e.target.value);
          }}
        />
        <Advice>
          The final username will be prefixed with the username from your
          account, ex. FashionorLove_username
        </Advice>
        <InputText
          type="email"
          label="Email Address"
          view="secondaryRegular"
          required
          asterisk="bold"
          value={formInfo.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            changeHandler('email', e.target.value);
          }}
        />
        <InputText
          type="password"
          label="Password"
          view="secondaryRegular"
          required
          asterisk="bold"
          value={formInfo.password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            changeHandler('password', e.target.value);
          }}
        />
        <InputText
          type="password"
          label="Confirm Password"
          view="secondaryRegular"
          required
          asterisk="bold"
          value={formInfo.confirmation}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            changeHandler('confirmation', e.target.value);
          }}
        />
        <SelectorLabel htmlFor="options">
          Role <Required>*</Required>
          <Select
            isForm={true}
            id="options"
            label="Role *"
            placeholder="- Select -"
            options={optionsSelect}
            value={formInfo.role}
            setValue={(e): void => {
              changeHandler('role', e as SelectProps);
            }}
          />
        </SelectorLabel>
      </Form>
      <Button
        type="action"
        onClick={submitHandler}
      >
        SUBMIT
      </Button>
    </Container>
  );
};
