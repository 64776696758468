// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import { styled, type ThemeProps } from './../../../theme/index';
import TitleSection from './../../atoms/TitleSection/TitleSection';
import Text from './../../atoms/Text/Text';

export interface CardProps
  extends ThemeProps,
    React.ComponentPropsWithoutRef<any> {
  link: string;
  code: string;
}

const Container = styled('div', {
  padding: '15px 31px 22px',
  backgroundColor: '$clear',
});

const HeaderTitle = styled(Text, {
  fontFamily: '$heading',
  fontSize: '21px',
  fontWeight: '$bold',
  letterSpacing: '0.6px',
  lineHeight: '21px',
  margin: '0 0 37px 2.5px',
});

const CodeWrap = styled(TitleSection, {
  backgroundColor: '#f0f2fa',
  padding: '8px 26px 12px',
  marginBottom: '34px',
  '&>span': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});

const Title = styled('span', {
  verticalAlign: 'middle',
  textTransform: 'capitalize',
  fontWeight: '$regular',
  fontFamily: '$body',
  fontSize: '14px',
  transform: 'translate(-0.5px, 1px)',
  '@bp1': {
    fontSize: '16px',
  },
});

const Code = styled('span', {
  fontWeight: '$bold',
  fontFamily: '$quote',
  transform: 'translate(3px, 2px)',
  wordBreak: 'break-all',
  fontSize: '22px',
  '@bp1': {
    fontSize: '30px',
  },
});

const Box = styled('div', {
  a: {
    wordBreak: 'break-all',
  },
  '&.first': {
    borderBottom: '1px solid #cecece',
    paddingBottom: '30.4px',
  },
});

const Subtitle = styled(Text, {
  '&.subtitle': {
    transform: 'translateX(-0.5px)',
  },
  fontFamily: '$heading',
  fontWeight: '$bold',
  letterSpacing: '0.5px',
  fontSize: '14px',
  lineHeight: '17px',
  textTransform: 'uppercase',
  '&:not(&.subtitle)': {
    padding: '30px 0 17px',
  },
  '@bp1': {
    fontSize: '17px',
  },
});

const Disclaimer = styled(Text, {
  fontSize: '12px',
  letterSpacing: '-0.2px',
  lineHeight: 1.44,
  '&.text': {
    margin: '17px 0 0',
    transform: 'translateX(-0.5px)',
  },
  '@bp1': {
    fontSize: '16px',
  },
});

const Body = styled('div', {
  transform: 'translateX(-0.5px)',
});

const Lead = styled('a', {
  fontsize: 'var(--fontSizes-size16)',
  color: 'var(--colors-green4)',
  cursor: 'pointer',
  fontWeight: 'var(--fontWeights-bold)',
  lineHeight: '1.4',
  textdecoration: 'none',
});

const Card: React.FC<CardProps> = ({ code, link, ...props }) => {
  return (
    <Container {...props}>
      <HeaderTitle>REFER A FRIEND</HeaderTitle>
      <CodeWrap>
        <Title>Your BrandCycle Invitation Code Is: </Title>
        <Code>{code}</Code>
      </CodeWrap>

      <Box className="first">
        <Subtitle className="subtitle">INVITE A FRIEND</Subtitle>
        <Disclaimer className="text">
          Share this BrandCycle Signup Link to invite publishers:{' '}
          <Lead
            style={{ display: 'block' }}
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            {link}
          </Lead>
        </Disclaimer>
      </Box>

      <Body>
        <Box>
          <Subtitle>What is an invitation Code?</Subtitle>
          <Disclaimer>
            A invitation Code is a Code that is used to track each person that
            you’ve invited to join BrandCycle.This Code is unique to each
            BrandCycle publisher, and will always appear here and remain the
            same.
          </Disclaimer>
        </Box>

        <Box>
          <Subtitle>Referral Bonus Program</Subtitle>
          <Disclaimer>
            Here&apos;s how our referral program works: You can earn a $25 bonus
            for anyone you refer, who signs up as a publisher of BrandCycle,
            gets approved, and makes their first sale within one month of
            sign-up! Once your friend makes their first sale, they will also
            receive a $25 bonus. It’s a Win-Win. This FREE money should be easy
            to earn, especially if you are part of a blogger group, or have an
            awesome following…as we know you do! To obtain your sign-ups, just
            copy and share your Invitation Code above, and make sure that this
            code is entered when your friend registers to BrandCycle. It’s that
            simple!
          </Disclaimer>
        </Box>
      </Body>
    </Container>
  );
};

export default Card;
