// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {
  type Dispatch,
  type SetStateAction,
  useMemo,
  useState,
} from 'react';
import { styled } from './../../../theme/index';
import Input from './../../atoms/Input/Input';
import Text from './../../atoms/Text/Text';
import { sum } from 'lodash';

interface Props {
  password: string;
  confirmPassword: string;
  setPassword: Dispatch<SetStateAction<string>>;
  setConfirmPassword: Dispatch<SetStateAction<string>>;
}

const Container = styled('div', {
  maxWidth: '580px',
  label: {
    color: '$darkgray',
    fontWeight: '$bold',
    fontSize: '$size16',
    lineHeight: 1.428,
    fontFamily: '$label',
  },
  p: {
    fontFamily: '$label',
  },
  input: {
    marginTop: '5px',
  },
});

const Row = styled('div', {
  display: 'grid',
  gridTemplateColumns: '5fr 4fr',
  marginBottom: '15px',
  alignItems: 'flex-end',
  '@bp3': {
    paddingRight: '15px',
  },
});

const Indicator = styled('progress', {
  appearance: 'none',
  backgroundColor: '#949494',
  height: '.3em',
  width: '100%',
  position: 'relative',
  variants: {
    levels: {
      level1: {
        '&::-webkit-progress-value': {
          backgroundColor: '#b94a48',
        },
      },
      level2: {
        '&::-webkit-progress-value': {
          backgroundColor: '#f05c1d',
        },
      },
      level3: {
        '&::-webkit-progress-value': {
          backgroundColor: '#ebbd04',
        },
      },
      level4: {
        '&::-webkit-progress-value': {
          backgroundColor: '#0b75ed',
        },
      },
      level5: {
        '&::-webkit-progress-value': {
          backgroundColor: '#01a917',
        },
      },
    },
  },
});

const InputWrapper = styled('div', {
  width: '192px',
});

const Advice = styled('ul', {
  listStyle: 'none',
  padding: '.2em .5em',
  margin: '.7em 0',
  border: '1px solid #B4B4B4',
  fontSize: '$size16',
  fontFamily: '$label',
});

const levels = ['Weak', 'Fair', 'Fair', 'Good', 'Strong'];

const validateRegex = ['[A-Z]', '[a-z]', '[0-9]', '[^\\w\\s]'];

const Password: React.FC<Props> = ({
  password,
  confirmPassword,
  setConfirmPassword,
  setPassword,
}) => {
  const [interacted, setInteracted] = useState(false);

  const validate = useMemo(() => {
    const lower = new RegExp(validateRegex[1]).test(password);
    const numbers = new RegExp(validateRegex[2]).test(password);
    const symbols = new RegExp(validateRegex[3]).test(password);
    const upperCase = new RegExp(validateRegex[0]).test(password);
    const length = password.length >= 6;

    const total = sum([lower, numbers, symbols, upperCase, lower]);
    let currentLevel: number;
    currentLevel = !length ? 0 : total <= 0 ? 0 : total - 1;
    currentLevel =
      password.length <= 12 && currentLevel === 4 ? 3 : currentLevel;

    return {
      progress: interacted && currentLevel === 0 ? 1 : currentLevel,
      level: levels[currentLevel],
      equal: password === confirmPassword && confirmPassword !== '',
      length,
      lower,
      numbers,
      symbols,
      upperCase,
    };
  }, [password, confirmPassword, interacted]);

  const onChangePass = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value);
    setInteracted(true);
  };

  const onChangeConfirmPass = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setConfirmPassword(e.target.value);
    setInteracted(true);
  };

  return (
    <Container>
      <Row>
        <InputWrapper>
          <Input
            value={password}
            onChange={onChangePass}
            type="password"
            label="Password"
            view="secondary"
          />
        </InputWrapper>
        <div>
          {interacted && (
            <>
              <Text css={{ display: 'flex' }}>
                <span style={{ flex: '2 1 auto' }}>Password strength:</span>
                <strong>{validate.level}</strong>
              </Text>
              <Indicator
                max={4}
                levels={`level${validate.progress}`}
                value={validate.progress}
              />
            </>
          )}
        </div>
      </Row>
      <Row>
        <InputWrapper>
          <Input
            value={confirmPassword}
            onChange={onChangeConfirmPass}
            type="password"
            label="Confirm password"
            view="secondary"
          />
        </InputWrapper>
        <div>
          {interacted && password.length > 0 && confirmPassword !== '' && (
            <Text>
              Passwords match:{' '}
              <span style={{ color: validate.equal ? 'inherit' : '#b94a48' }}>
                {validate.equal ? 'yes' : 'no'}
              </span>
            </Text>
          )}
        </div>
      </Row>
      {interacted && validate.level !== 'strong' && (
        <Advice>
          <li>To make your password stronger:</li>
          {!validate.length && <li>Use at least 6 characters</li>}
          {!validate.upperCase && <li>Add uppercase letters</li>}
          {!validate.lower && <li>Add lowercase letters</li>}
          {!validate.numbers && <li>Add numbers</li>}
          {!validate.symbols && <li>Add punctuation</li>}
        </Advice>
      )}
    </Container>
  );
};

export default Password;
