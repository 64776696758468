import React from 'react';
import { ButtonCopyLinkProps } from 'src/types/createLinkTypes';
import BaseIcon from 'src/components/atoms/BaseIcon/BaseIcon';
import { ReactComponent as IconCopy } from 'src/icons/iconCopy.svg';
import 'src/components/molecules/ButtonCopyLink/buttonCopyLink.scss';

const ButtonCopyLink: React.FC<ButtonCopyLinkProps> = ({
  affiliateLink,
  handleCopyLinkClick,
}) => {
  return (
    <button
      className="buttonCopyLink"
      onClick={handleCopyLinkClick}
    >
      <span className="buttonCopyLink__affLink">{affiliateLink}</span>
      <BaseIcon
        className="buttonCopyLink__icon"
        icon={IconCopy}
        size={18}
      />
    </button>
  );
};

export default ButtonCopyLink;
